import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import CustomComponent from 'components/customComponent';
import { LineChart, LineChartList } from '../../components/Statistics'
import Modal from 'components/Modal';
import IconButton from 'components/IconButton';

class TransactionModal extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showAccessibleList: false,
            data: []
        }
        this._isMounted = false;
    }

    async componentDidMount () {
        this._isMounted = true;
        await this.getData()
    }

    componentWillUnmount () {
        this._isMounted = false;
    }

    async getData () {
        let url = `/transactions/stats/${this.props.transaction.id}`

        let method = 'GET'
        let data = await this.request(url, method)

        if (data && data.values) {
            this._isMounted && this.setState({data: data.values.map(value => ({
                x: value.date.date,
                y: (this.props.transaction.connector.fluidType === 'Water')
                    ? parseFloat(value.m3Consumed).toFixed(2)
                    : parseFloat(value.kwhConsumed).toFixed(2)
            }))})
        }
    }

    render () {
        const {t, onClose, transaction} = this.props

        return (<>
            <Modal onClose={onClose} open={true} title={t('consumption')}>
                <p className="modal__description">
                    {t('terminal')} : {transaction.connector.terminal.cpoTerminalId} <br />
                    {t('place')} : {transaction.connector.terminal.address} <br />
                    {t('connector')} : {t(transaction.connector.fluidType)}
                </p>
                
                <div className="charts__header">
                    <h3 className="modal__title">{transaction.connector.fluidType === 'Water' ? t('water_consumption') : t('electric_consumption')}</h3>
                    <IconButton
                        onClick={() => this.setState({showAccessibleList: !this.state.showAccessibleList})}
                        icon="vnf-icons-icon-m-oeil-outline"
                        focusIcon="vnf-icons-icon-m-oeil-solid"
                        label={this.state.showAccessibleList ? 'graphical_version' : 'accessible_version'}/>
                </div>
                <div className="charts__container">
                    {this.state.showAccessibleList ? (
                        <div className="charts__table__container">
                            <LineChartList fluidType={transaction.connector.fluidType} data={this.state.data}/>
                        </div>
                    ) : (
                        <LineChart fluidType={transaction.connector.fluidType} data={this.state.data} />
                    )}
                </div>
                <div className="modal__buttons modal__buttons--center">
                    <button type="button" onClick={onClose} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-contained vnf-btn-bleu-a2 mdc-dialog__footer__button mdc-dialog__footer__button--accept">{t('close')}</button>
                </div>
            </Modal>
        </>)
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(TransactionModal)), TransactionModal));
