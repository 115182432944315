import {useState, useEffect} from 'react'
import OutsideClick from 'react-outclick'
import Modal from 'components/Modal'
import { useTranslation } from 'react-i18next'
import { FormTextArea } from 'components/FormComponents'
import ReactTooltip from 'react-tooltip'

export const StatusDropDown = ({status, reason, onChange}) => {
    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [thisReason, setThisReason] = useState(reason ? reason : '')
    const {t} = useTranslation()

    const getTip = () => t(status) + (thisReason ? `<br>${t('reason')} : <br>${thisReason}` : '')

    const handleChange = (accepted) => {
        setThisReason(accepted ? null : thisReason)
        setOpen(false)
        setOpenModal(false)
        onChange({accepted: accepted, reason: accepted ? null : thisReason})
    }


    useEffect(() => {
        ReactTooltip.rebuild()
    }, [])

    return (<>
        <OutsideClick onOutsideClick={() => setOpen(false)}>
            <div className="status-dropdown">
                <button type="button" onClick={() => setOpen(!open)} data-tip={getTip()}>
                    <span className={`status-dropdown__badge status-dropdown__badge--${status}`}></span>
                    <span className="sr-only">{t(status)}</span>
                </button>
                {open && (
                    <ul className="status-dropdown__list">
                        <li><button type="button" onClick={() => handleChange(true)}>{t('accepted')}</button></li>
                        <li><button type="button" onClick={() => setOpenModal(true)}>{t('refused')}</button></li>
                    </ul>
                )}
            </div>
        </OutsideClick>
        <Modal open={openModal} onClose={() => setOpenModal(false)} title={t('user_refusal_reason')}>
            <div className="primary-form">
                <div className="row">
                    <div className="col-12">
                        <FormTextArea
                            label={t('user_refusal_reason')}
                            value={thisReason}
                            required
                            handle={(e) => setThisReason(e.target.value)}/>
                    </div>
                </div>
                <div className="primary-form__buttons">
                    <button type="button" onClick={() => setOpenModal(false)} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-bleu-a3">{t('cancel')}</button>
                    <button type="button" onClick={() => handleChange(false)} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('confirm')}</button>
                </div>
            </div>
        </Modal>
    </>)
}