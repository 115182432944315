import CustomComponent from '../../components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import { FormInput } from 'components/FormComponents';
import { REGEX, validatePassword, validatePasswordMatch } from 'helpers/validation';
import PasswordHelper from 'components/PasswordHelper';

class EditPassword extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            pass: {
                current_password: "",
                new_password: "",
                conf_password: ""
            },
            formIsValid: false
        }
    }

    componentDidMount() {
        this.checkIsConnectedPWA();
    }

    checkFormValidity () {
        this.setState({
            formIsValid: (
                new RegExp(REGEX.password_12_characters).test(this.state.pass.new_password) &&
                new RegExp(REGEX.password_uppercase).test(this.state.pass.new_password) &&
                new RegExp(REGEX.password_lowercase).test(this.state.pass.new_password) &&
                new RegExp(REGEX.number).test(this.state.pass.new_password) &&
                new RegExp(REGEX.password_special_char).test(this.state.pass.new_password) &&
                this.state.pass.current_password !== this.state.pass.new_password &&
                this.state.pass.new_password === this.state.pass.conf_password
            )
        })
    }

    async afterSubmissionPass(event) {
        const { t } = this.props;
        event.preventDefault();
        if (this.state.pass.new_password === this.state.pass.conf_password) {
            if (await this.check_password(this.state.pass.new_password)) {
                if (await this.edit_password(this.state.pass.current_password, this.state.pass.new_password))
                {
                    this.props.addToast(t('Password successfully changed'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
                    if(localStorage.getItem('passwordNeedsChange') === "true") {
                        localStorage.setItem('passwordNeedsChange', false);
                        this.props.history.push("/");
                    }
                }
            }
        }
        else {
            this.props.addToast(t('Passwords do not match'), { appearance: 'danger' });
        }
    }

    async check_password(password) {
        const { t } = this.props;
        let url = "/user/password/check";
        let method = "POST";
        let body = JSON.stringify({ password: password });
        let data = await this.request(url, method, body);
        if (data && data.status === "ok" && data.valid)
            return true;
        else if (data && !data.valid && data.violations) {
            data.violations.forEach(element => this.props.addToast(t(element), { appearance: 'danger' }));

        }
        return false;
    }

    async edit_password(current_password, password) {
        const { t } = this.props;
        let url = "/user/password";
        let method = "POST";
        let csrf = await this.getCsrfToken("/user/csrf");
        let body = JSON.stringify({ oldPassword: current_password, password: password, _csrf_token: csrf });
        let data = await this.request(url, method, body);
        if (data && data.status === "ok" && data.valid)
            return true;
        else if (data && !data.valid && data.violations) {
            data.violations.forEach(element => this.props.addToast(t(element), { appearance: 'danger' }));
        }
        return false;
    }

    render() {
        const { t } = this.props;
        return (
            <div className="edit__form primary-form">
                <h2 className="primary-form__title">{t('Security')}</h2>
                <hr className="form-divider form-divider--big"/>
                <form onSubmit={(e) => this.afterSubmissionPass(e)}>
                    <div className="row">
                        <div className="col-12">
                            <FormInput
                                label={t("Current password")}
                                type="password"
                                name="current_password"
                                id="current_password"
                                required
                                handle={(e) => this.handleInputChange(e, null, 'pass')}
                                value={this.state.pass.current_password} />
                        </div>
                        <div className="col-12">
                            <FormInput
                                label={t("New password")}
                                type="password"
                                name="new_password" 
                                id="new_password"
                                validation={(e) =>validatePassword(e, this.state.pass.current_password)}
                                required
                                handle={(e) => this.handleInputChange(e, null, 'pass', () => this.checkFormValidity())}
                                value={this.state.pass.new_password} />
                        </div>
                        <div className="col-12">
                        <FormInput label={t("Confirmation")}
                            type="password"
                            name="conf_password"
                            id="conf_password"
                            validation={(value) => validatePasswordMatch(value, this.state.pass.new_password)}
                            required
                            handle={(e) => this.handleInputChange(e, null, 'pass', () => this.checkFormValidity())}
                            value={this.state.pass.conf_password} />
                        </div>
                        <div className="col-12">
                            <PasswordHelper password={this.state.new_password} />
                        </div>
                    </div>

                    <hr className="form-divider form-divider--big"/>
                    <div className="primary-form__buttons primary-form__buttons--right">
                        <button type="submit" disabled={!this.state.formIsValid} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('Save')}</button>
                    </div>
                    <p className="primary-form__required-fields">* {t('required_fields')}</p>
                </form>
            </div>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(EditPassword)), EditPassword));