import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import LoadingOverlay from 'react-loading-overlay';
import { withToast } from '../../App';
import { RegisterLayout } from '../../components/Layouts';
import {FileInput} from '../../components/FormComponents';
import { validatePdf } from 'helpers/validation';

class RegisterStep3 extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            register_step3: {
                kbis: false,
                rib: false,
            },
            formIsValid: true,
            isActive: true
        }
        this._isMounted = false;
    }

    checkFormValidity = () => {
        this.setState({formIsValid: true})
    }

    componentDidMount() {
        this._isMounted = true;
        this.checkIsConnectedPWA();
        //Load localstorage value
        this.loadStorageValues(['register_step3'], [this.state.register_step3], this.checkFormValidity);
        this._isMounted && this.checkKBISAndRIBExist();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async checkKBISAndRIBExist() {
        let url = "/register/step3";
        let method = "GET";
        let data = await this.request(url, method);

        if (data && data.status === "ok") {
            this._isMounted && this.setState({
                register_step3: {
                    kbis: data.kbis ? {label: 'see_kbis_file', url: data.kbis} : false,
                    rib: data.rib ? {label: 'see_rib_file', url: data.rib} : false,
                },
                isActive: false
            });
        }
        else {
            this._isMounted && this.setState({
                isActive: false
            });
        }
    }

    async deleteFile(file) {
        let url = '/register/step3/delete/' + file
        let method = 'POST'
        let csrf = await this.getCsrfToken("/register/csrf");
        let body = new FormData()
        body.append("_csrf_token", csrf);

        let data = await this.request(url, method, body, true)
        if (data && data.status === "ok") {
            this._isMounted && this.setState({
                register_step3: {
                    kbis: data.kbis ? {label: 'see_kbis_file', url: data.kbis} : false,
                    rib: data.rib ? {label: 'see_rib_file', url: data.rib} : false,
                },
                isActive: false
            }, () => console.log(this.state));
        }
        else {
            this._isMounted && this.setState({
                isActive: false
            });
        }
    }

    async afterSubmissionStep3(event, go_back = false) {
        const { t } = this.props;
        event.preventDefault();
        let data = await this.registerStep3();

        if (data && data.status === "ok") {
            localStorage.setItem('register_step3', JSON.stringify(this.state.register_step3));
            if (!go_back) {
                this.props.addToast(t('Third step of registration successful'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
                this.props.history.push('/register/step4');
            }
        }

        if (go_back) this.props.history.push('/register/step2');
    }

    async registerStep3() {
        let url = "/register/step3";
        let method = "POST";
        let csrf = await this.getCsrfToken("/register/csrf");
        let rib = document.getElementById('rib').files[0];
        let kbis = document.getElementById('kbis').files[0];
        let body = new FormData();
        if (rib)
            body.append("rib_file", rib);
        if (kbis)
            body.append("kbis_file", kbis);
        body.append("_csrf_token", csrf);
        let data = await this.request(url, method, body, true);
        return data;
    }

    render() {
        const { t } = this.props;
        return (
            <RegisterLayout step={3}>
                <div className="register__form primary-form">
                    <hr className="form-divider form-divider--big"/>
                    <LoadingOverlay active={this.state.isActive} classNamePrefix="loader_" spinner text={t('loading')}>
                        <form onSubmit={(e) => this.afterSubmissionStep3(e)}>
                            <div className="row">
                                <div className="col-md-6">
                                    <FileInput
                                        required
                                        id="rib"
                                        name="rib"
                                        validation={validatePdf}
                                        label={t('rib_instruction')}
                                        link={this.state.register_step3.rib}
                                        onDelete={() => this.deleteFile('RIB')}
                                        handle={(e) => console.log(e)}/>
                                </div>
                                <div className="col-md-6">
                                    <FileInput
                                        required
                                        id="kbis"
                                        name="kbis"
                                        validation={validatePdf}
                                        label={t('kbis_instruction')}
                                        link={this.state.register_step3.kbis}
                                        onDelete={() => this.deleteFile('KBIS')}
                                        handle={(e) => console.log(e)}/>
                                </div>
                            </div>
                            <hr className="form-divider form-divider--big"/>
                            <div className="primary-form__buttons">
                                <button type="button" onClick={(e) => this.afterSubmissionStep3(e, true)} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-bleu-a3">{t('Previous')}</button>
                                <button type="submit" disabled={!this.state.formIsValid} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('Next')}</button>
                            </div>
                            <p className="primary-form__required-fields">* {t('required_fields')}</p>
                        </form>
                    </LoadingOverlay>
                </div>
            </RegisterLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(RegisterStep3)), RegisterStep3));