import React from 'react';

const Input = ({ title, name, id, handle, value, min, max, step, type, disabled, onClick, labelOnRight }) => {


    return (
        <div>
            {
                (!labelOnRight && title) ?
                    <label {...(id && { htmlFor: id })}>{title}</label>
                    :
                    ""
            }
            <input
                name={name}
                {...(id && { id: id })}
                {...(min && { min: min })}
                {...(max && { max: max })}
                {...(step && { step: step })}
                {...(type === "checkbox" && { checked: value })}
                type={type}
                value={value || ""}
                {...(handle && { onChange: handle })}
                {...(onClick && { onClick: onClick })}
                {... (disabled && { disabled: disabled })}
            />
            {
                (labelOnRight && title) ?
                    <label {...(id && { htmlFor: id })}>{title}</label>
                    :
                    ""
            }
        </div>
    );

}

export default Input;