import L from 'leaflet'
import { MapContainer, TileLayer, Marker, AttributionControl } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { useEffect, useState } from 'react';
import MarkerAvailable from '../../assets/img/icons/terminal-marker-available.png'
import MarkerUnavailable from '../../assets/img/icons/terminal-marker-unavailable.png'
import MarkerCharging from '../../assets/img/icons/terminal-marker-charging.png'
import ReactToolTip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const TerminalMap = ({terminals, focusedTerminal, onMarkerClick}) => {
    const ZOOM = 13
    const [map, setMap] = useState(null)
    const {t} = useTranslation()

    const whenCreated = (m) => {
        setMap(m)
        setTimeout(ReactToolTip.rebuild,1000)
    }

    useEffect(() => {
        if (!map) return

        if (focusedTerminal) {
            map.flyTo([focusedTerminal.gpsLat, focusedTerminal.gpsLon], ZOOM, {animated: true, duration: 1})
            
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        } else if (terminals.length > 0) {
            const bounds = terminals.map(terminal => [terminal.gpsLat, terminal.gpsLon])
            map.flyToBounds(bounds, {animated: true, duration: 1})
        }
        ReactToolTip.rebuild()
    }, [focusedTerminal, map, terminals])

    return (
        <div className="terminal-map">
            <MapContainer
                center={[46.71109, 1.7191036]}
                zoom={ZOOM}
                scrollWheelZoom={false}
                whenCreated={whenCreated}
                style={{height: '100%', width: '100%'}}
                attributionControl={false}
            >
                
                <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                <AttributionControl position="bottomleft"/>
                {terminals.map(terminal => (
                    <Marker
                        eventHandlers={{click: () => onMarkerClick(terminal)}}
                        icon={TerminalMarker(terminal,focusedTerminal && terminal.id === focusedTerminal.id, t)}
                        key={terminal.id}
                        position={[terminal.gpsLat, terminal.gpsLon]}>
                    </Marker>
                ))}
            </MapContainer>
            <ul className="terminal-map__legend">
                <li><img src={MarkerAvailable} alt="" role="presentation"/>{t('Available')}</li>
                <li>
                    <img src={MarkerCharging} alt="" role="presentation"/>
                    <span>{t('Charging')}</span>
                </li>
                <li>
                    <img src={MarkerUnavailable} alt="" role="presentation"/>
                    <span>{t('Unavailable')}</span>
                </li>
            </ul>
        </div>
    )
}

const TerminalMarker = (terminal, focused = true, t) => {
    const statusIcons = {
        'Available': MarkerAvailable,
        'AvailableFull': MarkerAvailable,
        'Unavailable': MarkerUnavailable,
        'UnavailableFull': MarkerUnavailable,
        'UnavailableOccupiedFull': MarkerUnavailable,
        'Occupied': MarkerCharging,
        'OccupiedFull': MarkerCharging,
        'UnavailableOccupied': MarkerCharging,
        'default': MarkerUnavailable,
    }
    const iconUrl = statusIcons[terminal.status] || statusIcons['default']

    const tip = `
        <div class='terminal-tooltip__top'>
            <span class='terminal-tooltip__status terminal-tooltip__status--${terminal.status}'></span>
            <span>${terminal.cpoTerminalId} - ${terminal.address}</span>
        </div>
        <div>${t('last_update')}: ${moment.utc(terminal.lastUpdate.date).format(t('DATE_HOURS_FORMAT'))}</div>
    `

    return L.divIcon({
        className: `leaflet-marker${focused ? ' focused' : ''}`,
        iconSize: [40,41],
        html: `<img src="${iconUrl}" width="40", height="41" alt="" data-tip="${tip}"/>`
    })
}

export default TerminalMap