import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import CustomComponent from 'components/customComponent';
import { DefaultLayout } from 'components/Layouts';
import { FormSelect } from 'components/FormComponents';
import LoadingOverlay from 'react-loading-overlay';

class StopDistribution extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            distribution: {
                transaction: '',
            },
            formIsValid: false,
            transactions: [],
            isLoading: true
        }
        this._isMounted = false
    }

    checkFormValidity () {
        const isValid = (
            this.state.distribution.transaction !== ''
        )
        this.setState({formIsValid: isValid})
    }

    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.getTransactions();
        this.setState({isLoading: false});
    }

    async getTransactions() {
        let url = "/transactions/?running=true";
        let method = "GET";
        let data = await this.request(url, method);
        if (data && data.transactions) {
            const filteredTransactions = data.transactions.filter(t => t.status === "Charging")
            this._isMounted && this.setState({transactions: filteredTransactions});
        }
        else {
            this.setState({ isActive: false });
        }
    }

    async onSubmit(e) {
        const { t } = this.props;
        e.preventDefault()
        this.setState({isLoading: true})
        let url = "/terminal/operation/stop/" + this.state.distribution.transaction;
        let method = "POST";
        let csrf = await this.getCsrfToken("/terminal/csrf");
        let body = JSON.stringify({
            _csrf_token: csrf
        });
        let data = await this.request(url, method, body);
        if (data && data.status === "ok") {
            this.props.addToast(t('Charging stopped successfully'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
        }
        this.setState({isLoading: false})
    }

    render () {
        const {t} = this.props

        return (
            <DefaultLayout>
                <LoadingOverlay active={this.state.isLoading} classNamePrefix="loader_" spinner text={t('loading')} />
                <h1 className="main-title">{t('remote_operations')}</h1>
                <div className="primary-form">
                    <form onSubmit={(e) => this.onSubmit(e)}>
                        <h2 className="primary-form__title">{t('stop_a_distribution')}</h2>
                        <hr className="form-divider form-divider--big" />
                        <div className="row">
                            <div className="col-12">
                                <FormSelect
                                    required
                                    id="transaction"
                                    name="transaction"
                                    label={t('transaction')}
                                    value={this.state.distribution.transaction}
                                    handle={(e) => this.handleInputChange(e, null, 'distribution', () => this.checkFormValidity())}
                                    options={this.state.transactions.map((t) => ({value: t.id, name: `${t.connector.terminal.cpoTerminalId} - ${t.connector.position} - ${t.boat.eni} - ${t.boat.name}`}))}
                                />
                            </div>
                        </div>
                        <div className="primary-form__buttons primary-form__buttons--right">
                            <button type="submit" disabled={!this.state.formIsValid} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('send')}</button>
                        </div>
                        <p className="primary-form__required-fields">* {t('required_fields')}</p>
                    </form>
                </div>
            </DefaultLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(StopDistribution)), StopDistribution));