import { useState, useEffect } from 'react';
import IconButton from '../../components/IconButton';
import Modal from 'components/Modal';
import { SelectFilter, AutoCompleteFilter } from 'components/Filters';
import { LineChart, LineChartList, BarChart, BarChartList, PieChart, PieChartList } from '../../components/Statistics';
import { API_URL } from 'config';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

const periodOptions = [
    {value: 'years', name: 'per_year'},
    {value: 'months', name: 'per_month'},
    {value: 'weeks', name: 'per_week'},
    {value: 'days', name: 'per_day'},
]

export const AvailabilityChart = ({title, description, fluidType}) => {
    const {t} = useTranslation()

    const [open, setOpen] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    const [data, setData] = useState([])

    const color = fluidType === 'Water' ? 'blue' : 'green'

    useEffect(() => {
        const color_map = {
            'Water': {
                'Available': '#436cc6',
                'Charging': '#5e92ff',
                'Unknown': '#eee',
            },
            'Electricity': {
                'Available': '#237f21',
                'Charging': '#5ecb63',
                'Unknown': '#eee',
            }
        }

        // Reminder =>  CPO Connector statuses
        // ['Unknown', 'Available', 'Preparing', 'Charging', 'Finishing', 'SuspendedEVSE', 'SuspendedEV', 'Reserved', 'Unavailable', 'Faulted']
        const getData = async () => {
            let url = `${API_URL}/dashboard/stats/?target=connectors`;
    
            try {
                let reponse = await fetch(url, {method: 'GET', credentials: 'include', headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json'
                }})
                let data = await reponse.json()
    
                if (data && data.connectors) {
                    const connectors = data.connectors
                        .filter(connector => connector.fluidType === fluidType && ['Available', 'Charging', 'Unknown'].includes(connector.status))
                        .map(connector => ({
                            id: connector.status,
                            label: t(connector.status),
                            value: connector.total,
                            color: color_map[fluidType][connector.status]
                        }))
                    setData(connectors)
                }
            } catch (e) {
                console.log(e)
            }
        }
        getData()
    }, [fluidType, t])

    useEffect(() => ReactTooltip.hide, [fullScreen, open])

    return <>
        <h2 className={`section-card__title section-card__title--${color}`}>{title}</h2>
        <div className={`charts${fullScreen ? ' charts--fullscreen' : ''}`}>
            <div className="charts__container charts__container--homepage">
                <PieChart data={data} fluidType={fluidType}/>
            </div>
            <div className="charts__footer">
                <IconButton
                    label="accessible_version"
                    icon="vnf-icons-icon-m-oeil-outline"
                    focusIcon="vnf-icons-icon-m-oeil-solid"
                    onClick={() => setOpen(true)}/>
                <IconButton
                    label="show_fullscreen"
                    icon={`vnf-icons-icon-m-${fullScreen ? 'moins' : 'plus'}-outline`}
                    focusIcon={`vnf-icons-icon-m-${fullScreen ? 'moins' : 'plus'}-rond-solid`}
                    onClick={() => setFullScreen(!fullScreen)}/>
            </div>
        </div>
        <Modal open={open} onClose={() => setOpen(false)} title={title} description={description}>
            <PieChartList data={data} fluidType={fluidType}/>
        </Modal>
    </>
}

export const DistributionChart = ({title, description, fluidType}) => {
    const [open, setOpen] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    const [period, setPeriod] = useState('months')
    const [terminalSuggestions, setTerminSuggestions] = useState([])
    const [terminal, setTerminal] = useState('')
    const [terminalID, setTerminalID] = useState(null)
    const [boatSuggestions, setBoatSuggestions] = useState([])
    const [boat, setBoat] = useState('')
    const [boatID, setBoatID] = useState(null)
    const [data, setData] = useState([])
    const [suggestionTimeout, setSuggestionTimeout] = useState(false)
    const {t} = useTranslation()

    useEffect(() => {
        const getData = async () => {
            let url = `${API_URL}/dashboard/stats/?target=transactions&granularity=${period}`;
            if (boatID) url += `&boat_id=${boatID}`;
            if (terminalID) url += `&terminal_id=${terminalID}`

            try {
                let reponse = await fetch(url, {method: 'GET', credentials: 'include', headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json'
                }})
                let data = await reponse.json()

                if (data && data.transactions) {
                    console.log(data.transactions)

                    let transactions = data.transactions.map(point => {
                        let item = {date: point.date}
                        item[t('distribution_finished')] = fluidType === "Water" ? point.water_finished : point.electric_finished 
                        item[t('distribution_running')] = fluidType === "Water" ? point.water_running : point.electric_running
                        item[t('distribution_zero')] = fluidType === "Water" ? point.water_zero : point.electric_zero

                        return item
                    })

                    setData(transactions)
                }
            } catch (e) {
                console.log(e)
            }
        }
        getData()
    }, [period, terminalID, boatID, fluidType, t], () => {
        if (suggestionTimeout) clearTimeout(suggestionTimeout)
    })

    useEffect(() => ReactTooltip.hide, [fullScreen, open])

    const handleSuggestion = async (e) => {
        if (suggestionTimeout) clearTimeout(suggestionTimeout)
        setSuggestionTimeout(setTimeout(async () => {
            const {value, name} = e.target
            const url = `${API_URL}/transactions/autocomplete/${name}?q=${value}`;
    
            try {
                let reponse = await fetch(url, {method: 'GET', credentials: 'include', headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json'
                }})
                let data = await reponse.json()
    
                if (data && data.strings) {
                    switch(name) {
                        case 'terminal': setTerminSuggestions(data.strings); break;
                        case 'boat': setBoatSuggestions(data.strings); break;
                        default: break;
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }, 500))
    }

    const handleFilter = (e) => {
        const {value, name} = e.target
        const {id} = e.target.dataset
        switch(name) {
            case 'period': setPeriod(value); break;
            case 'terminal': setTerminal(value); setTerminalID(id); break;
            case 'boat': setBoat(value); setBoatID(id); break;
            default: break;
        }
    }

    return <>
        <div className={`charts${fullScreen ? ' charts--fullscreen' : ''}`}>
            <div className="charts__filters">
                <SelectFilter name="period" value={period} handle={handleFilter} options={periodOptions}/>
                <AutoCompleteFilter placeholder={t('terminal')} name="terminal" value={terminal} onFieldChange={handleSuggestion} handle={handleFilter} suggestions={terminalSuggestions}/>
                <AutoCompleteFilter placeholder={t('boat')} name="boat" value={boat} onFieldChange={handleSuggestion} handle={handleFilter} suggestions={boatSuggestions}/>
            </div>
            <div className="charts__container charts__container--homepage">
                <BarChart data={data} fluidType={fluidType}/>
            </div>
            <div className="charts__footer">
                <IconButton
                    label="accessible_version"
                    icon="vnf-icons-icon-m-oeil-outline"
                    focusIcon="vnf-icons-icon-m-oeil-solid"
                    onClick={() => setOpen(true)}/>
                <IconButton
                    label="show_fullscreen"
                    icon={`vnf-icons-icon-m-${fullScreen ? 'moins' : 'plus'}-outline`}
                    focusIcon={`vnf-icons-icon-m-${fullScreen ? 'moins' : 'plus'}-rond-solid`}
                    onClick={() => setFullScreen(!fullScreen)}/>
            </div>
        </div>
        <Modal open={open} onClose={() => setOpen(false)} title={title} description={description}>
            <div className="charts__filters px-0">
                <SelectFilter name="period" value={period} handle={handleFilter} options={periodOptions}/>
                <AutoCompleteFilter placeholder={t('terminal')} name="terminal" value={terminal} onFieldChange={handleSuggestion} handle={handleFilter} suggestions={terminalSuggestions}/>
                <AutoCompleteFilter placeholder={t('boat')} name="boat" value={boat} onFieldChange={handleSuggestion} handle={handleFilter} suggestions={boatSuggestions}/>
            </div>
            <BarChartList data={data} fluidType={fluidType}/>
        </Modal>
    </>
}

export const ConsumptionChart = ({title, description, fluidType}) => {
    const [open, setOpen] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    const [period, setPeriod] = useState('months')
    const [terminalSuggestions, setTerminSuggestions] = useState([])
    const [terminal, setTerminal] = useState('')
    const [terminalID, setTerminalID] = useState(null)
    const [boatSuggestions, setBoatSuggestions] = useState([])
    const [boat, setBoat] = useState('')
    const [boatID, setBoatID] = useState(null)
    const [data, setData] = useState([])
    const [suggestionTimeout, setSuggestionTimeout] = useState(null)
    const {t} = useTranslation()

    useEffect(() => {
        const getData = async () => {
            let url = `${API_URL}/dashboard/stats/?target=consumptions&granularity=${period}`;
            if (boatID) url += `&boat_id=${boatID}`;
            if (terminalID) url += `&terminal_id=${terminalID}`

            try {
                let reponse = await fetch(url, {method: 'GET', credentials: 'include', headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json'
                }})
                let data = await reponse.json()
    
                if (data && data.consumptions) {
                    let consumptions = data.consumptions.map(point => ({
                        x: point.date,
                        y: fluidType === 'Water' ? parseFloat(point.m3Consumed).toFixed(2) : parseFloat(point.kwhConsumed).toFixed(2)
                    }))
                    setData(consumptions)
                }
            } catch (e) {
                console.log(e)
            }
        }
        getData() 
    }, [period, terminalID, boatID, fluidType, t], () => {
        if (suggestionTimeout) clearTimeout(suggestionTimeout)
    })

    useEffect(() => ReactTooltip.hide, [fullScreen, open])

    const handleSuggestion = async (e) => {
        if (suggestionTimeout) clearTimeout(suggestionTimeout)
        setSuggestionTimeout(setTimeout(async () => {
            const {value, name} = e.target
            const url = `${API_URL}/transactions/autocomplete/${name}?q=${value}`;
    
            try {
                let reponse = await fetch(url, {method: 'GET', credentials: 'include', headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json'
                }})
                let data = await reponse.json()
    
                if (data && data.strings) {
                    switch(name) {
                        case 'terminal': setTerminSuggestions(data.strings); break;
                        case 'boat': setBoatSuggestions(data.strings); break;
                        default: break;
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }, 500))
    }

    const handleFilter = (e) => {
        const {value, name} = e.target
        const {id} = e.target.dataset
        switch(name) {
            case 'period': setPeriod(value); break;
            case 'terminal': setTerminal(value); setTerminalID(id); break;
            case 'boat': setBoat(value); setBoatID(id); break;
            default: break;
        }
    }


    return <>
        <div className={`charts${fullScreen ? ' charts--fullscreen' : ''}`}>
            <div className="charts__filters">
                <SelectFilter name="period" value={period} handle={handleFilter} options={periodOptions}/>
                <AutoCompleteFilter placeholder={t('terminal')} name="terminal" value={terminal} onFieldChange={handleSuggestion} handle={handleFilter} suggestions={terminalSuggestions}/>
                <AutoCompleteFilter placeholder={t('boat')} name="boat" value={boat} onFieldChange={handleSuggestion} handle={handleFilter} suggestions={boatSuggestions}/>
            </div>

            <div className="charts__container charts__container--homepage">
                <LineChart data={data} fluidType={fluidType} />
            </div>
            <div className="charts__footer">
                <IconButton
                    label="accessible_version"
                    icon="vnf-icons-icon-m-oeil-outline"
                    focusIcon="vnf-icons-icon-m-oeil-solid"
                    onClick={() => setOpen(true)}/>
                <IconButton
                    label="show_fullscreen"
                    icon={`vnf-icons-icon-m-${fullScreen ? 'moins' : 'plus'}-outline`}
                    focusIcon={`vnf-icons-icon-m-${fullScreen ? 'moins' : 'plus'}-rond-solid`}
                    onClick={() => setFullScreen(!fullScreen)}/>
            </div>
        </div>
        <Modal open={open} onClose={() => setOpen(false)} title={title} description={description}>
            <div className="charts__filters px-0">
                <SelectFilter name="period" value={period} handle={handleFilter} options={periodOptions}/>
                <AutoCompleteFilter placeholder={t('terminal')} name="terminal" value={terminal} onFieldChange={handleSuggestion} handle={handleFilter} suggestions={terminalSuggestions}/>
                <AutoCompleteFilter placeholder={t('boat')} name="boat" value={boat} onFieldChange={handleSuggestion} handle={handleFilter} suggestions={boatSuggestions}/>
            </div>
            <LineChartList data={data} fluidType={fluidType}/>
        </Modal>
    </>
}