import CustomComponent from 'components/customComponent';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import { FormCheckBox } from 'components/FormComponents';
import { RegisterLayout } from '../../components/Layouts';
import i18n from 'i18next';

class RegisterStep5 extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            register_step5: {
                acknowledged_warnings: false,
                accept_cgu: false
            },
            formIsValid: true
        }
    }

    checkFormValidity = () => {
        this.setState({formIsValid: true})
    }

    componentDidMount() {
        this.checkIsConnectedPWA();
        //Load localstorage value
        this.loadStorageValues(['register_step5'], [this.state.register_step5], this.checkFormValidity);
    }

    async afterSubmissionStep5(event) {
        const { t } = this.props;
        event.preventDefault();
        let data = await this.registerStep5();
        if (data && data.status === "ok") {
            localStorage.clear();
            this.props.addToast(t('Last step of registration successful'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
            this.props.history.push("/login");
        }
    }

    async registerStep5() {
        let url = "/register/step5";
        let method = "POST";
        let csrf = await this.getCsrfToken("/register/csrf");
        let body = JSON.stringify({
            acknowledged_warnings: this.state.register_step5.acknowledged_warnings,
            accept_cgu: this.state.register_step5.accept_cgu,
            user_language: i18n.language,
            _csrf_token: csrf
        });
        let response = await this.request(url, method, body);
        return response;
    }

    render() {
        const { t } = this.props;
        return (
            <RegisterLayout step={5}>
                <div className="register__form primary-form">
                    <div className="py-2" dangerouslySetInnerHTML={{__html: t('HTML__CGU_disclaimer', {interpolation: {escapeValue: false}})}}></div>
                    <form onSubmit={(e) => this.afterSubmissionStep5(e)}>
                        <hr className="form-divider"/>
                        <div className="checkbox-container">
                            <FormCheckBox
                                label={t('acknowledged_warnings')}
                                name="acknowledged_warnings"
                                id="acknowledged_warnings"
                                required
                                handle={(e) => this.handleInputChange(e, null, 'register_step5')}
                                value={this.state.register_step5.acknowledged_warnings} />
                            <FormCheckBox 
                                label={t('HTML__accept_cgu')}
                                name="accept_cgu"
                                id="accept_cgu"
                                required
                                handle={(e) => this.handleInputChange(e, null, 'register_step5')}
                                value={this.state.register_step5.accept_cgu} />
                        </div>
                        <div className="primary-form__buttons">
                            <Link to="/register/step4" className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-bleu-a3">{t('Previous')}</Link>
                            <button type="submit" disabled={!this.state.formIsValid} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('Register')}</button>
                        </div>
                        <p className="primary-form__required-fields">* {t('required_fields')}</p>
                        <p className="primary-form__required-fields">{t('brower_compatibily_disclaimer')}</p>
                    </form>
                </div>
            </RegisterLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(RegisterStep5)), RegisterStep5));