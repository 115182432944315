import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import SlideDown from 'react-slidedown';
import OutsideClick from 'react-outclick';
import MomentFnsUtils from '@date-io/moment'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'

export const FilterDropDown = ({children}) => {
    const {t} = useTranslation()
    const [open, setOpen] = useState(false)

    return (
        <div className='filter-dropdown'>
            <button className={`filter-dropdown__btn${open ? ' open' : ''}`} onClick={() => setOpen(!open)}>
                <i className="vnf-icons-icon-m-filtres-a-outline"></i>
                {open ? t('less_filters') : t('more_filters')}
                <i className={`vnf-icons-icon-m-fleche-a-${open ? 'haut' : 'bas'}-outline`}></i>
            </button>
            <SlideDown className={`filter-dropdown__content${open ? ' open' : ''}`} closed={!open}>{children}</SlideDown>
        </div>
    )
}

export const PerPageFilter = ({options, name, handle, value}) => {
    const {t} = useTranslation()

    return (
        <select value={value} name={name} className="filter-elements" aria-labelledby="filter-elements-label" onChange={handle}>
            <option value=" " id="filter-elements-label" disabled>{t('per_page')}</option>
            {options.map(option => <option key={option} value={option}>{option}</option>)}
        </select>
    )
}

export const TextFilter = ({id, name, value, placeholder = "", handle, label, showLabel, small}) => {
    return (
        <div className={`filter filter--text${small ? ' filter--small': ''}`}>
            <label htmlFor={id} className={!showLabel ? 'sr-only' : ''}>{label}:</label>
            <div className="filter__input-wrapper">
                <input id={id} name={name} type="text" value={value} placeholder={placeholder} onChange={handle}/>
            </div>
        </div>
    )
}

export const AutoCompleteFilter = ({id, name, value, placeholder = "", onFieldChange, handle, label, showLabel, suggestions, small, minChars = 2}) => {
    const [thisValue, setThisValue] = useState(value)
    const [visible, setVisible] = useState(true)

    const handleChange = (e) => {
        setThisValue(e.target.value)
        
        let trigger_handle = e.target.value === ''|| e.type === 'click'
        let trigger_fieldChange = !trigger_handle && e.target.value.length >= minChars
        
        if (trigger_handle) {
            setVisible(false)
            handle(e)
        }
        
        trigger_fieldChange && onFieldChange(e)
    }

    useEffect(() => setVisible(true), [suggestions])

    return (
        <div className={`filter filter--autocomplete${small ? ' filter--small': ''}`}>
            <label htmlFor={id} className={!showLabel ? 'sr-only' : ''}>{label}:</label>
            <div className="filter__input-wrapper">
                <input id={id} name={name} type="text" value={thisValue} placeholder={placeholder} onChange={handleChange} autoComplete="off"/>
                {(suggestions && suggestions.length > 0 && visible) && (
                    <OutsideClick onOutsideClick={() => setVisible(false)}>
                        <ul className="filter__suggestions">
                            {suggestions.map((suggestion, i) => (
                                <li key={i}><button data-id={suggestion.id} name={name} onClick={handleChange} value={suggestion.name}>{suggestion.name}</button></li>
                            ))}
                        </ul>
                    </OutsideClick>
                )}
            </div>

        </div>
    )
}

export const DateFilter = ({value, handle, id, name, showLabel, label, small, minDate, minDateMessage}) => {
    const [error, setError] = useState(false)
    const {t} = useTranslation()

    const onChange = (e) => {
        setError(e && !e._isValid)
        handle(e)
    }

    return (
        <div className={`filter filter--date${small ? ' filter--small': ''}`}>
            <label htmlFor={id} className={!showLabel ? 'sr-only' : ''}>{label}:</label>
            <MuiPickersUtilsProvider utils={MomentFnsUtils}>
                <KeyboardDatePicker
                    {...minDate && (minDate={minDate})}
                    {...minDateMessage && (minDateMessage={minDateMessage})}
                    className={error ? 'error' : ''}
                    keyboardIcon={<i className="vnf-icons-icon-m-planning-outline"></i>}
                    inputProps={{id: id, name: name}}
                    value={value}
                    onChange={onChange}
                    invalidDateMessage={t('invalid_input')}
                    inputVariant="filled"
                    format={t('DATE_FORMAT')} />
            </MuiPickersUtilsProvider>
        </div>
    )
}

export const SelectFilter = ({value, handle, id, name, showLabel, label, options, small}) => {
    const {t} = useTranslation()

    return (
        <div className={`filter filter--select${small ? ' filter--small': ''}`}>
            <label htmlFor={id} className={!showLabel ? 'sr-only' : ''}>{t(label)}:</label>
            <div className="filter__input-wrapper">
                <select name={name} id={id} value={value} onChange={handle}>
                    {options.map(option => (
                        <option key={option.value} value={option.value}>{t(option.name)}</option>
                    ))}
                </select>
            </div>
        </div>
    )
}

export const CheckboxFilter = ({value, handle, id, name, label}) => {
    const {t} = useTranslation()
    return (
        <div className="filter filter--checkbox">
            <input type="checkbox" id={id} name={name} onChange={handle} checked={value}/>
            <label htmlFor={id}>{t(label)}</label>
        </div>
    )
}

export const SortbyFilter = ({label, onClick, active, direction }) => {
    return (
        <button
            className="sortby"
            type="button"
            onClick={onClick}>
            {label}
            {(active && direction === 'ASC') && <i className="vnf-icons-icon-m-fleche-a-bas-outline"></i>}
            {(active && direction === 'DESC') && <i className="vnf-icons-icon-m-fleche-a-haut-outline"></i>}
            {!active && <i className="vnf-icons-icon-m-fleche-a-haut-bas-solid"></i>}
        </button>
    )
}