import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../hooks/useWindowDimensions';
import ScrollContainer from 'react-indiana-drag-scroll'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({file}) => {
    const [numPages, setNumpages] = useState(null)
    const [page, setPage] = useState(1)
    const [scale, setScale] = useState(1)
    const {width} = useWindowDimensions()

    const onDocumentLoad = (pdf) => {
        setNumpages(pdf._pdfInfo.numPages)
    }

    const zoomIn = () => setScale(Math.min(scale * 2, 8))
    const zoomOut = () => setScale(Math.max(scale / 2, .25))

    const {t} = useTranslation()

    return (
        <div className="pdf-reader">
            <div className="pdf-reader__actions">
                <div className="pdf-reader__zoom">
                    <button onClick={zoomOut}>
                        <i className="vnf-icons-icon-m-dezoom-outline"></i>
                        <span className="sr-only">{t('zoom_out')}</span>
                    </button>
                    <button onClick={zoomIn}>
                        <i className="vnf-icons-icon-m-zoom-outline"></i>
                        <span className="sr-only">{t('zoom_in')}</span>
                    </button>
                </div>

                <div className="pdf-reader__pages">
                    <button disabled={page === 1} onClick={() => setPage(page - 1)}>
                        <i className="vnf-icons-icon-fleche-a-gauche-outline"></i>
                        <span className="sr-only">{t('go_to_previous_page')}</span>
                    </button>
                    <span>{page} / {numPages}</span>
                    <button disabled={page === numPages} onClick={() => setPage(page + 1)}>
                        <i className="vnf-icons-icon-m-fleche-a-droite-outline"></i>
                        <span className="sr-only">{t('go_to_next_page')}</span>
                    </button>
                </div>

                <div className="pdf-reader__download">
                    <button onClick={() => window.open(file)}>
                        <i className="vnf-icons-icon-m-imprimante-outline"></i>
                        <span className="sr-only">{t('print')}</span>
                    </button>
                    <a className="pdf-reader__download" href={file} target="_blank" rel="noreferrer" data-tip={t('download_file')}>
                        <i className="vnf-icons-icon-m-telechargement-outline"></i>
                        <span className="sr-only">{t('download_file')}</span>
                    </a>
                </div>
            </div>
            <ScrollContainer className="pdf-reader__document">
                <Document file={file} renderMode="svg" onLoadSuccess={onDocumentLoad}>
                    <Page pageNumber={page} scale={scale} width={Math.min(width - 30, 800)}/>
                </Document>
            </ScrollContainer>
        </div>
    )
}

export default PDFViewer