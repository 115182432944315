import CustomComponent from "./customComponent";
import {withToast} from "../App";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Modal from "./Modal";

class LoginCheck extends CustomComponent {
    intervalId = null;
    delays = {
        timestamp: 10*1000, // Delay to compare activity timestamp
        loggedIn: 15*60*1000, // Delay to check if still logged in
    }

    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
        }
    }

    componentDidMount() {
        const isLoggedPWA = this.checkIsConnectedPWA();
        if (!isLoggedPWA) this.checkTimer();
        this.intervalId = setInterval(() => this.checkTimer(), this.delays.timestamp);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    async checkTimer() {
        const {t} = this.props;
        const activityTimestamp = parseInt(localStorage.getItem('_activity_timestamp'));
        const now = Date.now();

        if (!isNaN(activityTimestamp) && (now - activityTimestamp > this.delays.loggedIn)) {
            const loggedIn = await this.checkIsConnected();

            if (!loggedIn) {
                localStorage.clear();
                this.props.addToast(t('disconnectedMsg'), {appearance: 'danger'});
                this.props.history.push('/login');
            } else {
                this.setState({openModal: true});
            }
        }
    }

    refreshButtonClick() {
        this.setState({openModal: false});
        localStorage.setItem('_activity_timestamp', `${Date.now()}`);
        this.props.history.go(0);
    }

    render() {
        const {t} = this.props;
        return (
            <Modal
                open={this.state.openModal}
                title={t('inactivity_title')}
                description={t('inactivity_desc')}
                disableClose
            >
                <div className="modal__buttons justify-content-center">
                    <button type="button" onClick={() => this.refreshButtonClick()} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-orange-a4">{t('refresh_page')}</button>
                </div>
            </Modal>
        )
    }
}

export default withToast(withTranslation()(withRouter(LoginCheck)));