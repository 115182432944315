import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import { FormInput, FormMulticheckbox, FormSelect } from 'components/FormComponents';
import { REGEX, validateEmail, validateName } from '../../helpers/validation';
import Modal from 'components/Modal';
import { ROLES } from 'config';

class EditModal extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            entities: [],
            roles: this.getAvailableRoles(),
            user: {
                firstName: this.props.user.firstName,
                lastName: this.props.user.lastName,
                email: this.props.user.email,
                role: this.props.user.roles.filter(role => role !== 'ROLE_USER')[0],
                entities_id: this.props.user.entities.map(e => e.id.toString()),
            },
            isActive: true
        }
        this._isMounted = false;
    }

    getAvailableRoles () {
        let mainRole = this.loadMainRole()
        let filteredRoles = ROLES.filter(role => !['ROLE_ARMATEUR', 'ROLE_NAVIGANT'].includes(role))
        if (mainRole === 'ROLE_ADMIN') {
            filteredRoles = filteredRoles.filter(role => role !== 'ROLE_SUPER_ADMIN')
        }

        return filteredRoles.map(role => ({value: role, name: role}))
    }

    componentDidMount() {
        this._isMounted = true;
        this.getEntites();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    checkFormValidity = () => {
        this.setState({formIsValid: (
            new RegExp(REGEX.name).test(this.state.user.firstName) &&
            new RegExp(REGEX.name).test(this.state.user.lastName) &&
            new RegExp(REGEX.email).test(this.state.user.email) &&
            ROLES.includes(this.state.user.role) &&
            this.state.user.entities_id.length > 0
        )})
    }

    async getEntites() {
        let url = "/entity/list";
        let method = "GET";
        let data = await this.request(url, method);
        if (data && data.entities) {
            this._isMounted && this.setState({
                entities: data.entities.map(entity => ({value: entity.id, name: entity.name}))
            })
        }
    }

    async afterSubmissionInfo(event) {
        const { t } = this.props;
        event.preventDefault();
        let url = "/user/" + this.props.user.id;
        let method = "POST";
        let csrf = await this.getCsrfToken("/user/csrf");
        let body = JSON.stringify({
            user: {
                ...this.state.user,
                roles: [this.state.user.role]
            },
            _csrf_token: csrf
        });

        let data = await this.request(url, method, body);
        if (data && data.status === "ok") {
            this.props.addToast(t('userModified'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
            this.props.onClose()
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Modal open={true} onClose={this.props.onClose}>
                <h1 className="main-title">{t('Users')}</h1>
                    <div className="primary-form">
                        <h2 className="primary-form__title">{t('edit_user')}</h2>
                        <hr className="form-divider form-divider--big"/>
                        <form onSubmit={(e) => this.afterSubmissionInfo(e)}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <FormInput
                                        required
                                        label={t("First name")}
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        validation={validateName}
                                        handle={(e) => this.handleInputChange(e, null, 'user', this.checkFormValidity)}
                                        value={this.state.user.firstName}/>
                                </div>
                                <div className="col-sm-6">
                                    <FormInput
                                        required
                                        label={t("Last name")}
                                        type="text"
                                        name="lastName"
                                        id="lastName"
                                        validation={validateName}
                                        handle={(e) => this.handleInputChange(e, null, 'user', this.checkFormValidity)}
                                        value={this.state.user.lastName}/>
                                </div>
                                <div className="col-sm-6">
                                    <FormInput
                                        required
                                        label={t("Email")}
                                        type="email"
                                        name="email"
                                        id="email"
                                        validation={validateEmail}
                                        handle={(e) => this.handleInputChange(e, null, 'user', this.checkFormValidity)}
                                        value={this.state.user.email}/>
                                </div>
                                <div className="col-sm-6">
                                    <FormSelect
                                        required
                                        label={t('Roles')}
                                        id="role"
                                        name="role"
                                        options={this.state.roles}
                                        value={this.state.user.role}
                                        handle={(e) => this.handleInputChange(e, null, 'user', this.checkFormValidity)}/>
                                </div>
                                <div className="col-12">
                                    <hr className="form-divider" />
                                    <FormMulticheckbox
                                        required
                                        label={t('Entity')}
                                        name="entities_id"
                                        options={this.state.entities}
                                        handle={(e) => this.handleInputChange(e, null, 'user', this.checkFormValidity)}
                                        checkedItems={this.state.user.entities_id} />
                                </div>
                            </div>

                            <hr className="form-divider form-divider--big"/>
                            <div className="primary-form__buttons">
                                <button type="button" onClick={this.props.onClose} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-bleu-a3">{t('cancel')}</button>
                                <button type="submit" disabled={!this.state.formIsValid} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('Save')}</button>
                            </div>
                            <p className="primary-form__required-fields">* {t('required_fields')}</p>
                        </form>
                    </div>
            </Modal>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(EditModal)), EditModal));
