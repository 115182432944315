import CustomComponent from './customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../App';
import logoVNF from '../assets/img/logo-vnf-bleu.svg';
import logoHaropa from '../assets/img/logo-haropa-bleu.svg';
import logoBorneEtEaux from '../assets/img/logo-borne_et_eaux.png';
import Cookies from 'js-cookie';

class Footer extends CustomComponent {
    replayTour () {
        Cookies.remove('SkipTour')
        this.props.history.go(0)
    }

    render () {
        const { t } = this.props;

        return (
            <footer className="footer">
                <div className="footer__top">
                    <img src={logoBorneEtEaux} alt="logo Borne et Eau" />
                    <p>{t('A service developped by ...')}</p>
                </div>
                <div className="footer__bottom">
                    <p>{t('A service developped by ...')}</p>
                    <img src={logoVNF} alt="logo VNF" />
                    <div className="footer__img-separator"></div>
                    <img src={logoHaropa} alt="logo Haropa" />
                </div>
                <div className="footer__replay-tour">
                    <button onClick={() => this.replayTour()} type="button">{t('replay_tutorial')}</button>
                </div>
            </footer>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(Footer)), Footer));