import CustomComponent from 'components/customComponent';
import { DefaultLayout } from 'components/Layouts';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import logoBorneEtEaux from '../../assets/img/logo-borne_et_eaux.png';


class NotFound extends CustomComponent {

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        const {t} = this.props
        return (
            <DefaultLayout>
                <div className="homepage-logo" >
                    <img src={logoBorneEtEaux} alt="logo Borne et Eau" />
                </div>
                <div style={{textAlign: 'center'}}>
                    <h1 className="page-not-found__title">{t('page_not_found_title')}</h1>
                    <p className="page-not-found__text">{t('page_not_found_text')}</p>
                    <button className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-multi-color vnf-btn-bleu-a2 vnf-btn-box-shadow-vert-b1" onClick={this.goBack}>{t('go_back')}</button>
                </div>
            </DefaultLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(NotFound)), NotFound));