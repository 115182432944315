import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { withToast } from '../App';
import { useTranslation } from 'react-i18next';
import {
    DEFAULT_LAYOUT_SM,
    DEFAULT_LAYOUT_LG,
    FAKE_DATAVIZ_DATA_1,
    FAKE_DATAVIZ_DATA_2,
    FAKE_DATAVIZ_DATA_3,
    FAKE_DATAVIZ_DATA_4,
    FAKE_DATAVIZ_DATA_5,
    FAKE_DATAVIZ_DATA_6,
} from '../config';
import ScrollContainer from 'react-indiana-drag-scroll'
import moment from "moment";

export const ResponsiveGridLayout = WidthProvider(Responsive);
const dateTransform = (value, t) => moment(value).format(t('DATE_FORMAT'));

const LineChartTooltip = ({data, symbol}) => {
    const {t} = useTranslation();
    return (
        <div className="charts__tooltip">
            <span>{moment(data.point.data.x).format(t('DATE_HOURS_FORMAT'))}</span>
            <div className="charts__tooltip-top">
                <span className="charts__tooltip-badge" style={{backgroundColor: data.point.color}}></span>
                <span>{data.point.data.y}<small>{symbol}</small></span>
            </div>
        </div>
    )
}

export const LineChart = ({ data, fluidType = 'Water' }) => {
    const {t} = useTranslation();
    const chartData = [{
        id: fluidType === 'Water' ? t('water_consumption') : t('electric_consumption'),
        data: data
    }];
    const axisBottomValues = chartData[0].data
        .map(data => data.x)
        .filter((data, index, arr) => index === 0 || index === arr.length-1);

    return (
        <ResponsiveLine
            data={chartData}
            margin={{ top: 10, right: 40, bottom: 120, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 0, max: 'auto', stacked: true, reverse: false }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            colors={fluidType === 'Water' ? ['#5E92FF'] : ['#3CA941']}
            axisBottom={{tickValues: axisBottomValues,format: (value) => dateTransform(value, t),orient: 'bottom',tickSize: 0,tickPadding: 10,legendOffset: 36,legendPosition: 'middle'}}
            axisLeft={{orient: 'left',tickSize: 0,tickPadding: 10,tickRotation: 0,legendOffset: -40,legendPosition: 'middle'}}
            enableArea={true}
            areaOpacity={0.3}
            pointSize={5}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[{
                anchor: 'bottom-left',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 120,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 'auto',
                itemHeight: 16,
                itemOpacity: 1,
                symbolSize: 16,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
            }]}
            enableCrosshair={false}
            enableGridX={false}
            animate={false}
            tooltip={(data) => <LineChartTooltip data={data} symbol={fluidType === "Water" ? 'm3' : 'kWh'} />}/>
    )
}

export const LineChartList = ({ data, fluidType = 'Water' }) => {
    const {t} = useTranslation()

    return (
        <ScrollContainer hideScrollbars={false}>
            <table className={`charts__table charts__table--${fluidType === 'Water' ? 'blue' : 'green'}`}>
                <thead>
                    <tr>
                        <th>{t('date')}</th>
                        <th>{t('consumption')}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((point, index) => (
                        <tr key={index}>
                            <td>{point.x}</td>
                            <td>{point.y} {fluidType === "Water" ? 'm3' : 'kWh'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </ScrollContainer>
    )
}

const BarChartTooltip = ({data}) => {
    return (
        <div className="charts__tooltip">
            <span className="charts__tooltip-badge" style={{backgroundColor: data.color}}></span>
            <span>{data.value}</span>
        </div>
    )
}

export const BarChart = ({data, fluidType }) => {
    const {t} = useTranslation()

    const colors = fluidType === 'Water'
        ? ['#000000', '#5E92FF', '#436cc6']
        : ['#000000', '#5ecb63', '#237f21']

    const defs = [
        {id: 'running', type: 'patternLines', background: 'inherit', color: '#fff', rotation: 45, lineWidth: 3, spacing: 6}
    ]

    const fill = [
        {match: {id: t('distribution_running')}, id : 'running'},
    ]

    return (
        <ResponsiveBar
            data={data}
            keys={[t('distribution_zero'), t('distribution_running'), t('distribution_finished')]}
            defs={defs}
            fill={fill}
            margin={{ top: 10, right: 40, bottom: 120, left: 60 }}
            indexBy={'date'}
            padding={0.2}
            groupMode="stacked"
            colors={colors}
            borderColor={{ from: 'color', modifiers: [['darker', 2]] }}
            axisBottom={{orient: 'bottom',tickSize: 0,tickPadding: 10,tickRotation: -45,legendOffset: 36,legendPosition: 'middle'}}
            axisLeft={{orient: 'left',tickSize: 0,tickPadding: 10,tickRotation: 0,legendOffset: -40,legendPosition: 'middle'}}
            enableLabel={false}
            theme={{legends: { hidden: { text: { textDecoration: 'line-through' } } },}}
            legends={[{
                dataFrom: 'keys',
                anchor: 'bottom-left',
                direction: 'column',
                justify: false,
                translateX: 0,
                translateY: 120,
                itemsSpacing: 2,
                itemWidth: 140,
                itemHeight: 16,
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 16,
                symbolShape: 'circle',
                toggleSerie: true,
            }]}
            enableCrosshair={false}
            enableGridX={false}
            animate={false}
            tooltip={(data) => <BarChartTooltip data={data} />}/>
    )
};

export const BarChartList = ({ data, fluidType = 'Water' }) => {
    const {t} = useTranslation()

    return (
        <ScrollContainer hideScrollbars={false}>
            <table className={`charts__table charts__table--${fluidType === 'Water' ? 'blue' : 'green'}`}>
                <thead>
                    <tr>
                        <th>{t('date')}</th>
                        <th>{t('distribution_finished')}</th>
                        <th>{t('distribution_running')}</th>
                        <th>{t('distribution_zero')}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((point, index) => (
                        <tr key={index}>
                            <td>{point.date}</td>
                            <td>{point[t('distribution_finished')]}</td>
                            <td>{point[t('distribution_running')]}</td>
                            <td>{point[t('distribution_zero')]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </ScrollContainer>
    )
}

const PieChartTooltip = ({data}) => {
    return (
        <div className="charts__tooltip">
            <span className="charts__tooltip-badge" style={{backgroundColor: data.datum.color}}></span>
            <span>{data.datum.value}</span>
        </div>
    )
}

export const PieChart = ({ data }) => {
    const {t} = useTranslation()

    const defs = [
        {id: 'unavailable', type: 'patternLines', background: 'inherit', color: '#fff', rotation: 45, lineWidth: 3, spacing: 6}
    ]

    const fill = [
        {match: {label: t('Unavailable')}, id : 'unavailable'},
    ]

    return (
        <ResponsivePie
            defs={defs}
            fill={fill}
            data={data}
            margin={{ top: 10, right: 10, bottom: 40, left: 10 }}
            innerRadius={0}
            animate={false}
            padAngle={0}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            enableArcLinkLabels={false}
            enableArcLabels={false}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
            colors={data.map(element => { return element.color; })}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 40,
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ]
                }
            ]}
            tooltip={(data) => <PieChartTooltip data={data} />}/>
    )
}

export const PieChartList = ({ data, fluidType = 'Water' }) => {
    const {t} = useTranslation()

    return (
        <ScrollContainer hideScrollbars={false}>
            <table className={`charts__table charts__table--${fluidType === 'Water' ? 'blue' : 'green'}`}>
                <thead>
                    <tr>
                        <th>{t('status')}</th>
                        <th>{t('quantity')}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((point, index) => (
                        <tr key={index}>
                            <td>{point.label}</td>
                            <td>{point.value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </ScrollContainer>
    )
}

class Statistics extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            layouts: {
                lg: DEFAULT_LAYOUT_LG,
                md: DEFAULT_LAYOUT_LG,
                sm: DEFAULT_LAYOUT_SM,
                xs: DEFAULT_LAYOUT_SM,
                xxs: DEFAULT_LAYOUT_SM
            },
            breakpoint: "lg",
            currentLayout: []
        }
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.checkIsConnectedPWA()) {
            this._isMounted && this.getOwnLayout();
        }
    }

    async getOwnLayout() {
        let url = "/dashboard/layout";
        let method = "GET";
        let data = await this.request(url, method);
        if (data && data.status === "ok")
            this._isMounted && this.setState({ layouts: data.layout || this.state.layouts });
    }

    async setOwnLayout(layouts) {
        let url = "/dashboard/layout";
        let method = "POST";
        let csrf = await this.getCsrfToken("/dashboard/csrf");
        let body = JSON.stringify({
            layout: layouts,
            _csrf_token: csrf
        });
        await this.request(url, method, body);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onBreakpointChange(newBreakpoint) {
        this.setState(prevState => ({
            layouts: {
                ...prevState.layouts,
                [this.state.breakpoint]: this.state.currentLayout
            },
            breakpoint: newBreakpoint
        }), () => { this.setOwnLayout(this.state.layouts); });
    }

    onLayoutChange(currentLayout) {
        this.setState({ currentLayout: currentLayout });
        let layouts = this.state.layouts;
        layouts[this.state.breakpoint] = currentLayout;
        this.setOwnLayout(layouts);
    }

    render() {
        const chartList = [
            {
                id: 1,
                title: "Etat des connecteurs d'electricité",
                content: <PieChart data={FAKE_DATAVIZ_DATA_1} />,
            },
            {
                id: 2,
                title: "Etat des connecteurs d'eau",
                content: <PieChart data={FAKE_DATAVIZ_DATA_2} />,
            },
            {
                id: 3,
                title: "Consommation électrique",
                content: <BarChart
                    keys={["Nb de distributions d'électricité", "Nb de distributions d'électricité à zero kW", "Nb de distributions d'électricité en cours"]}
                    indexBy="date"
                    data={FAKE_DATAVIZ_DATA_3}
                    colors={['hsl(109, 68%, 48%)', 'hsl(0, 0%, 0%)', 'hsl(0, 72%, 54%)']} />
            },
            {
                id: 4,
                title: "Consommation d'eau",
                content: <BarChart
                    keys={["Nb de distributions d'électricité", "Nb de distributions d'électricité à zero kW", "Nb de distributions d'électricité en cours"]}
                    indexBy="date"
                    data={FAKE_DATAVIZ_DATA_4}
                    colors={['hsl(204, 64%, 44%)', 'hsl(0, 0%, 0%)', 'hsl(0, 72%, 54%)']} />,
            },
            {
                id: 5,
                title: "Consommation électrique",
                content: <LineChart colors={['hsl(37, 90%, 56%)']} data={FAKE_DATAVIZ_DATA_5} />,
            },
            {
                id: 6,
                title: "Consommation d'eau",
                content: <LineChart colors={['hsl(190, 42%, 38%)']} data={FAKE_DATAVIZ_DATA_6} />,
            },
        ];

        return (
            <ResponsiveGridLayout className="layout" rowHeight={300}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 12, md: 12, sm: 6, xs: 6, xxs: 6 }}
                onLayoutChange={(currentLayout) => this.onLayoutChange(currentLayout)}
                onBreakpointChange={(newBreakpoint) => this.onBreakpointChange(newBreakpoint)}
                layouts={this.state.layouts}
            >
                {
                    chartList.map((element, index) => (
                        <div key={index} data-grid={{ x: ((index % 2) * 6), y: (index % 2), w: 6, h: 1 }} className="card">
                            <h2 className="chartTitle">{element.title}</h2>
                            <div className="chart">
                                {element.content}
                            </div>
                        </div>
                    ))
                }
            </ResponsiveGridLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(Statistics)), Statistics));
