import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { Button, Modal } from 'react-bootstrap';
import { withToast } from '../../App';
import Ticket from './ticket';
import { DefaultLayout } from 'components/Layouts';


class DeleteTicket extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            show: true
        }
    }

    componentDidMount() {
        this.checkIsConnectedPWA();
    }

    async handleDelete() {
        const { t } = this.props;
        let id = 0;
        if (this.props.match.params.id) {
            id = this.props.match.params.id;
            await this.deleteTicket(id);
            this.setState({ show: false });
            this.props.addToast(t('ticketDeleted'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
        }
        this.props.history.push("/ticket");
    }

    handleClose() {
        this.setState({ show: false });
        this.props.history.push("/ticket");
    }

    async deleteTicket(id) {
        let url = "/ticket/" + id;
        let method = "DELETE";
        let csrf = await this.getCsrfToken("/ticket/csrf");
        let body = JSON.stringify({
            _csrf_token: csrf
        });
        let data = await this.request(url, method, body);
        if (data && data.status === "ok") {
            return true;
        }
        return false;
    }

    render() {
        const { t } = this.props;
        return (
            <DefaultLayout>
                <Modal show={this.state.show} onHide={() => this.handleClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('deleteTicketTitle')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{t('deleteTicketBody')}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={() => this.handleDelete()}>
                            {t('deleteTicketValidate')}
                        </Button>
                        <Button variant="danger" onClick={() => this.handleClose()}>
                            {t('deleteTicketClose')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Ticket />
            </DefaultLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(DeleteTicket)), DeleteTicket));