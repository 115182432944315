import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import { DefaultLayout } from '../../components/Layouts';
import update_notes from '../../update_note.json';

class UpdateNote extends CustomComponent {
    render () {
        const {t} = this.props

        return (
            <DefaultLayout>
                <h1 className="main-title">{t('updates_note')}</h1>
                {update_notes.slice().reverse().map((note, index) => (
                    <section key={index} className="patch-note">
                        <h2 className="patch-note__title h3">
                            {index === 0 && (<span className="patch-note__badge">{t('new')}</span>)}
                            <div>
                                <i className="vnf-icons-icon-m-information-solid"></i>
                                <span>{t('version')} {note.version}</span>
                                <span> - {t(note.type)}</span>
                                {!!note.date && <span> ({note.date})</span>}
                            </div>
                        </h2>
                        {!!note.intro && <h4>{note.intro}</h4>}
                        {!!note.tasks.fixes?.length && (
                            <>
                                <h4 className="m-0">{t('fixes')}</h4>
                                <ul className="unstyled ml-2">
                                    {note.tasks.fixes.map((task, index) => (
                                        <li key={index}>{task}</li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {!!note.tasks.features?.length && (
                            <>
                                <h4 className="m-0">{t('features')}</h4>
                                <ul className="unstyled  ml-2">
                                    {note.tasks.features.map((task, index) => (
                                        <li key={index}>{task}</li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </section>
                ))}
            </DefaultLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(UpdateNote)), UpdateNote));