import CustomComponent from 'components/customComponent';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import { FormInput, PhoneInput } from 'components/FormComponents';
import { RegisterLayout } from '../../components/Layouts';
import { REGEX, validateName, validateEmail } from '../../helpers/validation';

class Register extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            register: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
            },
            formIsValid: false
        }
    }

    componentDidMount() {
        this.checkIsConnectedPWA();
        //Load localstorage value
        this.loadStorageValues(['register'], [this.state.register], this.checkFormValidity);
    }

    checkFormValidity = () => {
        this.setState({formIsValid: (
            new RegExp(REGEX.name).test(this.state.register.firstName) &&
            new RegExp(REGEX.name).test(this.state.register.lastName) &&
            new RegExp(REGEX.email).test(this.state.register.email)
        )})
    }

    async afterSubmissionStepOne(event) {
        const { t } = this.props;
        event.preventDefault();
        let data = await this.registerStep1();
        if (data && data.status === "ok") {
            localStorage.setItem('register', JSON.stringify(this.state.register));
            this.props.addToast(t('First step of registration successful'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
            this.props.history.push("/register/step2");
        }
    }

    async registerStep1() {
        let url = "/register/step1";
        let method = "POST";
        let csrf = await this.getCsrfToken("/register/csrf");
        let body = JSON.stringify({
            firstName: this.state.register.firstName,
            lastName: this.state.register.lastName,
            email: this.state.register.email,
            phone: this.state.register.phone,
            _csrf_token: csrf
        });
        let data = await this.request(url, method, body);
        return data;
    }

    render() {
        const { t } = this.props;
        return (
            <RegisterLayout step={1}>
                <div className="register__form primary-form">
                    <form onSubmit={(e) => this.afterSubmissionStepOne(e)}>
                        <hr className="form-divider form-divider--big"/>
                        <div className="row">
                            <div className="col-md-6">
                                <FormInput
                                    required
                                    label={t("Your first name")}
                                    type="text"
                                    name="firstName" id="firstName"
                                    handle={(e) => this.handleInputChange(e, null, 'register', this.checkFormValidity)}
                                    validation= {validateName}
                                    value={this.state.register.firstName} />
                            </div>
                            <div className="col-md-6">
                                <FormInput
                                    required
                                    label={t("Your last name")}
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    handle={(e) => this.handleInputChange(e, null, 'register', this.checkFormValidity)}
                                    validation= {validateName}
                                    value={this.state.register.lastName} />
                            </div>
                            <div className="col-md-6">
                                <PhoneInput
                                    label={t("Phone number")}
                                    name="phone"
                                    id="phone"
                                    value={this.state.register.phone}
                                    handle={phone => this.setState(prevState => ({ register: { ...prevState.register, phone: phone } }))}/>
                            </div>
                            <div className="col-md-6">
                                <FormInput
                                    required
                                    label={t("Your email address")}
                                    type="email"
                                    name="email"
                                    id="email" handle={(e) => this.handleInputChange(e, null, 'register', this.checkFormValidity)}
                                    validation= {validateEmail}
                                    value={this.state.register.email} />
                            </div>
                        </div>
                        <hr className="form-divider form-divider--big"/>
                        <div className="primary-form__buttons">
                            <Link to="/" className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-bleu-a3">{t('go_back')}</Link>
                            <button type="submit" disabled={!this.state.formIsValid} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('Next')}</button>
                        </div>
                        <p className="primary-form__required-fields">* {t('required_fields')}</p>
                    </form>
                </div>
            </RegisterLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(Register)), Register));