import CustomComponent from 'components/customComponent';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import LoadingOverlay from 'react-loading-overlay';
import {FormInput, FormCheckBox, FormSubmit} from 'components/FormComponents';
import { LoginLayout } from 'components/Layouts';
import { REGEX } from '../../helpers/validation';

class Login extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            login: {
                email: "",
                password: "",
                remember_me: true,
            },
            formIsValid: false,
            isLoading: false
        }
        this._isMounted = false;
    }

    componentDidMount() {
        this.checkIsConnectedPWA();
        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    checkFormValidity = () => {
        this.setState({formIsValid: (
            new RegExp(REGEX.email).test(this.state.login.email) &&
            this.state.login.password !== ""
        )})
    }

    async login(csrf_token) {
        const { t } = this.props;
        let url = "/login";
        let method = "POST";
        let body = JSON.stringify({ email: this.state.login.email, password: this.state.login.password, _remember_me: this.state.login.remember_me, _csrf_token: csrf_token });
        let data = await this.request(url, method, body);

        if (data) {
            if (data.status === 'ok') {
                let isServiceWorkerUpdated = localStorage.getItem('isServiceWorkerUpdated')? true : false;
                localStorage.clear(); // clear localStorage to delete old session data
                if (isServiceWorkerUpdated)
                    localStorage.setItem('isServiceWorkerUpdated', 'true');
                localStorage.setItem('accountRoles', JSON.stringify(data.roles)); // save account roles
                localStorage.setItem('_remember_me', JSON.stringify(this.state.login.remember_me));
                this.props.onRolesLoad(data.roles); // send roles to navigation
                await this.getAccount(true);
                this.props.addToast(t('Connection successful'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
                if (data.passwordNeedsChange) {
                    localStorage.setItem('passwordNeedsChange', true);
                    this.props.history.push("/password/edit");
                }
                else if (data.cguNeedValidation) {
                    localStorage.setItem('cguNeedValidation', true);
                    this.props.history.push("/");
                }
                else {
                    this.props.history.push("/");
                }
            }
        }
    }

    async afterSubmission(event) {
        this._isMounted && this.setState({isLoading: true})
        event.preventDefault();
        let csrf = await this.getCsrfToken("/login/csrf");
        await this.login(csrf);
        this._isMounted && this.setState({isLoading: false})
    }

    render() {
        const { t } = this.props;
        return (
            <LoginLayout>
                <LoadingOverlay active={this.state.isLoading} classNamePrefix="loader_" spinner text={t('loading')}></LoadingOverlay>
                <div className="login__form secondary-form">
                    <h1 className="secondary-form__title h2">{t("Identify")}</h1>
                    <form onSubmit={(e) => this.afterSubmission(e)}>
                        <div className="row">
                            <div className="col-12">
                                <FormInput
                                    label={t("Email")}
                                    type="email"
                                    name="email"
                                    id="email"
                                    appearance="secondary"
                                    handle={(e) => this.handleInputChange(e, null, 'login', () => this.checkFormValidity())}
                                    value={this.state.login.email}/>
                            </div>
                            <div className="col-12">
                                <FormInput
                                    label={t("Password")}
                                    type="password"
                                    name="password"
                                    id="password"
                                    appearance="secondary"
                                    handle={(e) => this.handleInputChange(e, null, 'login', () => this.checkFormValidity())}
                                    value={this.state.login.password}/>
                            </div>
                        </div>
                        <FormCheckBox
                            label={t("remember_me")}
                            name="remember_me"
                            id="remember_me"
                            appearance="secondary"
                            handle={(e) => this.handleInputChange(e, null, 'login')}
                            value={this.state.login.remember_me}/>
                        <FormSubmit label={t("Login")} disabled={this.state.isLoading || !this.state.formIsValid}/>
                    </form>
                    <div className="links">
                        <Link to="/password/reset">{t("Forgot your password ?")}</Link>
                        <Link to="/register">{t("New ? Create an account")}</Link>
                    </div>
                </div>
            </LoginLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(Login)), Login));