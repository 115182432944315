import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import { DefaultLayout } from 'components/Layouts';
import { FormInput } from 'components/FormComponents';

class AddTicket extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            addticket: {
                terminal_id: "",
                reason: "",
                message: ""
            },
            formIsValid: false
        }
    }

    componentDidMount() {
        if (this.checkIsConnectedPWA()) {
            //Load localstorage value
            this.loadStorageValues(['addticket'], [this.state.addticket]);
        }
    }

    checkFormValidity = () => {
        this.setState({formIsValid: (
            this.state.addticket.reason.trim() &&
            this.state.addticket.message.trim()
        )})
    }

    async afterSubmission(event) {
        const { t } = this.props;
        event.preventDefault();
        let url = "/ticket/new";
        let method = "POST";
        let csrf = await this.getCsrfToken("/ticket/csrf");
        let body = JSON.stringify({
            reason: this.state.addticket.reason,
            message: this.state.addticket.message,
            _csrf_token: csrf
        });

        let data = await this.request(url, method, body);
        if (data && data.status === "ok") {
            this.props.addToast(t('ticketAdded'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
            this.props.history.push('/ticket');
        }
    }

    render() {
        const { t } = this.props;
        return (
            <DefaultLayout>
                <h1 className="main-title">{t('tickets')}</h1>
                <div className="edit__form primary-form">
                    <h2 className="primary-form__title">{t('add_ticket')}</h2>
                    <p className="primary-form__description">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, doloribus.</p>
                    <hr className="form-divider form-divider--big"/>
                    <form onSubmit={(e) => this.afterSubmission(e)}>
                        <div className="row">
                            <div className="col-sm-12">
                                <FormInput
                                    label={t("ticket_reason")}
                                    type="text"
                                    name="reason"
                                    id="reason"
                                    handle={(e) => this.handleInputChange(e, null, 'addticket', this.checkFormValidity)}
                                    value={this.state.addticket.reason}/>
                            </div>
                            <div className="col-sm-12">
                                <FormInput
                                    label={t("ticket_message")}
                                    type="text"
                                    name="message"
                                    id="message"
                                    handle={(e) => this.handleInputChange(e, null, 'addticket', this.checkFormValidity)}
                                    value={this.state.addticket.message}/>
                            </div>
                        </div>
                        
                        <hr className="form-divider form-divider--big"/>
                        <div className="primary-form__buttons">
                            <button type="button" onClick={() => this.props.history.goBack()} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-bleu-a3">{t('cancel')}</button>
                            <button type="submit" disabled={!this.state.formIsValid} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('Save')}</button>
                        </div>
                    </form>
                    </div>
            </DefaultLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(AddTicket)), AddTicket));
