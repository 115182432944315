import Modal from "components/Modal"
import CustomComponent from 'components/customComponent';
import { Link } from "react-router-dom"
import { FormSwitch, FormCheckBox, FormInput } from "components/FormComponents"
import { validateNumber } from '../../helpers/validation';
import SlideDown from "react-slidedown";
import hoistStatics from 'hoist-non-react-statics';
import { withRouter } from 'react-router-dom';
import { withTranslation, useTranslation } from 'react-i18next';
import { withToast } from '../../App';
import LoadingOverlay from "react-loading-overlay";
import { useState } from 'react';

class TerminalModal extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            eni: '',
            code: '',
            display: "LIST", // LIST, DETAILS, CONNECTOR, CHARGE
            terminal: {},
            connector: null,
            count: {},
            isLoading: false
        }
        this._isMounted = false
        this._interval = null
    }

    componentDidMount() {
        this._isMounted = true
        this.getTerminal()
    }

    componentWillUnmount() {
        this._isMounted = false
        if (this._interval) clearInterval(this._interval)
    }

    getTerminal = async () => {
        let url = "/terminal/" + this.props.terminal.id;
        const method = "GET";
        let data = await this.request(url, method);

        if(this._isMounted && data && data.status === 'ok' && data.terminal) {
            this.setState({
                count: data.count,
                terminal: data.terminal,
                isLoading: false
            })
            return data.terminal
        }

        this.setState({isLoading: false})
        return null
    }

    afterTransactionSuccess = async () => {
        this.setState({display: 'CHARGE'})
        const {id, status} = this.state.connector

        if (this._interval) clearInterval(this._interval)
        this._interval = setInterval(async () => {

            const terminal = await this.getTerminal()
            if (terminal) {
                const connector = terminal.connectors.find(c => c.id === id)

                // If connector status has changed
                if (connector && connector.status !== status && connector.status !== 'Preparing') {
                    this.onChargeMessageClose()
                }
            }
        }, 2500)
    }

    onChargeMessageClose = () => {
        if (this._interval) clearInterval(this._interval)
        this.setState({display: 'LIST'})
    }

    onToggle = (e, connector) => {
        e.preventDefault()
        this.setState({
            display: 'CONNECTOR',
            connector: connector
        })
    }

    afterSubmission = async (e) => {
        e.preventDefault()

        this.afterTransactionSuccess()

        const {t} = this.props
        const {status, id} = this.state.connector

        let url = `/terminal/charge/${status === 'Charging' ? 'stop' : 'start'}/${id}`;
        let csrf = await this.getCsrfToken("/terminal/csrf");
        const method = "POST";
        let body = JSON.stringify({
            _csrf_token: csrf,
            boat_eni: this.state.eni,
            boat_accesscode: this.state.code,
        });

        let data = await this.request(url, method, body);

        if(data && data.status === 'ok') {
            const message = status !== "Charging" ? 'transaction_start_success' : 'transaction_stop_success'
            this.props.addToast(t(message), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
            this.afterTransactionSuccess()
        }
    }

    onReportUnvailable = async (e, connector) => {
        e.preventDefault()

        const {status, id} = connector
        const {t} = this.props

        let url = `/terminal/${status === 'Unavailable' ? 'enable' : 'disable'}/${id}`;
        let csrf = await this.getCsrfToken("/terminal/csrf");
        const method = "POST";
        let body = JSON.stringify({
            _csrf_token: csrf,
        });
        let data = await this.request(url, method, body);
        if(data && data.status === 'ok') {
            let message = status === 'Unavailable' ? 'report_available_success' : 'report_unavailable_success'
            this.props.addToast(t(message), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
        }
    }

    render() {
        const {terminal, onClose, t} = this.props;
        terminal.price_group = 'Borne&Eau';

        // const connectors = terminal.connectors
        const connectors = terminal.connectors.sort((a,b) => a.position - b.position)

        return (
            <LoadingOverlay active={this.state.isLoading} classNamePrefix="loader_" spinner text={t('loading')}>
                <Modal size="lg" onClose={onClose} open={true} title={terminal.cpo_terminal_id}>
                    <p className="modal__description">
                        {t('place')} : {terminal.address} {terminal.city} <br />
                        {t('type')} : {terminal.model}
                    </p>
                    {this.state.display === 'DETAILS' && (<>
                        <div className="terminal-details terminal-details--top">
                            <h2 className="terminal-details__title">{t('transaction_count')}</h2>
                            <ul className="terminal-details__transactions">
                                <li><span>{t('total')}</span>{this.state.count['total']}</li>
                                <li><span>{t('this_week')}</span>{this.state.count['week']}</li>
                                <li><span>{t('today')}</span>{this.state.count['day']}</li>
                            </ul>
                        </div>
                        <div className="terminal-details terminal-details--bottom">
                            <h2 className="terminal-details__title">{t('terminal_details')}</h2>
                            <ul className="terminal-details__info">
                                <li>GPS : LAT: {terminal.gpsLat} | LNG {terminal.gpsLon}</li>
                                <li>{t('price_group')} : {terminal.price_group}</li>
                            </ul>
                        </div>

                        <div className="modal__buttons modal__buttons--right w-100">
                            <button type="button" onClick={() => this.setState({display: 'LIST'})} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-multi-color vnf-btn-vert-b4 vnf-btn-box-shadow-vert-b1">Retour</button>
                        </div>
                    </>)}
                    {this.state.display === 'LIST' && (<>
                        <ul className="connector-list" style={{gridTemplateRows: `repeat(${Math.ceil(connectors.length / 2)}, 1fr)`}}>
                            {connectors.map((connector, index) => <Connector index={index + 1} key={connector.id} connector={connector} offline={!terminal.active} onToggle={this.onToggle} onReportUnvailable={this.onReportUnvailable}/>)}
                        </ul>
                        <div className="modal__buttons modal__buttons--right w-100">
                            <button type="button" onClick={() => this.setState({display: 'DETAILS'})} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-multi-color vnf-btn-vert-b4 vnf-btn-box-shadow-vert-b1">Détails</button>
                            <Link to={`/transaction/terminal/${terminal.cpoTerminalId}`} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-multi-color vnf-btn-vert-b4 vnf-btn-box-shadow-vert-b1">Transactions</Link>
                        </div>
                    </>)}
                    {this.state.display === 'CONNECTOR' && (<>
                        <div className="primary-form">
                            <h2 className="primary-form__title h2">{this.state.connector.status !== "Charging" ? t('start_a_distribution') : t('stop_a_distribution')}</h2>
                            <p className="primary-form__description">{t('connector')} : {t(this.state.connector.fluidType)}-{this.state.connector.position}</p>   
                            <form onSubmit={(e) => this.afterSubmission(e)}>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <FormInput
                                            label={t("eni")}
                                            type="text"
                                            name="eni"
                                            id="eni"
                                            validation={validateNumber}
                                            handle={(e) => this.setState({eni: e.target.value})}
                                            value={this.state.eni}/>
                                    </div>
                                    {this.state.connector.status !== "Charging" && (
                                        <div className="col-sm-12">
                                            <FormInput
                                                label={t("code")}
                                                type="text"
                                                name="code"
                                                id="code"
                                                validation={validateNumber}
                                                handle={(e) => this.setState({code: e.target.value})}
                                                value={this.state.code}/>
                                        </div>
                                    )}
                                </div>
                                <hr className="form-divider form-divider--big"/>
                                <div className="primary-form__buttons">
                                    <button type="button" onClick={() => this.setState({display: 'LIST'})} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-bleu-a3">{t('cancel')}</button>
                                    <button type="submit" className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('Save')}</button>
                                </div>
                          </form>
                        </div>
                    </>)}
                    {this.state.display === "CHARGE" && (<>
                        <div className="primary-form">
                            <h2 className="primary-form__title h2">{this.state.connector.status !== "Charging" ? t('start_a_distribution') : t('stop_a_distribution')}</h2>
                            <p className="primary-form__description">{t('connector')} : {t(this.state.connector.fluidType)}-{this.state.connector.position}</p>   
                            <p className="h1 terminal__please-wait">{t('proceed_with_disconnection')}</p>
                            <hr className="form-divider form-divider--big"/>
                            <div className="primary-form__buttons primary-form__buttons--center ">
                                <button type="button" onClick={() => this.onChargeMessageClose()} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-bleu-a3">{t('close')}</button>
                            </div>
                        </div>
                    </>)}
                </Modal>
            </LoadingOverlay>
        )
    }
}


const Connector = ({index, connector, offline, onToggle, onReportUnvailable}) => {
    const [open, setOpen] = useState(false);
    const {t} = useTranslation();

    let canToggleSwitch =  ["Available", "Preparing"].includes(connector.status);

    const running_transactions = connector.transactions.filter(transaction => ["Charging", "Pending"].includes(transaction.status));

    const account = JSON.parse(localStorage.getItem('account'));
    const role = account.roles.filter(role => role !== 'ROLE_USER')[0] || [];

    if (!canToggleSwitch && running_transactions.length > 0) {
        const id = account.id;
        const {boat_owner_id, boat_armateur_id} = running_transactions.pop();
        canToggleSwitch = ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_TECHNICIEN'].includes(role) || boat_owner_id === id || boat_armateur_id === id;
    }

    const displayAsUnavailable = () => {
        if (['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_TECHNICIEN'].includes(role)) return false
        return connector.disabled || offline
    }

    return (<>
        <li className="connector-list__item">
            <div className="connector-list__header">
                <h4 className="connector-list__title">
                    {index} - {t(connector.fluidType)}
                    {/* {t(connector.fluidType)}-{connector.position} */}
                    <span className="connector-list__type">{connector.capacity}</span>
                </h4>
                {offline ? (
                    <span className={`connector-list__status connector-list__status--Unavailable`}>{t('Unavailable')}</span>
                ) : (
                    <span className={`connector-list__status connector-list__status--${connector.status}`}>{t(connector.status)}</span>
                )}
                <button type="button" className={`connector-list__toggle${open ? ' open' : ''}`} onClick={() => setOpen(!open)}>
                    <span className="sr-only">{open ? t('close') : t('open')}</span>
                    <i className={`vnf-icons-icon-m-fleche-a-${open ? 'haut' : 'bas'}-outline`}></i>
                </button>
            </div>
            <SlideDown closed={!open} className="connector-list__slidedown">
                <div className="connector-list__body">
                    <div className="connector-list__left">
                        {(!canToggleSwitch || offline) ? (t('unavailable_charge')) : (
                            <FormSwitch
                                id={'connector_on_off-' + connector.id}
                                label={t("Arrêter ou démarrer la distribution")}
                                value={connector.status === "Charging"}
                                handle={(e) => onToggle(e, connector)}
                                appearance="secondary"
                                labelOnOff={[t('Arrêter'), t('Démarrer')]}/>
                        )}
                    </div>
                    <div className="connector-list__right">
                        {/* /*eslint no-mixed-operators: ["error", {"groups": [["&&", "||", "?:"]]}]* */}
                        {(displayAsUnavailable()) ? (t('unavailable')) : 
                            (['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_TECHNICIEN'].includes(role) ) ?
                                <FormCheckBox
                                id={'connector_unavailable-' + connector.id}
                                value={connector.status === "Unavailable"}
                                handle={(e) => onReportUnvailable(e, connector)}
                                appearance="secondary"
                                label={t('report_unavailable')}/>
                                :
                                <></>
                        }
                    </div>
                </div>
            </SlideDown>
        </li>
    </>)
}


export default withToast(hoistStatics(withTranslation()(withRouter(TerminalModal)), TerminalModal));