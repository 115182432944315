import { useTranslation } from 'react-i18next';

const Pagination = (props) => {
    const {handleClick, pageCurrent, pageTotal, pageDisplay = 5} = props
    const {t} = useTranslation()

    let items = Array.from({length: pageTotal}, (_, i) => i + 1)
    let pageStart = Math.max(parseInt(pageCurrent) - Math.floor(pageDisplay / 2), 1)
    items = items.splice(pageStart -1, pageDisplay)

    return (
        <ul className="pagination">
            <li><button onClick={handleClick} disabled={pageCurrent < 2} className={`pagination__link`} value={parseInt(pageCurrent) - 1}><span className="sr-only">{t('go_to_previous_page')}</span><i className="vnf-icons-icon-fleche-a-gauche-outline"></i></button></li>
            {items.map(item => (
                <li key={item}><button onClick={handleClick} className={`pagination__link ${item === parseInt(pageCurrent) ? 'active': ''}`} value={item}><span className="sr-only">{t('go_to_page')} </span>{item}</button></li>
            ))}
            <li><button onClick={handleClick} disabled={pageCurrent >= pageTotal}  className={`pagination__link`} value={parseInt(pageCurrent) + 1}><span className="sr-only">{t('go_to_next_page')}</span><i className="vnf-icons-icon-m-fleche-a-droite-outline"></i></button></li>
        </ul>
    )
}

export default Pagination