import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import CustomComponent from 'components/customComponent';
import LoadingOverlay from 'react-loading-overlay';
import { DefaultLayout } from 'components/Layouts';
import { FormInput, FormSelect, FormAutoComplete } from 'components/FormComponents';

class StartDistribution extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            distribution: {
                terminal: '',
                connector: '',
                access_code: ''
            },
            formIsValid: false,
            terminal: '',
            terminals: [],
            connectors: [],
            terminalSuggestions: [],
            isLoading: false
        }
        this._isMounted = false
        this.timeout = null
    }

    componentDidMount() {
        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false
        if (this.timeout) clearTimeout(this.timeout)
    }

    checkFormValidity () {
        const isValid = (
            this.state.distribution.terminal &&
            this.state.distribution.connector &&
            this.state.distribution.access_code
        );
        this.setState({formIsValid: isValid});
    }

    updateConnectors() {
        if (this.state.terminals) {
            const selected_terminal = this.state.terminals.find(t => parseInt(t.id) === parseInt(this.state.distribution.terminal))
            this.setState(prevState => ({
                distribution: {
                    ...prevState.distribution,
                    connector: selected_terminal ? selected_terminal.connectors[0].id : ''
                },
                connectors: selected_terminal ? selected_terminal.connectors : []
            }), () => this.checkFormValidity())
        } else {
            this.checkFormValidity()
        }
    }

    async onSubmit(e) {
        const { t } = this.props;
        e.preventDefault();
        let url = `/terminal/charge/start/${this.state.distribution.connector}`;
        let method = 'POST';
        let csrf = await this.getCsrfToken("/terminal/csrf");
        let body = JSON.stringify({
            boat_eni: this.state.distribution.access_code,
            boat_accesscode: '',
            _csrf_token: csrf
        });
        this.setState({isLoading: true})
        let data = await this.request(url, method, body)
        if (data && data.status === "ok") {
            this.props.addToast(t('Charging started successfully'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
        }
        this.setState({isLoading: false})
    }

    handleTerminalChange = (e) => {
        const {value} = e.target
        const {id} = e.target.dataset
        this._isMounted && this.setState(prevState => ({
            terminal: value,
            distribution: {
                ...prevState.distribution,
                terminal: id
            }
        }), this.updateConnectors)
    }

    handleTerminalSuggestions = async (e) => {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(async () => {
            const {value} = e.target
            let url = `/terminal/?q=${value}`;
            const method = "GET";

            let data = await this.request(url, method);

            if (data && data.terminals) {
                const filteredTerminals = data.terminals.filter(t => t.connectors.length > 0)

                this._isMounted && this.setState({
                    terminals: filteredTerminals,
                    terminalSuggestions: filteredTerminals.map(t => ({id: t.id, name: t.cpoTerminalId}))
                }, this.updateConnectors)
            }
        }, 200)
    }

    render () {
        const {t} = this.props

        return (
            <DefaultLayout>
                <LoadingOverlay active={this.state.isLoading} classNamePrefix="loader_" spinner text={t('loading')} />
                <h1 className="main-title">{t('remote_operations')}</h1>
                <div className="primary-form">
                    <form onSubmit={(e) => this.onSubmit(e)}>
                        <h2 className="primary-form__title">{t('start_a_distribution')}</h2>
                        <hr className="form-divider form-divider--big" />
                        <div className="row">
                            <div className="col-12">
                                <FormAutoComplete
                                    required
                                    label={t('terminal')}
                                    placeholder={t('filter_terminal_placeholder')}
                                    id="terminal"
                                    name="terminal"
                                    value={this.state.terminal}
                                    onFieldChange={this.handleTerminalSuggestions}
                                    handle={(e) => this.handleTerminalChange(e)}
                                    suggestions={this.state.terminalSuggestions}/>
                                {/* <FormSelect
                                    required
                                    id="terminal"
                                    name="terminal"
                                    label={t('terminal')}
                                    value={this.state.distribution.terminal}
                                    handle={(e) => this.handleInputChange(e, null, 'distribution', () => this.updateConnectors())}
                                    options={this.state.terminals.map(t => ({value: t.id, name: t.cpoTerminalId}))}/> */}
                            </div>
                            <div className="col-12">
                                <FormSelect
                                    required
                                    id="connector"
                                    name="connector"
                                    label={t('connector')}
                                    value={this.state.distribution.connector}
                                    handle={(e) => this.handleInputChange(e, null, 'distribution', () => this.checkFormValidity())}
                                    options={this.state.connectors.map((c, index) => ({value: c.id, name: `${index + 1} - ${t(c.fluidType)} - ${c.capacity}`}))}/>
                            </div>
                            <div className="col-12">
                                <FormInput
                                    required
                                    id="access_code"
                                    name="access_code"
                                    label={t('eni')}
                                    value={this.state.distribution.access_code}
                                    handle={(e) => this.handleInputChange(e, null, 'distribution', () => this.checkFormValidity())}/>
                            </div>
                        </div>
                        <hr className="form-divider form-divider--big" />
                        <div className="primary-form__buttons primary-form__buttons--right">
                            <button type="submit" disabled={!this.state.formIsValid} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('send')}</button>
                        </div>
                        <p className="primary-form__required-fields">* {t('required_fields')}</p>
                    </form>
                </div>
            </DefaultLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(StartDistribution)), StartDistribution));