import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import { FormCheckBox } from 'components/FormComponents';
import { DefaultLayout } from '../../components/Layouts';

class AcceptCGU extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            register_step5: {
                accept_cgu: false
            },
            formIsValid: false
        }
    }

    checkFormValidity = () => {
        this.setState({formIsValid: this.state.register_step5.accept_cgu === true})
    }

    componentDidMount() {
        this.checkIsConnectedPWA();
        if (!localStorage.getItem('cguNeedValidation')) {
            this.props.history.push('/')
        }
    }

    async afterSubmissionStep5(event) {
        event.preventDefault();

        let url = '/user/acceptCGU'
        let csrf = await this.getCsrfToken("/user/csrf");
        let body = JSON.stringify({
            _csrf_token: csrf
        })

        let response = await this.request(url, 'POST', body)

        if (response && response.status === "ok") {
            localStorage.removeItem('cguNeedValidation')
            this.props.history.push('/')
        }
    }

    render() {
        const { t } = this.props;
        return (
            <DefaultLayout>
                <h1 className="main-title">{t('cgu')}</h1>
                <div className='row'>
                    <div className='col-lg-8 offset-lg-2'>
                        <div className="register__form primary-form">
                            <form onSubmit={(e) => this.afterSubmissionStep5(e)}>
                                <hr className="form-divider"/>
                                <div className="checkbox-container">
                                    <FormCheckBox 
                                        label={t('HTML__accept_cgu')}
                                        name="accept_cgu"
                                        id="accept_cgu"
                                        required
                                        handle={(e) => this.handleInputChange(e, null, 'register_step5', this.checkFormValidity)}
                                        value={this.state.register_step5.accept_cgu} />
                                </div>
                                <div className="primary-form__buttons">
                                    <button type="submit" disabled={!this.state.formIsValid} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('send')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </DefaultLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(AcceptCGU)), AcceptCGU));