import { useState, useEffect } from "react";
import { withTranslation } from 'react-i18next';
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const CustomToast = ({...props}) => {
  const {t, appearance, message, date, autoDismiss, autoDismissTimeout, onDismiss, size = 'sm', children} = props

  const [close, setClose] = useState(false)

  const iconClasses = {
    "danger": "vnf-icons-icon-s-erreur-solid",
    "info": "vnf-icons-icon-s-information-solid",
    "success": "vnf-icons-icon-s-succes-solid",
    "warning": "vnf-icons-icon-s-attention-solid"
  }

  const onClick = () => {
    if (close) return
    setClose(true)
    setTimeout(onDismiss, 250)
  }

  useEffect(() => {
    if (autoDismiss) {
      setTimeout(onDismiss, autoDismissTimeout || 4000)
    }
  });

  return (
    <div className={`vnf-toast vnf-toast--${appearance} vnf-toast--${size} ${close ? 'close' : ''}`}>
      <div className='vnf-toast__header'>
        <i className={`vnf-toast__icon ${iconClasses[appearance] || ''}`}></i>
        <p className={`vnf-toast__title vnf-toast__title--${size}`}>{children}</p>
        <button className="vnf-toast__close" onClick={onClick}>
          <i className="vnf-icons-icon-m-croix-fermer-outline"></i>
          <span className="sr-only">{t("Close notification")}</span>
          {autoDismiss && (
            <div className="vnf-toast__progress" style={{animationDuration: `${autoDismissTimeout || 4000}ms`}}>
              <CircularProgressbar strokeWidth={10} value={100}/>
            </div>
          )}
        </button>
      </div>
      {(date || message) && (
        <div className="vnf-toast__content">
          {date && <p className="vnf-toast__date">{date}</p>}
          {message && <p className="vnf-toast__message">{message}</p>}
        </div>
      )}
    </div>
  )
}

export default withTranslation()(CustomToast)
