import CustomComponent from './customComponent';
import { withRouter, NavLink, useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { withTranslation, useTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../App';
import { useState, useEffect } from 'react';
import logoVNF from '../assets/img/logo-vnf-monochrome.svg';
import logoVNFBleu from '../assets/img/logo-vnf-bleu.svg';
import logoHaropa from '../assets/img/logo-haropa.svg';
import logoHaropaBleu from '../assets/img/logo-haropa-bleu.svg';
import logoBorneEtEaux from '../assets/img/logo-borne_et_eaux.png';
import LanguageSelector from 'components/LanguageSelector';
import { matchRoles } from 'helpers/helpers';
import { MENU_DATA } from '../config';
import TourManager from './TourManager';

class Navigation extends CustomComponent {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            roles: [],
            // menu_data: []
        }
    }

    componentDidMount () {
        this.setState({
            roles: this.loadRoles()
        })
    }

    handleOnClick = () => {this.setState({open : !this.state.open})}

    logout = async () => {
        const { t } = this.props;
        let url = "/logout";
        let method = "GET";
        await this.request(url, method); // disconnect request
        this.props.addToast(t('Logout successful'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
        localStorage.clear();
        this.props.history.push("/login");
    }

    render() {
        let account = localStorage.getItem('account');
        return account !== null ? <NavigationMenu data={MENU_DATA} roles={this.state.roles} logout={this.logout}/> : <></>
    }
}

/**
 * MAIN COMPONENT FOR NAVIGATION MENU ( HEADER AND MAIN-NAVIGATION )
 * INCLUDES NavigationItem COMPONENT
 */
const NavigationMenu = (props) => {
    const {data, logout, roles} = props
    const [open, setOpen] = useState(false)
    const [displayShortCuts, setDisplayShortCuts] = useState(false)
    const [activeItems, setActiveItems] = useState([])
    const { t } = useTranslation()
    const location = useLocation()
    const {pathname} = location

    const handleClick = (tree) => {
      setActiveItems(tree)
    }

    const toggleMenu = () => {
        setOpen(!open)
    }

    useEffect (() => {setOpen(false)}, [location])
    useEffect (() => {if (!open) setActiveItems([])}, [open])

    return (<>
        <header className="header">
            <a className="skipLink" href="#main-content">{t('skip_to_main')}</a>
            <nav>
                <ul>
                    <li>
                        <NavLink to="/" exact>
                            <i className={`vnf-icons-icon-m-maison-${pathname === '/' ? 'solid' : 'outline'}`}></i>
                            <span className="sr-only-desktop">{t('home')}</span>
                        </NavLink>
                    </li>
                    <li>
                        <button onClick={toggleMenu} className={open ? 'active': ''}>
                            <i className="vnf-icons-icon-m-menu-b-outline"></i>
                            <span className="sr-only-desktop">{t('menu')}</span>
                        </button>
                    </li>
                    <li className="visible-mobile">
                        <button onClick={() => {setDisplayShortCuts(!displayShortCuts)}} className={displayShortCuts ? 'active': ''}>
                            <i className="vnf-icons-icon-m-liens-outline"></i>
                            <span className="sr-only-desktop">{t('shortcuts')}</span>
                        </button>
                    </li>
                    <li>
                        <NavLink to="/account" exact className="tour--account">
                            <i className={`vnf-icons-icon-m-profil-${pathname === '/account' ? 'solid' : 'outline'}`}></i>
                            <span className="sr-only-desktop">{t('Account')}</span>
                        </NavLink>
                    </li>
                    <li>
                        <button onClick={logout}>
                            <i className="vnf-icons-icon-m-deconnexion-outline"></i>
                            <span className="sr-only-desktop">{t('Logout')}</span>
                        </button>
                    </li>
                </ul>
            </nav>
            <img src={logoBorneEtEaux} alt="" className="header__logo"/>

            {displayShortCuts && (
                <div className="header-shortcuts">
                    <div className="header-shortcuts__header">
                        <img src={logoBorneEtEaux} alt="" className="header__logo"/>
                    </div>
                    <div className="header-shortcuts__body">
                        <h2 className="header-shortcuts__title">{t('shortcuts')}</h2>
                        {matchRoles(roles, ['ROLE_USER', 'ROLE_TECHNICIEN', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_ARMATEUR', 'ROLE_NAVIGANT']) && (
                            <Link className="header-shortcuts__link" to="/terminal">{t('terminals_state')}</Link>
                        )}
                        <Link className="header-shortcuts__link" to="/transation">{t('transaction_history')}</Link>
                    </div>
                    <div className="header-shortcutrs__footer footer__bottom">
                        <p>{t('A service developped by ...')}</p>
                        <img src={logoVNFBleu} alt="logo VNF" />
                        <div className="footer__img-separator"></div>
                        <img src={logoHaropaBleu} alt="logo Haropa" />
                    </div>
                </div>
            )}            

        </header>
        <div className={`main-navigation ${open ? 'open' : ''}`}>
            <div className="main-navigation__menu main-navigation__menu--level-0">
                <div className="main-navigation__menu-wrapper">
                    <div className="main-navigation__top">
                        <button onClick={toggleMenu} className="main-navigation__close">
                            <span className="sr-only">{open ? t('close_menu') : t('open_menu')}</span>
                            <i className="vnf-icons-icon-s-croix-solid"></i>
                        </button>
                        <LanguageSelector navigation={true}/>
                    </div>
                    <nav>
                        <ul className="main-navigation__list">
                            {data.map(data => (matchRoles(roles, data.roles)) && <NavigationItem key={data.id} level={1} data={data} activeItems={activeItems} handleClick={handleClick} tree={[data.id]} roles={roles}/>)}
                        </ul>
                            <ul className="main-navigation__quicklinks">
                                {matchRoles(roles, ['ROLE_TECHNICIEN', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_ARMATEUR', 'ROLE_NAVIGANT']) && (
                                    <li><NavLink to="/terminal" exact>{t('terminals_state')}</NavLink></li>
                                )}
                                <li><NavLink to="/transaction" exact>{t('transaction_history')}</NavLink></li>
                            </ul>

                        <div className="main-navigation__separator"></div>
                        <div className="main-navigation__footer">
                            <p>{t('A service developped by ...')}</p>
                            <img src={logoVNF} alt="logo VNF" />
                            <img src={logoHaropa} alt="logo Haropa" />
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        <div className={`main-navigation__overlay ${open ? 'open' : ''}`} onClick={toggleMenu}></div>
        <TourManager openMenu={setOpen} roles={roles}/>
    </>)
}

/**
 * NAVIGATION ITEM COMPONENT FOR NAVIGATION MENU
 * REQUIRED BY NavigationMenu COMPONENT
 */
const NavigationItem = (props) => {
    const {data, level, activeItems, handleClick, tree, roles} = props
    const open = activeItems.includes(data.id)
    const { t } = useTranslation()

    const goBack = () => {
      handleClick(tree.slice(0, -1))
    }

    if (data.separator) {
        return <li className="main-navigation__separator"></li>
    }

    return (
        <li>
            {!data.children && <NavLink className={`main-navigation__link tour--${data.name}`} to={data.to} exact>{t(data.name)}</NavLink>}
            {data.children && <button className="main-navigation__link main-navigation__link--has-sublink" onClick={() => {handleClick(tree)}}>{t(data.name)}<i className="vnf-icons-icon-m-fleche-a-droite-outline"></i></button>}
            {data.children && (
            <div className={`main-navigation__menu main-navigation__menu--level-${level} ${open ? 'open' : ''}`}>
                <div className="main-navigation__menu-wrapper">
                    <button onClick={goBack} className="main-navigation__goback">
                        <span className="sr-only">{t('close_menu')}</span>
                        <i className="vnf-icons-icon-m-fleche-b-gauche-outline"></i>
                    </button>
                    <p className="main-navigation__menu-title">{t(data.name)}</p>
                    <ul className="main-navigation__list">
                        {data.children.map(data => (matchRoles(roles, data.roles)) && <NavigationItem key={data.id} level={level + 1} data={data} activeItems={activeItems} handleClick={handleClick} tree={[...tree, data.id]} roles={roles}/>)}
                    </ul>
                </div>
            </div>
            )}
        </li>
    )
}

export default withToast(hoistStatics(withTranslation()(withRouter(Navigation)), Navigation));