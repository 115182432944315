import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import { FormInput, PhoneInput, FileInput } from 'components/FormComponents';
import { REGEX, validateEmail, validateENI, validatePdf } from '../../helpers/validation';
import Modal from 'components/Modal';

class CreateModal extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            addship: {
                eni: "",
                name: "",
                email: "",
                phone: ""
            },
            loading: false,
            formIsValid: false
        }
    }

    componentDidMount() {
        if (this.checkIsConnectedPWA()) {
            //Load localstorage value
            this.loadStorageValues(['addship'], [this.state.addship]);
        }
    }

    checkFormValidity = () => {
        this.setState({formIsValid: (
            new RegExp(REGEX.ENI).test(this.state.addship.eni) &&
            new RegExp(REGEX.email).test(this.state.addship.email)
        )})
    }

    async afterSubmission(event) {
        const { t } = this.props;
        event.preventDefault();
        let url = "/boat/new";
        let method = "POST";
        let csrf = await this.getCsrfToken("/boat/csrf");
        let certificate_file = document.getElementById('certificate_file').files[0];
        let vat_exemption_file = document.getElementById('vat_exemption_file').files[0];
        let body = new FormData();

        body.append("eni", this.state.addship.eni);
        body.append("name", this.state.addship.name);
        body.append("email", this.state.addship.email);
        body.append("phone", this.state.addship.phone);
        body.append("_csrf_token", csrf);

        if (certificate_file)
            body.append("certificate_file", certificate_file);
        if (vat_exemption_file)
            body.append("vat_exemption_file", vat_exemption_file);

        this.setState({loading: true})
        let data = await this.request(url, method, body, true);
        this.setState({loading: false})

        if (data && data.status === "ok") {
            await this.getAccount();
            this.props.addToast(t('shipAdded'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
            this.props.onClose()
        }
    }

    render() {
        const { t } = this.props;

        return (<>
            <Modal open={true} onClose={this.props.onClose}>
                <div className="primary-form">
                    <h2 className="primary-form__title">{t('add_ship')}</h2>
                    <hr className="form-divider form-divider--big"/>
                    <form onSubmit={(e) => this.afterSubmission(e)}>
                        <div className="row">
                            <div className="col-sm-12">
                                <FormInput
                                    label={t("eni")}
                                    type="text"
                                    name="eni"
                                    id="eni"
                                    validation={validateENI}
                                    handle={(e) => this.handleInputChange(e, null, 'addship', this.checkFormValidity)}
                                    value={this.state.addship.eni}/>
                            </div>
                            <div className="col-sm-12">
                                <FormInput
                                    label={t("ship_name")}
                                    type="text"
                                    name="name"
                                    id="name"
                                    handle={(e) => this.handleInputChange(e, null, 'addship', this.checkFormValidity)}
                                    value={this.state.addship.name}/>
                            </div>
                            <div className="col-sm-12">
                                <FormInput
                                    label={t("ship_email")}
                                    type="email"
                                    name="email"
                                    id="email"
                                    validation={validateEmail}
                                    handle={(e) => this.handleInputChange(e, null, 'addship', this.checkFormValidity)}
                                    value={this.state.addship.email}/>
                            </div>
                            <div className="col-sm-12">
                                <PhoneInput
                                    required
                                    label={t("ship_phone")}
                                    name="phone"
                                    id="phone"
                                    value={this.state.addship.phone}
                                    handle={phone => this.setState(prevState => ({ addship: { ...prevState.addship, phone: phone } }))}/>
                            </div>
                            <div className="col-md-6">
                                <FileInput
                                    required
                                    id="certificate_file"
                                    name="certificate_file"
                                    validation={validatePdf}
                                    label={t('community_certificate_instruction')}
                                    handle={(e) => console.log(e)}/>
                            </div>
                            <div className="col-md-6">
                                <FileInput
                                    required
                                    id="vat_exemption_file"
                                    name="vat_exemption_file"
                                    validation={validatePdf}
                                    label={t('vat_exemption_file_instruction')}
                                    handle={(e) => console.log(e)}/>
                            </div>
                        </div>
                        
                        <hr className="form-divider form-divider--big"/>
                        <div className="primary-form__buttons">
                            <button type="button" onClick={this.props.onClose} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-bleu-a3">{t('cancel')}</button>
                            <button type="submit" disabled={!this.state.formIsValid} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('Save')}</button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>)
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(CreateModal)), CreateModal));
