import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

const IconLink = ({label, icon, focusIcon, showLabel, href, external, color, hasHoverColor = false, hoverColor}) => {
    const {t} = useTranslation()
    const [focus, setFocus] = useState(false)
    if (hasHoverColor)
        hoverColor = `icon-button-hover--${hoverColor || 'bleu-a2'}`;

    useEffect (() => ReactTooltip.rebuild(), [label])

    return external ? (
        <a
            target="_blank"
            rel="noreferrer"
            data-tip={t(label)}
            className={`icon-button icon-button--${color || 'bleu-a2'} ${hoverColor}`}
            href={href}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onMouseEnter={() => setFocus(true)}
            onMouseLeave={() => setFocus(false)}>
            <i className={focus ? focusIcon : icon}></i>
            <span className={showLabel ? '' : 'sr-only'}>{t(label)}</span>
        </a>
    ) : (
        <Link
            data-tip={t(label)}
            className={`icon-button icon-button--${color || 'bleu-a2'} ${hoverColor}`}
            to={href}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onMouseEnter={() => setFocus(true)}
            onMouseLeave={() => setFocus(false)}>
            <i className={focus ? focusIcon : icon}></i>
            <span className={showLabel ? '' : 'sr-only'}>{t(label)}</span>
        </Link>
    )
}

export default IconLink