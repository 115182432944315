import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { withToast } from '../../App';
import { DefaultLayout } from 'components/Layouts';
import Pagination from '../../components/Pagination';
import { TextFilter, PerPageFilter } from '../../components/Filters';
import { matchRoles } from '../../helpers/helpers';
import IconButton from '../../components/IconButton';
import CreateModal from './_createUser';
import EditModal from './_editUser';
import DeleteModal from './_deleteUser';
import ScrollContainer from 'react-indiana-drag-scroll';

class User extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                list: [],
            },
            roles: [],
            isActive: true,
            currentPage: 1,
            pageTotal: 1,
            filter: {
                search: '',
                per_page: 15
            },
            createUser: false, // true or false
            editUser: null, // user or null
            deleteUser: null, // user or null
            sortField: null,
            sortDirection: 'ASC',
        }
        this._isMounted = false;
        this.timeout = null
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.checkIsConnectedPWA()) {
            this.loadStorageValues(['user'], [this.state.user]);
            this.setState({ roles: this.loadRoles() });
            this._isMounted && this.getUsers();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.timeout) clearTimeout(this.timeout)
    }

    paginationOnClick = (e) => {
        const value = e.currentTarget.value
        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                search: ''
            },
            currentPage: value,
        }), this.getUsers)
    }

    perPageOnClick = (e) => {
        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                per_page: e.target.value
            },
            currentPage: 1,
        }), () => this.getUsers())
    }

    onFilterInputChange = (e) => {
        const term = e.target.value

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                search: term
            },
        }), () => {
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => this.getUsers(), 500)
        })
    }

    async getUsers() {
        const page = this.state.currentPage;
        const limit = this.state.filter.per_page;
        const method = "GET";
        let url = `/user/list/users?limit=${limit}&page=${page}`
        if (this.state.filter.search) url += `&q=${this.state.filter.search}`

        this.setState({
            currentPage: page
        })

        let data = await this.request(url, method);
        if (data && data.users) {
            this._isMounted && this.setState(prevState => ({
                user: {
                    ...prevState.user,
                    list: data.users,
                },
                isActive: false,
                pageTotal: Math.ceil(data.nbPages) || 1,
            }), () => {
                localStorage.setItem('user', JSON.stringify(this.state.user));
            });
        }
        else {
            this.setState({ isActive: false });
        }
    }

    handleSort = (field) => {
        this.setState({
            sortField: field,
            sortDirection: (field !== this.state.sortField || this.state.sortDirection === 'DESC') ? 'ASC' : 'DESC'
        }, () => this.getUsers())
    }

    render() {
        const { t } = this.props;
        return (
            <DefaultLayout>
                <h1 className="main-title">{t('Users')}</h1>
                <LoadingOverlay active={this.state.isActive} classNamePrefix="loader_" spinner text={t('loading')}>
                    <div className="table-header__container table-header__container--with-action">
                        <div className="table-header table-header--blue">
                            <h2 className="table-header__title">{t('users_list')}</h2>
                            <div className="row">
                                <div className="col-md-6">
                                    <TextFilter id="filter-users" name="filter-users" value={this.state.filter.search} label={t('filter_user_placeholder')} placeholder={t('filter_user_placeholder')} handle={this.onFilterInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="table-header__actions">
                            <button onClick={() => this.setState({createUser: true})} type="button" className="table-header__link"><i className="vnf-icons-icon-m-plus-rond-solid"></i>{t('add_user')}</button>
                            <PerPageFilter
                                name="per_page"
                                options={[15, 25, 50, 75, 100]}
                                value={this.state.filter.per_page}
                                handle={(e) => this.perPageOnClick(e)} />
                        </div>
                    </div>
                    <ScrollContainer className="card-table__wrapper" hideScrollbars={false}>
                        <table className="card-table card-table--blue">
                            <thead>
                                <tr>
                                    <th>{t('Full name')}</th>
                                    <th>{t('Email')}</th>
                                    <th>{t('Roles')}</th>
                                    <th>{t('Entity')}</th>
                                    <th>{t('Creation date')}</th>
                                    {matchRoles(this.state.roles, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']) && <th>{t('Actions')}</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.user.list.map((user) => (
                                    <tr key={user.id}>
                                        <td data-label={t('Full name')}>{user.firstName} {user.lastName}</td>
                                        <td data-label={t('Email')}>{user.email}</td>
                                        <td data-label={t('Roles')}>{user.roles.filter(role => role !== 'ROLE_USER').map(role => <span key={role} className={`badge badge--${role}`}>{t(role)}</span>)}</td>
                                        <td data-label={t('Entity')}>{user.entities.map(entity => entity.name).join(', ')}</td>
                                        <td data-label={t('Creation date')}>{moment.utc(user.created_at.date).format(t('DATE_HOURS_FORMAT'))}</td>
                                        {matchRoles(this.state.roles, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']) && (
                                            <td className="card-table__actions card-table__min-width">
                                                {/* TODO => COMMENT, REFACTOR OR SIMPLIFY */}
                                                {(matchRoles(user.roles.filter(role => role !== 'ROLE_USER'), this.state.roles) && !(matchRoles(user.roles.filter(role => role !== 'ROLE_USER'), ['ROLE_ADMIN']) && !this.state.roles.includes("ROLE_SUPER_ADMIN")) && this.getLocalStorageAccount()['email'] !== undefined && this.getLocalStorageAccount()['email'] !== user.email) && (
                                                    <div className="card-table__actions-wrapper">
                                                        <IconButton
                                                            onClick={()=> this.setState({editUser: user})}
                                                            label={t('Edit')}
                                                            icon="vnf-icons-icon-m-crayon-solid"
                                                            focusIcon="vnf-icons-icon-m-crayon-solid"
                                                            color="bleu-a3"/>
                                                        <IconButton
                                                            onClick={()=> this.setState({deleteUser: user})}
                                                            label={t('Delete')}
                                                            icon="vnf-icons-icon-m-corbeille-solid"
                                                            focusIcon="vnf-icons-icon-m-corbeille-solid"
                                                            color="bleu-a3"/>
                                                    </div>
                                                )}
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </ScrollContainer>

                    {(this.state.pageTotal > 1 && this.state.user.list.length > 0) && (
                        <Pagination handleClick={this.paginationOnClick} pageCurrent={this.state.currentPage} pageTotal={this.state.pageTotal} pageDisplay={3} />
                    )}

                    {this.state.createUser && <CreateModal onClose={() => this.setState({createUser: false}, this.getUsers)}/>}
                    {this.state.editUser && <EditModal user={this.state.editUser} onClose={() => this.setState({editUser: null}, this.getUsers)}/>}
                    {this.state.deleteUser && <DeleteModal user={this.state.deleteUser} onClose={() => this.setState({deleteUser: null}, this.getUsers)}/>}
                </LoadingOverlay>
            </DefaultLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(User)), User));
