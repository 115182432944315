import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import Modal from 'components/Modal';
import LoadingOverlay from 'react-loading-overlay';

class RefreshModal extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    async handleRegenerate() {
        const { t } = this.props;

        let url = "/boat/" + this.props.ship.id + "/regenerate-code";
        let method = "POST";
        let csrf = await this.getCsrfToken("/boat/csrf");
        let body = JSON.stringify({
            _csrf_token: csrf
        });

        this.setState({loading: true})
        let data = await this.request(url, method, body);
        this.setState({loading: false})
        
        if (data && data.status === "ok") {
            this.props.addToast(t('shipCodeRegenerated'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
            this.props.onClose()
        }
    }

    render() {
        const { t } = this.props;

        return (<>
            <LoadingOverlay active={this.state.loading} classNamePrefix="loader_" spinner text={t('loading')}></LoadingOverlay>
            <Modal
                title={t('RegenerateShipCodeTitle')}
                description={t('RegenerateShipCodeBody')}
                open={true}
                onClose={this.props.onClose}>
                    <div className="modal__buttons">
                        <button type="button" onClick={this.props.onClose} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-bleu-a3">{t('RegenerateShipCodeClose')}</button>
                        <button type="button" onClick={() => this.handleRegenerate()} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-orange-a4">{t('RegenerateShipCodeValidate')}</button>
                    </div>
            </Modal>
        </>)
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(RefreshModal)), RefreshModal));