import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import { FormInput, PhoneInput, FormSelect } from 'components/FormComponents';
import { RegisterLayout } from '../../components/Layouts';
import {
    REGEX,
    validateName,
    validateEmail,
    validateNumber,
    validateSIRET,
    validateVAT,
    validateAddress
} from '../../helpers/validation';
import { COUNTRIES } from 'config';

class RegisterStep2 extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            register_step2: {
                companyName: "",
                companySiret: "",
                companyTVA: "",
                companyAddress: "",
                companyCity: "",
                companyZipcode: "",
                companyCountry: "",
                companyEmail: "",
                companyPhone: ""
            },
            formIsValid: false
        }
    }

    checkFormValidity = () => {
        this.setState({formIsValid: (
            new RegExp(REGEX.name).test(this.state.register_step2.companyName) &&
            (
                new RegExp(REGEX.SIRET).test(this.state.register_step2.companySiret) ||
                new RegExp(REGEX.VAT).test(this.state.register_step2.companyTVA)
            ) &&
            !!this.state.register_step2.companyAddress?.length &&
            new RegExp(REGEX.name).test(this.state.register_step2.companyCity) &&
            new RegExp(REGEX.number).test(this.state.register_step2.companyZipcode) &&
            new RegExp(REGEX.name).test(this.state.register_step2.companyCountry) &&
            new RegExp(REGEX.email).test(this.state.register_step2.companyEmail)
        )})
    }

    componentDidMount() {
        this.checkIsConnectedPWA();
        //Load localstorage value
        this.loadStorageValues(['register_step2'], [this.state.register_step2], this.checkFormValidity);
        // clear companyTVA if companySiret & companyTVA are not empty to fix disabled input problem
        if (this.state.register_step2.companySiret && this.state.register_step2.companyTVA)
            this.setState(prevState => ({
                register_step2: {
                    ...prevState.register_step2,
                    companyTVA: ""
                }
            }));
    }

    async afterSubmissionStep2(event, go_back = false) {
        const { t } = this.props;
        event.preventDefault();
        let data = await this.registerStep2();
        if (data && data.status === "ok") {
            localStorage.setItem('register_step2', JSON.stringify(this.state.register_step2));
            if (!go_back) {
                this.props.addToast(t('Second step of registration successful'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
                this.props.history.push("/register/step3");
            }
        }

        if (go_back) this.props.history.push('/register/');
    }

    async registerStep2() {
        let url = "/register/step2";
        let method = "POST";
        let csrf = await this.getCsrfToken("/register/csrf");
        let body = JSON.stringify({
            companyName: this.state.register_step2.companyName,
            companySiret: this.state.register_step2.companySiret,
            companyTVA: this.state.register_step2.companyTVA,
            companyAddress: this.state.register_step2.companyAddress,
            companyCity: this.state.register_step2.companyCity,
            companyZipcode: this.state.register_step2.companyZipcode,
            companyCountry: this.state.register_step2.companyCountry,
            companyEmail: this.state.register_step2.companyEmail,
            companyPhone: this.state.register_step2.companyPhone,
            _csrf_token: csrf
        });
        let data = await this.request(url, method, body);
        return data;
    }

    render() {
        const { t } = this.props;
        return (
            <RegisterLayout step={2}>
                <div className="register__form primary-form">
                    <form onSubmit={(e) => this.afterSubmissionStep2(e)}>
                        <hr className="form-divider form-divider--big"/>
                        <div className="row">
                            <div className="col-12">
                                <FormInput
                                    required
                                    label={t("company_name")}
                                    type="text"
                                    name="companyName" id="companyName"
                                    handle={(e) => this.handleInputChange(e, null, 'register_step2', this.checkFormValidity)}
                                    validation={validateName}
                                    value={this.state.register_step2.companyName}
                                />
                            </div>
                            <div className="col-12">
                                <p className="primary-form__text">{t('fill_vat_or_siret')}</p>
                            </div>
                            <div className="col-md-6">
                                <FormInput
                                    required
                                    label={t("siret")}
                                    type="text"
                                    name="companySiret" id="companySiret"
                                    validation={validateSIRET}
                                    handle={(e) => this.handleInputChange(e, null, 'register_step2', this.checkFormValidity)}
                                    value={this.state.register_step2.companySiret}
                                    disabled={this.state.register_step2.companyTVA}
                                />
                            </div>
                            <div className="col-md-6">
                                <FormInput
                                    required
                                    label={t("intra_community_vat")}
                                    type="text"
                                    name="companyTVA" id="companyTVA"
                                    validation={validateVAT}
                                    handle={(e) => this.handleInputChange(e, null, 'register_step2', this.checkFormValidity)}
                                    value={this.state.register_step2.companyTVA}
                                    disabled={this.state.register_step2.companySiret}
                                />
                            </div>
                            {/* <div className="col-12">
                                <hr className="form-divider" />
                            </div> */}
                            <div className="col-md-12">
                                <FormInput
                                    required
                                    label={t("address")}
                                    type="text"
                                    name="companyAddress" id="companyAddress"
                                    handle={(e) => this.handleInputChange(e, null, 'register_step2', this.checkFormValidity)}
                                    validation={validateAddress}
                                    value={this.state.register_step2.companyAddress}/>
                            </div>
                            <div className="col-md-6">
                                <FormInput
                                    required
                                    label={t("city")}
                                    type="text"
                                    name="companyCity" id="companyCity"
                                    handle={(e) => this.handleInputChange(e, null, 'register_step2', this.checkFormValidity)}
                                    validation={validateName}
                                    value={this.state.register_step2.companyCity}/>
                            </div>
                            <div className="col-md-6">
                                <FormInput
                                    required
                                    label={t("zipcode")}
                                    type="text"
                                    name="companyZipcode" id="companyZipcode"
                                    handle={(e) => this.handleInputChange(e, null, 'register_step2', this.checkFormValidity)}
                                    validation={validateNumber}
                                    value={this.state.register_step2.companyZipcode}/>
                            </div>
                            <div className="col-md-6">
                                <FormSelect
                                    required
                                    options={COUNTRIES.sort().map(country => ({value: country, name: country}))}
                                    label={t("country")}
                                    type="text"
                                    name="companyCountry" id="companyCountry"
                                    handle={(e) => this.handleInputChange(e, null, 'register_step2', this.checkFormValidity)}
                                    validation={validateName}
                                    value={this.state.register_step2.companyCountry}/>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-6">
                                <FormInput
                                    required
                                    label={t("company_email")}
                                    type="email"
                                    name="companyEmail" id="companyEmail"
                                    handle={(e) => this.handleInputChange(e, null, 'register_step2', this.checkFormValidity)}
                                    validation={validateEmail}
                                    value={this.state.register_step2.companyEmail}/>
                            </div>
                            <div className="col-md-6">
                                <PhoneInput
                                    label={t("Phone number")}
                                    name="phone"
                                    id="phone"
                                    value={this.state.register_step2.companyPhone}
                                    handle={phone => this.setState(prevState => ({ register_step2: { ...prevState.register_step2, companyPhone: phone } }))}/>
                            </div>
                        </div>
                        <hr className="form-divider form-divider--big"/>
                        <div className="primary-form__buttons">
                            <button type="button" onClick={(e) => this.afterSubmissionStep2(e, true)} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-bleu-a3">{t('Previous')}</button>
                            <button type="submit" disabled={!this.state.formIsValid} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('Next')}</button>
                        </div>
                        <p className="primary-form__required-fields">* {t('required_fields')}</p>
                    </form>
                </div>
            </RegisterLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(RegisterStep2)), RegisterStep2));