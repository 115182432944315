import { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

const IconButton = ({disabled = false, label, icon, focusIcon, showLabel, onClick, color, hasHoverColor = false, hoverColor}) => {
    const {t} = useTranslation()
    const [focus, setFocus] = useState(false)
    if (hasHoverColor)
        hoverColor = `icon-button-hover--${hoverColor || 'bleu-a2'}`;

    useEffect (() => ReactTooltip.rebuild(), [label])

    return (
        <button
            disabled={disabled}
            type="button"
            className={`icon-button icon-button--${color || 'bleu-a2'} ${hoverColor}`}
            onClick={onClick}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onMouseEnter={() => setFocus(true)}
            onMouseLeave={() => setFocus(false)}
        >
            <i
                className={focus ? focusIcon : icon}
                data-tip={t(label)}
            />
            <span className={showLabel ? '' : 'sr-only'}>{t(label)}</span>
        </button>
    )
}

export default IconButton