import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { withToast } from '../../App';
import { DefaultLayout } from 'components/Layouts';
import Pagination from '../../components/Pagination';
import { TextFilter, SelectFilter, PerPageFilter, SortbyFilter } from '../../components/Filters';
import { StatusDropDown } from './_statusDropDown';
import IconButton from '../../components/IconButton';
import { matchRoles } from 'helpers/helpers';
import EditModal from './_editUsager'
import DeleteModal from './_deleteUser'
import ScrollContainer from 'react-indiana-drag-scroll';

const entity_filters = [{value: 'all', name: 'display_all'}];
const status_filters = [{value: 'all', name: 'display_all'}, {value: 'allowed', name: 'allowed'}, {value: 'denied', name: 'denied'}, {value: 'waiting', name: 'waiting'}];

class Usager extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            usager: {
                list: []
            },
            roles: [],
            isActive: true,
            pageTotal: 1,
            currentPage: 1,
            filter: {
                search: '',
                status: 'all',
                entity: 'all',
                per_page: 15
            },
            entities: [],
            editUser: null, // user or null
            deleteUser: null, // user or null
            sortField: '',
            sortDirection: 'ASC',
        }
        this._isMounted = false;
        this.timeout = null
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.checkIsConnectedPWA()) {
            //Load localstorage value
            this.loadStorageValues(['usager'], [this.state.usager]);
            this.setState({ roles: this.loadRoles() });
            this.getData()
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.timeout) clearTimeout(this.timeout)
    }

    paginationOnClick = (e) => {
        const value = e.currentTarget.value
        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                search: ''
            },
            currentPage: value,
        }), this.getUsagers)
    }

    perPageOnClick = (e) => {
        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                per_page: e.target.value
            },
            currentPage: 1,
        }), () => this.getUsagers())
    }

    onFilterInputChange = (e) => {
        const term = e.target.value

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                search: term
            },
        }), () => {
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => this.getUsagers(), 500)
        })
    }

    async getData() {
        let url = "/entity/list";
        let method = "GET";
        let data = await this.request(url, method);
        if (data && data.entities) {
            let entities = (this.loadMainRole() === 'ROLE_SUPER_ADMIN')
                ? [...entity_filters, ...data.entities.map(e => ({value: e.id, name: e.name}))]
                : [...entity_filters, ...JSON.parse(localStorage.getItem('account')).entities.map(e => ({value: e.id, name: e.name}))]

            this._isMounted && this.setState(prevState => ({
                entities: entities,
                filter: {
                    ...prevState.filter,
                    entity: entities.length > 1 ? entities[1].value :  'all'
                }
            }), this.getUsagers)
        }
    }

    async getUsagers() {
        const page = this.state.currentPage;
        const limit = this.state.filter.per_page;
        const entity = this.state.filter.entity;
        const status = this.state.filter.status;
        const sortField = this.state.sortField;
        const sortDirection = this.state.sortDirection;

        const method = "GET";
        let url = `/user/list/usagers?limit=${limit}&page=${page}`;
        if (this.state.filter.search !== '') url += `&q=${this.state.filter.search}`
        if (entity !=='all') url += `&permission_entity=${entity}`;
        if (status !=='all') url += `&permission_status=${status}`;
        if (sortField) url+= `&order_on=${(sortField === "Full name") ? "company_name" : "creation_date"}&order_dir=${sortDirection}`;
        // pagination
        this.setState({
            currentPage: page
        })

        let data = await this.request(url, method);

        if (data && data.users) {
            this._isMounted && this.setState(prevState => ({
                usager: {
                    ...prevState.usager,
                    list: data.users
                },
                isActive: false,
                pageTotal: Math.ceil(data.nbPages) || 1,
            }), () => {
                localStorage.setItem('usager', JSON.stringify(this.state.usager));
            });
        }
        else {
            this.setState({ isActive: false });
        }
    }

    async getFile(id, file_type) {
        let url = "/company/" + id + "/files";
        let method = "GET";
        let files = await this.request(url, method);
        if (file_type === "rib")
            window.open(files.rib, '_blank').focus();
        if (file_type === "kbis")
            window.open(files.kbis, '_blank').focus();
    }

    async onPermissionChange(e, user_id, entity_id) {
        let url = `/entity/${e.accepted ? 'accept' : 'deny'}`
        let method = 'POST'
        let csrf = await this.getCsrfToken("/entity/csrf");
        let body = JSON.stringify({
            entity_id: entity_id,
            user_id: user_id,
            reason: e.reason,
            _csrf_token: csrf
        })

        let data = await this.request(url, method, body)
        if (data && data.status === 'ok') {
            this.getUsagers()
        }
    }

    renderStatusDropDown (user, entity_id) {
        const filteredPermissions = user.permissions.filter(perm => parseInt(perm.entity.id) === parseInt(entity_id))

        if (filteredPermissions.length === 0) {
            return <></>
        }

        const {grant_status, reason} = filteredPermissions[0]
        return (
            <StatusDropDown
                status={grant_status}
                reason={reason}
                onChange={(e) => this.onPermissionChange(e, user.id, entity_id)}/>
        )
    }

    handleSort = (field) => {
        this.setState({
            sortField: field,
            sortDirection: (field !== this.state.sortField || this.state.sortDirection === 'DESC') ? 'ASC' : 'DESC'
        }, () => this.getUsagers())
    }


    render() {
        const { t } = this.props;
        return (
            <DefaultLayout>
                <h1 className="main-title">{t('boat_owners')}</h1>
                <LoadingOverlay
                    active={this.state.isActive}
                    classNamePrefix="loader_"
                    spinner
                    text={t('loading')}>
                    <div className="table-header__container table-header__container--with-action">
                        <div className="table-header table-header--blue">
                            <h2 className="table-header__title">{t('boat_owners_list')}</h2>
                            <div className="row">
                                <div className="col-md-6 mb-4 mb-md-0">
                                    <TextFilter id="filter-users" name="filter-users" value={this.state.filter.search} label={t('filter_user_placeholder')} placeholder={t('filter_user_placeholder')} handle={this.onFilterInputChange}/>
                                </div>
                                <div className="col-md-3 mb-4 mb-md-0">
                                    <SelectFilter
                                        id="entity"
                                        name="entity"
                                        label={t('entity')}
                                        value={this.state.filter.entity}
                                        handle={(e) => this.handleInputChange(e, null, 'filter', () => this.getUsagers())}
                                        showLabel
                                        small
                                        options={this.state.entities}/>
                                </div>
                                <div className="col-md-3">
                                    <SelectFilter
                                        id="status"
                                        name="status"
                                        label={t('status')}
                                        value={this.state.filter.status}
                                        handle={(e) => this.handleInputChange(e, null, 'filter', () => this.getUsagers())}
                                        showLabel
                                        small
                                        options={status_filters}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="table-header__actions">
                        <div className="ml-md-auto">
                            <PerPageFilter
                                name="per_page"
                                options={[15,25,50]}
                                value={this.state.filter.per_page}
                                handle={(e) => this.perPageOnClick(e)}/>
                        </div>
                    </div>

                    <ScrollContainer className="card-table__wrapper" hideScrollbars={false}>
                        <table className="card-table card-table--blue">
                            <thead>
                                <tr>
                                    <th><SortbyFilter label={t('Full name')} active={this.state.sortField === 'Full name'} direction={this.state.sortDirection} onClick={() => this.handleSort('Full name')}/></th>
                                    <th>{t('Email')}</th>
                                    <th>{t('Phone number')}</th>
                                    <th>{t('address')}</th>
                                    <th>{t('city')}</th>
                                    <th>{t('country')}</th>
                                    <th>{t('SIRET')}</th>
                                    <th><SortbyFilter label={t('Creation date')} active={this.state.sortField === 'Creation date'} direction={this.state.sortDirection} onClick={() => this.handleSort('Creation date')}/></th>
                                    <th>{t('rib')}</th>
                                    <th>{t('kbis')}</th>
                                    <th>{t('boat')}</th>
                                    <th>{t('status')}</th>
                                    {matchRoles(this.state.roles, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']) && <th>{t('Actions')}</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.usager.list.map((user, index) =>
                                        <tr key={index}>
                                            <td data-label={t('Full name')}>{user.company.name}</td>
                                            <td data-label={t('Email')}>{user.email}</td>
                                            <td data-label={t('Phone number')}>{user.phone}</td>
                                            <td data-label={t('address')}>{user.company.address}</td>
                                            <td data-label={t('city')}>{user.company.city}</td>
                                            <td data-label={t('country')}>{user.company.country}</td>
                                            <td data-label={t('SIRET')}>{user.company.companyIdentifier}</td>
                                            <td data-label={t('Creation date')}>{moment.utc(user.created_at.date).format(t('DATE_HOURS_FORMAT'))}</td>
                                            <td data-label={t('rib')}>
                                                {user.company.rib_file && <IconButton label={t('see_rib_file')} onClick={() => this.getFile(user.company.id, "rib")} icon="vnf-icons-icon-m-document-outline" focusIcon="vnf-icons-icon-m-document-solid" color="bleu-a3"/>}
                                                {!user.company.rib_file && <div className="b"><i className="vnf-icons-icon-m-croix-fermer-solid"></i></div>}
                                            </td>
                                            <td data-label={t('kbis')}>
                                                {user.company.kbis_file && <IconButton label={t('see_kbis_file')} onClick={() => this.getFile(user.company.id, "kbis")} icon="vnf-icons-icon-m-document-outline" focusIcon="vnf-icons-icon-m-document-solid" color="bleu-a3"/>}
                                                {!user.company.kbis_file && <div className="b"><i className="vnf-icons-icon-m-croix-fermer-solid"></i></div>}
                                            </td>
                                            <td data-label={t('boat')}>
                                                <IconButton label={t('see_boat')} icon="vnf-icons-icon-m-detail-outline" focusIcon="vnf-icons-icon-m-detail-outline" color="bleu-a4" hasHoverColor={true} onClick={() => {this.props.history.push("/ship/" + user.id);}}/>
                                            </td>
                                            <td data-label={t('status')}>{this.renderStatusDropDown(user, this.state.filter.entity)}</td>
                                            {matchRoles(this.state.roles, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']) && 
                                                <td className="card-table__actions card-table__min-width">
                                                    <div className="card-table__actions-wrapper">
                                                        <IconButton
                                                            onClick={()=> this.setState({editUser: user})}
                                                            label={t('Edit')}
                                                            icon="vnf-icons-icon-m-crayon-solid"
                                                            focusIcon="vnf-icons-icon-m-crayon-solid"
                                                            color="bleu-a3"/>
                                                        <IconButton
                                                            onClick={()=> this.setState({deleteUser: user})}
                                                            label={t('Delete')}
                                                            icon="vnf-icons-icon-m-corbeille-solid"
                                                            focusIcon="vnf-icons-icon-m-corbeille-solid"
                                                            color="bleu-a3"/>
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </ScrollContainer>
                    
                    {(this.state.pageTotal > 1 && this.state.usager.list.length > 0) && <Pagination handleClick={this.paginationOnClick} pageCurrent={this.state.currentPage} pageTotal={this.state.pageTotal} pageDisplay={3} />}

                    {this.state.editUser && <EditModal user={this.state.editUser} onClose={() => this.setState({editUser: null}, this.getUsagers)}/>}
                    {this.state.deleteUser && <DeleteModal user={this.state.deleteUser} onClose={() => this.setState({deleteUser: null}, this.getUsagers)}/>}
                </LoadingOverlay>
            </DefaultLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(Usager)), Usager));
