import { useTranslation } from "react-i18next"
// import { REGEX } from "helpers/validation"

const PasswordHelper = (password) => {
    const {t} = useTranslation()

    return (
        <div className="password-helper">
            <p>{t('password_helper_intro')}</p>
            <ul>
                <li>{t('password_helper_length')}</li>
                <li>{t('password_helper_uppercase')}</li>
                <li>{t('password_helper_digit')}</li>
                <li>{t('password_helper_specialchar')}</li>
            </ul>
        </div>
    )
}

export default PasswordHelper