import { useState } from "react"
import { useTranslation } from "react-i18next"
import SlideDown from "react-slidedown"
import { API_URL } from "config"

const LanguageSelector = ({navigation}) => {
    const [open, setOpen] = useState(false)
    const { i18n } = useTranslation()
    const language = i18n.options.resources[i18n.language].name
    const languages = Object.values(i18n.options.resources)

    const handleClick = async (e) => {
        const lang = e.target.value
        // save localStorage language to keep language after refresh
        localStorage.setItem('language', lang);
        i18n.changeLanguage(lang)
        setOpen(false)

        if (navigation) sendLanguageChangeRequest(lang)
    }

    const sendLanguageChangeRequest = async (lang) => {
        try {
            const response = await fetch(API_URL + '/user/csrf', {
                headers: {Accept: 'application/json','Content-Type': 'application/json'},
                credentials: 'include'
            })
            const data = await response.json()
            const csrf = data.token

            fetch(API_URL + '/user/language/' + lang, {
                method: 'POST',
                headers: {Accept: 'application/json','Content-Type': 'application/json'},
                credentials: 'include',
                body: JSON.stringify({
                    _csrf_token: csrf
                })
            })
        } catch(error) {
            console.error(error)
        }
    }

    return (
        <div className={navigation ? 'language-selector--navigation' : 'language-selector'}>
            <button className={navigation ? 'language-selector--navigation__toggle' : 'language-selector__toggle'} onClick={() => setOpen(!open)}>{language}<i className={`vnf-icons-icon-m-fleche-a-${open ? 'haut': 'bas'}-outline`}></i></button>
            <SlideDown closed={!open} className={navigation ? 'language-selector--navigation__content': `language-selector__content`}>
                <ul>
                    {languages.filter(lang => lang.name !== language).map(lang => <li key={lang.key}><button onClick={handleClick} value={lang.key}>{lang.name}</button></li>)}
                </ul>
            </SlideDown>
        </div>
    )
}

export default LanguageSelector