import { useState, useEffect } from "react";
import classNames from 'classnames';
import ReactPhoneInput from 'react-phone-input-2';
import { useTranslation } from "react-i18next";
import OutsideClick from 'react-outclick'
import { Switch, TextField } from "@material-ui/core";
import IconLink from './IconLink';
import IconButton from './IconButton';
import ReactToolTip from 'react-tooltip';

/**
 * Validation prop should return a object or null: {
 *  type: 'erreur'|'succes',
 *  message: String
 * }
 */
export const FormInput = ({label, type, name, id, value, handle, onClick, disabled, required, validation, helper, appearance = "primary"}) => {
    const [show, setShow] = useState(false)
    const [isFocus, setIsFocus] = useState(false)
    const [isPristine, setIsPristine] = useState(value.length === 0)
    const [isDirty, setIsDirty] = useState(value.length > 0)
    const [msg, setMsg] = useState(null)

    const labelID = `${id}-label`

    const {t} = useTranslation()

    useEffect (() => {
        setIsDirty(value.length > 0)
    }, [value])

    useEffect(() => {
        if (disabled) setMsg(null)
    }, [disabled])

    const onInput = (e) => {
        const val = e.target.value
        setMsg(validation ? validation(val) : null)
        handle(e)
    }

    return (
        <div className="input-wrapper">
            <label htmlFor={id} className={classNames({
                input: true,
                'input--button': type === 'button',
                "input--primary": appearance === "primary",
                "input--secondary": appearance === "secondary",
                valid: !isPristine && msg && msg.type === "succes",
                error: !isPristine && msg && msg.type === "erreur",
                focus: isFocus,
                dirty: isDirty})}>

                <input
                    onClick={onClick}
                    onInput={onInput}
                    onFocus={() => setIsFocus(true)}
                    onBlur={() => {setIsFocus(false); setIsPristine(false)}}
                    type={show ? 'text' : type}
                    aria-labelledby={labelID}
                    name={name}
                    id={id}
                    disabled={disabled}
                    value={value}/>

                {(type === 'password') && (
                    <button type="button" onClick={() => setShow(!show)} className="input__show-password">
                        {/* TODO => UPDATE ICONS */}
                        <i className={type === 'password' ? 'vnf-icons-icon-s-oeil-solid' : 'vnf-icons-icon-s-oeil-barre-solid'}></i>
                        <span className="sr-only">Voir le mot de passe</span>
                    </button>
                )}

                <span className="label" id={labelID}>{label}{required && ' *'}</span>
            </label>

            {helper && (
                <div className={`helper helper--${appearance} helper--${appearance}--information`}>
                    <i className={`vnf-icons-icon-s-information-solid`}></i>
                    {t(helper)}
                </div>
            )}

            {(msg && !isPristine) && (
                <div className={`helper helper--${appearance} helper--${appearance}--${msg.type}`}>
                    <i className={`vnf-icons-icon-s-${msg.type}-solid`}></i>
                    {t(msg.message)}
                </div>
            )}
        </div>
    )
}

export const FormAutoComplete = ({id, name, value, onFieldChange, handle, label, suggestions, minChars = 2, required, appearance = "primary", placeholder}) => {
    const [thisValue, setThisValue] = useState(value)
    const [visible, setVisible] = useState(true)
    const [isDirty, setIsDirty] = useState(value.length > 0)
    const [isFocus, setIsFocus] = useState(false)
    const labelID = `${id}-label`

    const handleChange = (e) => {
        setThisValue(e.target.value)
        let trigger_handle = e.target.value === ''|| e.type === 'click'
        let trigger_fieldChange = !trigger_handle && e.target.value.length >= minChars
        if (trigger_handle) {
            setVisible(false)
            handle(e)
        }
        trigger_fieldChange && onFieldChange(e)
    }

    useEffect (() => {
        setIsDirty(thisValue.length > 0)
    }, [thisValue])

    useEffect(() => setVisible(true), [suggestions])

    return (
        <div className="input-wrapper">
            <label htmlFor={id} className={classNames({
                    input: true,
                    "input--primary": appearance === "primary",
                    "input--secondary": appearance === "secondary",
                    focus: isFocus,
                    dirty: isDirty || placeholder
            })}>
            <div className="filter__input-wrapper">
                <input
                    id={id}
                    name={name}
                    type="text"
                    value={thisValue}
                    placeholder={placeholder}
                    onInput={handleChange}
                    autoComplete="off"
                    onFocus={() => setIsFocus(true)}
                    onBlur={() => setIsFocus(false)}/>
                    {(suggestions && suggestions.length > 0 && visible) && (
                        <OutsideClick onOutsideClick={() => setVisible(false)}>
                            <ul className="filter__suggestions">
                                {suggestions.map((suggestion, i) => (
                                    <li key={i}><button data-id={suggestion.id} name={name} onClick={handleChange} value={suggestion.name}>{suggestion.name}</button></li>
                                ))}
                            </ul>
                        </OutsideClick>
                    )}
            </div>
                <span className="label" id={labelID}>{label}{required && ' *'}</span>
            </label>
        </div>
    )
}

export const FormSelect = ({label, name, id, value, handle, options, required}) => {
    const [dirty, setDirty] = useState(value.length > 0)
    const [focus, setFocus] = useState(false)

    const {t} = useTranslation()

    const handleChange = (e) => {
        setDirty(e.target.value !== "")
        setFocus(false)
        handle(e)
    }

    useEffect (() => {
        setDirty(value !== "")
    }, [value])

    const onClick = (e) => {
        if (e.target.id && e.target.id === id) {
            setFocus(true)
        } else {
            setFocus(false)
        }
    }

    return (
        <OutsideClick onOutsideClick={onClick}>
            <div className="input-wrapper">
                <div className={`select ${dirty ? 'dirty': ''} ${focus ? 'focus': ''}`}>
                    <label htmlFor={id}>{label}{required && ' *'}</label>
                    <select
                        id={id}
                        name={name}
                        value={value}
                        onClick={onClick}
                        onChange={handleChange}>
                        <option label=" "></option>
                        {options.map(option => (
                            <option key={option.value} value={option.value}>{t(option.name)}</option>
                        ))}
                    </select>
                    <i className={`vnf-icons-icon-m-triangle-${focus ? 'haut' : 'bas'}-solid`}></i>
                </div> 
            </div>
        </OutsideClick>
    )
}

export const FormCheckBox = ({label, name, id, value, handle, appearance}) => {
    const labelID = `${id}-label`
    const [isChecked, setIsChecked] = useState(value)

    const onChange = (e) => {
        setIsChecked(e.target.checked);
        handle(e)
    }

    useEffect(() => {
        setIsChecked(value)
    }, [value])

    return (
        <div className="input-wrapper">
            <div className={`checkbox checkbox--${appearance}`}>
                <label htmlFor={id} className={isChecked ? 'checked' : ''}>
                    <input type="checkbox" id={id} name={name} value={value} checked={isChecked} onChange={onChange} aria-labelledby={labelID}></input>
                </label>
                <div id={labelID}
                    className="label"
                    dangerouslySetInnerHTML={{__html: label, interpolation: {escapeValue: false}}}></div> 
            </div>
        </div>
    )
}

export const FormSubmit = ({label, disabled}) => {
    return (
        <div className="input-wrapper">
            <div className="submit-wrapper">
                <button type="submit" className="submit" disabled={disabled}>{label}</button>
            </div>
        </div>
    )
}

export const PhoneInput = ({value, required, handle, label, name, id, placeholder='0607080911121314151617181920'}) => {
    const [dialCode, setDialCode] = useState('33')
    const [holder, setHolder] = useState('0 60 70 80 91')

    const onChange = (value, country) => {
        updatePlaceholder(country)

        // Force french phone not to have a leading 0
        if (country.countryCode === "fr" && value[2] && value[2] === '0') {
            value = value.substr(0, 2) + value.substr(3)
        }

        if (handle) handle(value)
    }

    const updatePlaceholder = (country) => {
        let numbers = placeholder.split('')
        const {format, dialCode} = country

        if (!format || !dialCode) return

        let pattern = format.split(' ')
        pattern.shift()
        let newPlaceholder = pattern.map((s,i) => {
            return s.split('').map(s => numbers.shift()).join('')
        }).join(' ')

        setDialCode(dialCode)
        setHolder(newPlaceholder)
    }

    return (
        <div className="input-wrapper">
            <label className="sr-only" htmlFor={id}>{label}</label>
            <ReactPhoneInput
                countryCodeEditable={false}
                placeholder={`+${dialCode} ${holder}`}
                inputProps={{id, name}}
                containerClass="input-phone"
                specialLabel={label + (required ? ' *' : '')}
                value={value}
                onChange={onChange}
                country='fr'/>
            {/* <div><div>+{dialCode}_</div><span>{holder}</span></div> */}
        </div>
    )
}

export const FormMulticheckbox = ({options, name, label, handle, required, checkedItems = [], appearance = 'default', disabled}) => {
    const [selected, setSelected] = useState(checkedItems.map(item => item.toString()))
    const [checkAll, setCheckAll] = useState(false)
    const {t} = useTranslation()

    useEffect(() => {
        setCheckAll(checkedItems.length === options.length)
        setSelected(checkedItems)
    },[checkedItems, options])

    const handleItemChange = (e) => {
        let {value, checked} = e.target
        const newState = checked ? [...selected, value] : selected.filter(val => val !== value)
        setSelected(newState)

        setCheckAll(options.length === newState.length)

        handle && handle({
            target: {
                name: name,
                type: 'multicheckbox',
                value: newState
            }
        })
    }

    const handleCheckAll = (e) => {
        let {checked} = e.target
        const newState = checked ? options.map(option => option.value.toString()) : []
        setSelected(newState)
        setCheckAll(checked)


        handle && handle({
            target: {
                name: name,
                type: 'multicheckbox',
                value: newState
            }
        })
    }

    return (
        <div className="input-wrapper">
            <div className="multi-checkbox">
                <p className="multi-checkbox__label">{label}{required && ' *'}</p>
                <div className="multi-checkbox__list">
                    {options.map(option => <MultiCheckboxItem appearance={appearance} key={option.value} name={name} label={option.name} value={option.value} checked={selected.includes(option.value.toString())} handle={handleItemChange} disabled={disabled}/>)}
                </div>
                {!disabled && (
                <div className={`checkbox checkbox--${appearance} checkbox--checkall`}>
                    <label className={checkAll ? 'checked' : ''}>
                        <input type="checkbox" arial-labelledby={`${name}--checkall`} value={checkAll} onChange={handleCheckAll} checked={checkAll}></input>
                    </label>
                    <div className="label" id={`${name}--checkall`}>{checkAll ? t('uncheck_all') : t('check_all') }</div> 
                </div>
                )}
            </div>
        </div>
    )
}

const MultiCheckboxItem = ({name, value, label, handle, appearance='default', checked, disabled}) => {
    const id = `${name}--${value}`
    const labelID = `${id}-label`
    const {t} = useTranslation()

    const onChange = (e) => {
        handle(e)
    }

    return (
        <div className="multi-checkbox__item">
            <div className={`checkbox checkbox--${appearance}${disabled && ' disabled'}`}>
                <label htmlFor={id} className={`${checked && 'checked'}${disabled && ' disabled'}`}>
                    <input type="checkbox" id={id} name={name} value={value} onChange={onChange} aria-labelledby={labelID} checked={checked} disabled={disabled}></input>
                </label>
                <div id={labelID} className="label">{t(label)}</div> 
            </div>
        </div>
    )
}

export const FileInput = ({id, name, label, handle, link, required, onDelete, validation}) => {
    const label_id = `${id}-label`
    const [filename, setFilename] = useState(null)
    const [msg, setMsg] = useState(null)

    const handleChange = (e) => {
        if (e?.target?.files.length > 0) {
            if (validation) {
                const validationMessage = validation(e)
                setMsg(validation(e))
                if (validationMessage) return
            }

            setFilename(e.target.files[0].name || null)
            handle && handle(e)
        }
    }

    const handleRemove = () => {
        ReactToolTip.hide()
        onDelete()
    }

    const {t} = useTranslation()

    return (
        <div className="input-wrapper">
            <div className="input-file">
                <span className="input-file__label" id={label_id}>{label}{required && ' *'}</span>
                <label htmlFor={id} className={filename ? 'active' : ''}>
                    <input type="file" name={name} id={id} onChange={handleChange} aria-labelledby={label_id}/>
                    <i className="vnf-icons-icon-m-upload-outline"></i>
                    <span className="input-file__instruction">{t('file_input_instruction')}</span>
                    {filename && (
                        <div className="input-file__actions">
                            <div className="icon-button icon-button--bleu-a2">
                                <i className="vnf-icons-icon-m-document-outline"></i>{filename}
                            </div>
                        </div>
                    )}
                    {(!filename && link) && (
                        <div className="input-file__actions">
                            <IconLink
                                href={link.url}
                                showLabel
                                external
                                label={t(link.label) || t('see_file')}
                                icon="vnf-icons-icon-m-oeil-outline"
                                focusIcon="vnf-icons-icon-m-oeil-solid"/>
                            {onDelete && (
                            <IconButton
                                onClick={handleRemove}
                                icon="vnf-icons-icon-m-corbeille-outline"
                                focusIcon="vnf-icons-icon-m-corbeille-solid"
                                label={('remove')}/>
                            )}
                        </div>
                    )}
                </label>
            </div>
            {(msg) && (
                <div className={`helper helper--primary helper--primary--${msg.type}`}>
                    <i className={`vnf-icons-icon-s-${msg.type}-solid`}></i>
                    {t(msg.message)}
                </div>
            )}
        </div>
    )
}

export const FormSwitch = ({id, name, handle, label, value, labelOnOff, appearance="primary"}) => {
    return (
        <div className="input-wrapper">
            <div className={`form-switch form-switch--${appearance}`}>
                {labelOnOff && (<span>{labelOnOff[0]}</span>)}
                <Switch
                    
                    checked={value}
                    onChange={handle}
                    inputProps={{id: id, name: name}}/>
                {labelOnOff && (<span>{labelOnOff[1]}</span>)}
                <label htmlFor={id} className={labelOnOff ? 'sr-only': ''}>{label}</label>
            </div>
        </div>
    )
}

export const FormTextArea = ({id, name, handle, label, value, appearance="primary", placeholder="", required, validation = null}) => {
    const [msg, setMsg] = useState(null)
    const [dirty, setDirty] = useState(false)

    const onChange = (e) => {
        setDirty(true)
        setMsg(validation ? validation(e.target.value) : null)
        handle(e)
    }

    const {t} = useTranslation()

    return (
        <div className="input-wrapper">
            <TextField
                error={msg && msg.type === "erreur"}
                className="input input--text-area"
                required={required}
                placeholder={placeholder}
                value={value}
                id={id}
                name={name}
                label={label}
                onChange={onChange}
                multiline
                rows={2}
                fullWidth
                variant={appearance === 'secondary' ? 'standard' : 'outlined'}/>
            {(msg && dirty) && (
                <div className={`helper helper--${appearance} helper--${appearance}--${msg.type}`}>
                    <i className={`vnf-icons-icon-s-${msg.type}-solid`}></i>
                    {t(msg.message)}
                </div>
            )}
        </div>
    )
}

export const FormPermission = ({label, name, id, onClick, value, disabled}) => {
    return (
        <div className="input-wrapper">
            <div className="form-permission">
                <label htmlFor={id}>{label}</label>
                <input
                    className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4"
                    type="button"
                    id={id}
                    value={value}
                    name={name}
                    disabled={disabled}
                    onClick={onClick} />
            </div>
        </div>
    )
}
