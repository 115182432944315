import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import { FormTextArea, FormSelect } from 'components/FormComponents';
import Modal from 'components/Modal';
import LoadingOverlay from 'react-loading-overlay';
import { validateTicketMessage } from 'helpers/validation';

const reason_options = ['issue_terminal_panel', 'issue_interface_operation', 'issue_payement', 'issue_damaged_terminal', 'issue_personnal_data', 'issue_other']
        .map(str => ({value: str, name: str}))

class CreateTicket extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            addticket: {
                terminal_id: '',
                reason: "",
                message: ""
            },
            terminals: [],
            formIsValid: false,
            isLoading: false
        }
    }

    componentDidMount() {
        this._isMounted = true
        this.loadStorageValues(['addticket'], [this.state.addticket]);
        this.getTerminals()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    checkFormValidity = () => {
        this.setState({formIsValid: (
            this.state.addticket.reason !== "" &&
            this.state.addticket.message.length > 0 &&
            this.state.addticket.message.length <= 500
        )})
    }

    async getTerminals () {
        let url = `/terminal/`;
        const method = "GET";
        let data = await this.request(url, method);

        if (data && data.terminals) {
            this._isMounted && this.setState({terminals: data.terminals.map(terminal => ({value: terminal.id, name: `${terminal.cpoTerminalId} (${terminal.address} - ${terminal.city})`}))})
        }
    }

    async afterSubmission(event) {
        const { t } = this.props;
        event.preventDefault();
        let url = "/ticket/new";
        let method = "POST";
        let csrf = await this.getCsrfToken("/ticket/csrf");
        let body = JSON.stringify({
            ...(this.state.addticket.terminal_id && {terminal_id: this.state.addticket.terminal_id}),
            reason: this.state.addticket.reason,
            message: this.state.addticket.message,
            _csrf_token: csrf
        });

        this.setState({isLoading: true})

        let data = await this.request(url, method, body);
        if (data && data.status === "ok") {
            this.props.addToast(t('ticketAdded'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
            this.props.onClose()
        }

        this.setState({isLoading: false})
    }

    render() {
        const { t } = this.props;
        return (
            <LoadingOverlay active={this.state.isLoading} classNamePrefix="loader_" spinner text={t('loading')}>
                <Modal title={t('add_ticket')} open={true} onClose={this.props.onClose}>
                    <div className="edit__form primary-form">
                        {/* <h2 className="primary-form__title">{t('add_ticket')}</h2> */}
                        {/* <hr className="form-divider form-divider--big"/> */}
                        <form onSubmit={(e) => this.afterSubmission(e)}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <FormSelect
                                        label={t('ticket_reason')}
                                        name='reason'
                                        id='reason'
                                        options={reason_options}
                                        handle={(e) => this.handleInputChange(e, null, 'addticket')}
                                        value={this.state.addticket.reason}/>
                                </div>
                                <div className="col-sm-12">
                                    <FormSelect
                                        label={t('ticket_terminal')}
                                        name='terminal_id'
                                        id='terminal_id'
                                        options={this.state.terminals}
                                        handle={(e) => this.handleInputChange(e, null, 'addticket')}
                                        value={this.state.addticket.terminal_id}/>
                                </div>
                                <div className="col-sm-12">
                                    <FormTextArea
                                        placeholder={t('min_1_max_500')}
                                        required
                                        label={t("ticket_message")}
                                        type="text"
                                        name="message"
                                        id="message"
                                        handle={(e) => this.handleInputChange(e, null, 'addticket', this.checkFormValidity)}
                                        validation={validateTicketMessage}
                                        value={this.state.addticket.message}/>
                                </div>
                            </div>
                            
                            <hr className="form-divider form-divider--big"/>
                            <div className="primary-form__buttons">
                                <button type="button" onClick={() => this.props.onClose()} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-bleu-a3">{t('cancel')}</button>
                                <button type="submit" disabled={!this.state.formIsValid} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('Save')}</button>
                            </div>
                            <p className="primary-form__required-fields">* {t('required_fields')}</p>
                            <p className="primary-form__required-fields">
                                <br/>{t('contact_vnf')}: {process.env.REACT_APP_VNF_CONTACT}
                                <br/>{t('contact_haropa')}: {process.env.REACT_APP_HAROPA_CONTACT}
                            </p>
                        </form>
                        </div>
                </Modal>
            </LoadingOverlay>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(CreateTicket)), CreateTicket));
