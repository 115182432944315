import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import { FormInput, PhoneInput, FileInput } from 'components/FormComponents';
import { REGEX, validateEmail, validateENI, validatePdf } from '../../helpers/validation';
import Modal from 'components/Modal';
import LoadingOverlay from 'react-loading-overlay';

class EditModal extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            editship: {
                eni: "",
                name: "",
                email: "",
                phone: "",
                certificate_file: false,
                vat_exemption_file: false
            },
            files:{
                certificate_file: false,
                vat_exemption_file: false
            },
            loading: true,
            formIsValid: false
        }
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.getShip(this.props.ship.id);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async getFiles(id) {
        let url = "/boat/" + id + "/files";
        let method = "GET";
        let files = await this.request(url, method);

        return files;
    }

    async getShip(id) {
        let url = "/boat/" + id;
        let method = "GET";
        let data = await this.request(url, method);
        if (data && data.boat) {
            let files = {};
            if (data.boat.certificate_file || data.boat.vat_exemption_file)
                files = await this.getFiles(id);

            this._isMounted && this.setState(prevState => ({
                editship: {
                    ...prevState.editship,
                    eni: data.boat.eni,
                    name: data.boat.name,
                    email: data.boat.email,
                    phone: data.boat.phone,
                    certificate_file: data.boat.certificate_file,
                    vat_exemption_file: data.boat.vat_exemption_file
                },
                files: {
                    ...prevState.files,
                    certificate_file: (files.certificate) ? {label: 'see_certificate_file', url: files.certificate} : false,
                    vat_exemption_file: (files.vat_exemption) ? {label: 'see_certificate_file', url: files.vat_exemption} : false
                },
                loading: false
            }), () => this.checkFormValidity());
        }
        else {
            this.setState({ loading: false });
        }
    }

    checkFormValidity = () => {
        this.setState({formIsValid: (
            new RegExp(REGEX.ENI).test(this.state.editship.eni) &&
            new RegExp(REGEX.email).test(this.state.editship.email)
        )})
    }

    async afterSubmission(event) {
        const { t } = this.props;
        event.preventDefault();

        let url = "/boat/" + this.props.ship.id;
        let method = "POST";
        let csrf = await this.getCsrfToken("/boat/csrf");

        let certificate_file = document.getElementById('certificate_file').files[0];
        let vat_exemption_file = document.getElementById('vat_exemption_file').files[0];
        let body = new FormData();
        let boat = JSON.stringify({
            eni: this.state.editship.eni,
            name: this.state.editship.name,
            email: this.state.editship.email,
            phone: this.state.editship.phone
        });
        body.append("boat", boat);
        body.append("_csrf_token", csrf);

        if (certificate_file)
            body.append("certificate_file", certificate_file);
        if (vat_exemption_file)
            body.append("vat_exemption_file", vat_exemption_file);

        let data = await this.request(url, method, body, true);
        if (data && data.status === "ok") {
            this.props.addToast(t('shipModified'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
            this.props.onClose()
        }
    }

    render() {
        const { t } = this.props;
        return (<>
            <Modal
                title={t('edit_ship')}
                open={true}
                onClose={this.props.onClose}>
                <div className="primary-form">
                    <form onSubmit={(e) => this.afterSubmission(e)}>
                        <div className="row">
                            <div className="col-sm-12">
                                <FormInput
                                    label={t("eni")}
                                    type="text"
                                    name="eni"
                                    id="eni"
                                    validation={validateENI}
                                    handle={(e) => this.handleInputChange(e, null, 'editship', this.checkFormValidity)}
                                    value={this.state.editship.eni}/>
                            </div>
                            <div className="col-sm-12">
                                <FormInput
                                    label={t("ship_name")}
                                    type="text"
                                    name="name"
                                    id="name"
                                    handle={(e) => this.handleInputChange(e, null, 'editship', this.checkFormValidity)}
                                    value={this.state.editship.name}/>
                            </div>
                            <div className="col-sm-12">
                                <FormInput
                                    label={t("ship_email")}
                                    type="email"
                                    name="email"
                                    id="email"
                                    validation={validateEmail}
                                    handle={(e) => this.handleInputChange(e, null, 'editship', this.checkFormValidity)}
                                    value={this.state.editship.email}/>
                            </div>
                            <div className="col-sm-12">
                                <PhoneInput
                                    required
                                    label={t("ship_phone")}
                                    name="phone"
                                    id="phone"
                                    value={this.state.editship.phone}
                                    handle={phone => this.setState(prevState => ({ editship: { ...prevState.editship, phone: phone } }))}/>
                            </div>
                            <div className="col-md-6">
                                <FileInput
                                    required
                                    id="certificate_file"
                                    name="certificate_file"
                                    validation={validatePdf}
                                    label={t('community_certificate_instruction')}
                                    link={this.state.files.certificate_file}
                                    handle={(e) => console.log(e)}
                                    onDelete={() => console.log('delete')}/>
                            </div>
                            <div className="col-md-6">
                                <FileInput
                                    required
                                    id="vat_exemption_file"
                                    name="vat_exemption_file"
                                    validation={validatePdf}
                                    label={t('vat_exemption_file_instruction')}
                                    link={this.state.files.vat_exemption_file}
                                    handle={(e) => console.log(e)}
                                    onDelete={() => console.log('delete')}/>
                            </div>
                        </div>
                        
                        <hr className="form-divider form-divider--big"/>
                        <div className="primary-form__buttons">
                            <button type="button" onClick={this.props.onClose} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-bleu-a3">{t('cancel')}</button>
                            <button type="submit" disabled={!this.state.formIsValid} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('Save')}</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <LoadingOverlay active={this.state.loading} classNamePrefix="loader_" spinner text={t('loading')}></LoadingOverlay>
        </>)
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(EditModal)), EditModal));
