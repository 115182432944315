import CustomComponent from 'components/customComponent';
//import { withRouter, Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import LoadingOverlay from 'react-loading-overlay';
import Input from 'components/input';
import { withToast } from '../../App';
import { DefaultLayout } from 'components/Layouts';


class EditTicket extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            editticket: {
                terminal_id: "",
                reason: "",
                message: ""
            },
            isActive: true
        }
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.checkIsConnectedPWA()) {
            //Load localstorage value
            //this.loadStorageValues(['editticket'], [this.state.editticket]);
            let id = 0;
            if (this.props.match.params.id)
                id = this.props.match.params.id;
            this._isMounted && this.getTicket(id);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    async getTicket(id) {
        let url = "/ticket/" + id;
        let method = "GET";
        let data = await this.request(url, method);
        if (data && data.ticket) {

            this._isMounted && this.setState(prevState => ({
                editticket: {
                    ...prevState.editticket,
                    reason: data.ticket.reason,
                    message: data.ticket.message,
                },
                isActive: false
            }));
        }
        else {
            this.setState({ isActive: false });
        }
    }

    async afterSubmission(event) {
        const { t } = this.props;
        event.preventDefault();
        let id = 0;
        if (this.props.match.params.id)
            id = this.props.match.params.id;
        let url = "/ticket/" + id;
        let method = "POST";
        let csrf = await this.getCsrfToken("/ticket/csrf");
        let body = JSON.stringify({
            reason: this.state.editticket.reason,
            message: this.state.editticket.message,
            _csrf_token: csrf
        });

        let data = await this.request(url, method, body);
        if (data && data.status === "ok") {
            this.props.addToast(t('ticketModified'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
        }
    }

    render() {
        const { t } = this.props;
        return (
            <DefaultLayout>
                <LoadingOverlay
                    active={this.state.isActive}
                    classNamePrefix="loader_"
                    spinner
                    text={t('loading')}
                >
                    <h1>{t("tickets")}</h1>
                    <form onSubmit={(e) => this.afterSubmission(e)}>
                        {/* <Input title={t("eni")} type="text" name="eni" id="eni" handle={(e) => this.handleInputChange(e, null, 'editticket')} value={this.state.editticket.eni} /> */}
                        <Input title={t("ticket_reason")} type="text" name="reason" id="reason" handle={(e) => this.handleInputChange(e, null, 'editticket')} value={this.state.editticket.reason} />
                        <Input title={t("ticket_message")} type="text" name="message" id="message" handle={(e) => this.handleInputChange(e, null, 'editticket')} value={this.state.editticket.message} />
                        <input type="submit" name="save" value={t("Save")} />
                    </form>
                </LoadingOverlay>
            </DefaultLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(EditTicket)), EditTicket));
