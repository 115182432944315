import CustomComponent from 'components/customComponent';
import { DefaultLayout } from 'components/Layouts';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
// import Statistics from '../../components/Statistics';
import logoBorneEtEaux from '../../assets/img/logo-borne_et_eaux.png';
import {TabContainer, TabItem} from '../../components/Tabs';
import { AvailabilityChart, DistributionChart, ConsumptionChart } from './_charts';

class Home extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            filter: {
                search: ''
            },
            stats: {
            },
            isActive: true,
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.checkIsConnectedPWA()) {
            this._isMounted && this.getStats();
        }
    }


    async getStats() {
        this.setState({isActive: true})
        let url = `/dashboard/stats/`;
        if (this.state.filter.search !== '') url += `?q=${this.state.filter.search}`
        const method = "GET";
        let data = await this.request(url, method);

        if (data && data.terminals) {

            this.setState(prevState => ({
                stats: {
                    ...prevState.stats,
                    list: data.stats,
                },
                isActive: false,
                recap: {
                    ...prevState.recap,
                    terminals: [
                        data.count.Available || 0,
                        data.count.Occupied || 0,
                        data.count.Unavailable || 0
                    ]
                }
            }), () => {
                localStorage.setItem('terminal', JSON.stringify(this.state.terminal));
            })
        }
    }

    render() {
        const {t} = this.props

        return (
            <DefaultLayout>
                <div className="homepage-logo" >
                    <img src={logoBorneEtEaux} alt="logo Borne et Eau" />
                </div>
                <div className="row">
                    <div className="col-xl-9">
                        <section className="section section--green">
                            <div className="section__header">
                                <h1 className="section__title section__title--green">{t('electric_terminal')}</h1>
                            </div>
                            <div className="container section__container">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="section-card">
                                            <AvailabilityChart fluidType={'Electricity'} title={t('electric_connectors')} description={t('electric_connectors_state')}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="section-card">
                                            <TabContainer id="electric_consumption-tabs" color="green">
                                                <TabItem title={t('distribution')}>
                                                    <DistributionChart fluidType={'Electricity'} title={t('electric_distribution')} description={t('electric_distribution_state')}/>
                                                </TabItem>
                                                <TabItem title={t('consumption')}>
                                                    <ConsumptionChart fluidType={'Electricity'} title={t('electric_consumption')} description={t('electric_consumption_state')}/>
                                                </TabItem>
                                            </TabContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section section--blue">
                            <div className="section__header">
                                <h1 className="section__title">{t('water_terminal')}</h1>
                            </div>
                            <div className="container section__container">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="section-card">
                                            <AvailabilityChart fluidType={'Water'} title={t('water_connectors')} description={t('water_connectors_state')}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="section-card">
                                            <TabContainer id="water_consumption-tabs">
                                                <TabItem title={t('distribution')}>
                                                    <DistributionChart fluidType={'Water'} title={t('water_distribution')} description={t('water_distribution_state')}/>
                                                </TabItem>
                                                <TabItem title={t('consumption')}>
                                                    <ConsumptionChart fluidType={'Water'} title={t('water_consumption')} description={t('water_consumption_state')}/>
                                                </TabItem>
                                            </TabContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="col-xl-3">
                        <section className="section-shortcuts">
                            <h1 className="section-shortcuts__title">{t('shortcuts')}</h1>
                            <Link className="section-shortcuts__link" to="/terminal">{t('terminals_state')}</Link>
                            <Link className="section-shortcuts__link" to="/transaction">{t('transaction_history')}</Link>
                        </section>
                    </div>
                </div>
                {/* <Statistics /> */}
            </DefaultLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(Home)), Home));