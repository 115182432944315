import { useState, useEffect } from "react"
import { CSSTransition } from "react-transition-group"


const Modal = ({open, title, description, children, onClose, size="md", handle, disableClose = false}) => {
    const [isOpen, setOpen] = useState(false)

    const onClick = () => {
        !disableClose && setOpen(false)
        handle && handle(false)
        onClose && onClose()
    }

    useEffect( () => setOpen(open), [open])

    return (
        <CSSTransition in={isOpen} timeout={250}>
            <div className="modal">
                <div className={`modal__content modal__content--${size}`}>
                    {!disableClose && (
                        <button type="button" className="modal__close" onClick={onClick}>
                            <span className="sr-only">Fermer la modale</span>
                            <i className="vnf-icons-icon-m-croix-fermer-outline"></i>
                        </button>
                    )}
                    <div className="modal__header">
                        {title && <h2 className="modal__title">{title}</h2>}
                        {description && <p className="modal__description">{description}</p>}
                    </div>
                    <div className="modal__body">
                        {children}
                    </div>
                </div>
                <div className="modal__overlay" onClick={onClick}></div>
            </div>
        </CSSTransition>
    )
}

export default Modal