import CustomComponent from 'components/customComponent';
//import { withRouter, Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import moment from 'moment';
import { withToast } from '../../App';
import Modal from 'components/Modal';
import { FormTextArea } from 'components/FormComponents';
import { SelectFilter } from 'components/Filters';
import LoadingOverlay from 'react-loading-overlay';
import { matchRoles } from 'helpers/helpers';
import { validateTicketMessage } from 'helpers/validation';

const status_options = ['open', 'waiting_answer', 'closed']
        .map(s => ({value: s, name: 'ticket_' + s}))

class EditTicket extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            showticket: {
                status: this.props.ticket.status,
                terminal: this.props.ticket.terminal,
                reason: this.props.ticket.reason,
                message: this.props.ticket.message,
                comments: this.props.ticket.comments,
                comment: "",
            },
            formIsValid: false,
            isLoading: false,
        }
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    checkFormValidity = () => {
        this.setState({formIsValid: (
            this.state.showticket.comment.length > 0 &&
            this.state.showticket.comment.length <= 500
        )})
    }

    async afterSubmissionSendComment(event) {
        const { t } = this.props;
        event.preventDefault();

        this.setState({isLoading: true})

        let url = "/ticket/" + this.props.ticket.id + "/comment";
        let method = "POST";
        let csrf = await this.getCsrfToken("/ticket/csrf");
        let body = JSON.stringify({
            message: this.state.showticket.comment,
            _csrf_token: csrf
        });

        let data = await this.request(url, method, body);
        if (data && data.status === "ok") {
            this.props.addToast(t('ticket_comment_sent'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
            this.props.onClose()
        }

        this.setState({isLoading: false})
    }

    async updateStatus () {
        const { t } = this.props;

        let url = "/ticket/" + this.props.ticket.id + '/status'
        let method = 'POST'
        let csrf = await this.getCsrfToken("/ticket/csrf");
        let body = JSON.stringify({
            status: this.state.showticket.status,
            _csrf_token: csrf
        });

        this.setState({isLoading: true})
        let data = await this.request(url, method, body);
        if (data && data.ticket) {
            this._isMounted && this.setState(prevState => ({
                showticket: {
                    ...prevState.showticket,
                    status: data.ticket.status
                },
            }))
            this.props.addToast(t('ticket_status_updated'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
        }
        this.setState({isLoading: false})
    }

    render() {
        const { t, ticket } = this.props;

        return (
            <LoadingOverlay active={this.state.isLoading} classNamePrefix="loader_" spinner text={t('loading')}>
                <Modal title={t('ticket_details')} open={true} onClose={this.props.onClose}>
                    <div className="edit__form primary-form">
                    {/* <h2 className="primary-form__title">{t('ticket_details')}</h2> */}
                    {/* <hr className="form-divider form-divider--big"/> */}
                    <div className="tickets__header">
                        <ul className="tickets__info">
                            <li>
                                <span>{t('terminal')}: </span>
                                <span>{ticket.terminal && `${ticket.terminal.cpoTerminalId} ((${ticket.terminal.address} - ${ticket.terminal.city}))`}</span>
                            </li>
                            <li>
                                <span>{t('subject')}: </span>
                                <span>{t(ticket.reason)}</span>
                            </li>
                        </ul>
                        {matchRoles(this.loadRoles(), ['ROLE_ADMIN']) && (
                            <SelectFilter
                                id="status"
                                name="status"
                                label={t('status')}
                                showLabel
                                value={this.state.showticket.status}
                                handle={(e) => this.handleInputChange(e, null, 'showticket', () => this.updateStatus())}
                                options={status_options}/>
                        )}
                    </div>
                    <ul className="tickets__comment-list">
                        <li className="tickets__comment">
                            <h4 className="tickets__author" >{ticket.author.lastName} {ticket.author.firstName}</h4>
                            <span className="tickets__date">{moment.utc(ticket.created_at.date).format(t('DATE_HOURS_FORMAT'))}</span>
                            <p className="tickets__message" >{this.state.showticket.message}</p>
                        </li>
                        {ticket.comments.map((comment, index) => (
                            <li key={index} className="tickets__comment">
                                <h4 className="tickets__author" >{comment.author.lastName} {comment.author.firstName}</h4>
                                <span className="tickets__date">{moment.utc(comment.date.date).format(t('DATE_HOURS_FORMAT'))}</span>
                                <p className="tickets__message" >{comment.message}</p>
                            </li>
                        ))}
                    </ul>

                    <form onSubmit={(e) => this.afterSubmissionSendComment(e)}>
                        <div className="row">
                            <div className="col-12">
                                <FormTextArea
                                    required
                                    placeholder={t('min_1_max_500')}
                                    label={t("ticket_message")}
                                    type="text"
                                    name="comment"
                                    id="comment"
                                    handle={(e) => this.handleInputChange(e, null, 'showticket', this.checkFormValidity)}
                                    validation={validateTicketMessage}
                                    value={this.state.showticket.comment}/>
                            </div>
                        </div>
                        <hr className="form-divider form-divider--big mt-4"/>
                        <div className="primary-form__buttons">
                            <button type="button" onClick={() => this.props.onClose()} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-bleu-a3">{t('cancel')}</button>
                            <button type="submit" disabled={!this.state.formIsValid} className="ml-auto vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('reply')}</button>
                        </div>
                        <p className="primary-form__required-fields">* {t('required_fields')}</p>
                        <p className="primary-form__required-fields">
                            <br/>{t('contact_vnf')}: {process.env.REACT_APP_VNF_CONTACT}
                            <br/>{t('contact_haropa')}: {process.env.REACT_APP_HAROPA_CONTACT}
                        </p>
                    </form>
                    </div>
                </Modal>
            </LoadingOverlay>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(EditTicket)), EditTicket));
