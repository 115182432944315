import {Tabs, Tab} from '@material-ui/core'
import { useState } from 'react';
import useWindowDimensions from 'hooks/useWindowDimensions';

export const TabItem = (props) => <>{props.children}</>

export const TabContainer = ({children, id, color="blue"}) => {
    const [selected, setSelected] = useState(0)
    const {width} = useWindowDimensions()

    return (
        <div className={`tabs tabs--${color}`}>
            <Tabs className="tabs__list" value={selected} onChange={(_,value) => setSelected(value)} variant={width > 992 ? 'fullWidth' : 'scrollable'}>
                {children.map((child, index) => (
                    <Tab
                        key={index}
                        className="tabs__item"
                        disabled={child.props.disabled}
                        label={child.props.title}
                        id={`${id}-tab-${index}`}
                        aria-controls={`${id}-tabpanel-${index}`}/>
                ))}
            </Tabs>
            {children.map((child, index) => <TabContent key={index} value={selected} index={index} id={id}>{child}</TabContent>)}
        </div>
    )
}

const TabContent = ({children, value, index, id}) => {
    return (
        <div
            className={`tabs__panel${value === index ? ' open' : ''}`}
            role="tabpanel"
            id={`${id}-tabpanel-${index}`}
            aria-labelledby={`${id}-tab-${index}`}>
            {children}
        </div>
    )
}