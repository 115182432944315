import { useState, useEffect } from 'react';
import { useTour } from "@reactour/tour"
import { useTranslation } from 'react-i18next';
import { REACT_TOUR_STEPS } from '../config';
import Modal from './Modal';
import { matchRoles } from 'helpers/helpers';
import Cookies from 'js-cookie';


const TourManager = ({openMenu, roles}) => {
    const {t} = useTranslation()
    const Tour = useTour()
    const [openModal, setOpenModal] = useState(false)

    const startTour = () => {
        const {setSteps, setCurrentStep, setIsOpen} = Tour
        // 1 Set Steps according to user role
        setSteps(REACT_TOUR_STEPS
            .filter(step => matchRoles(roles, step.roles))
            .map(step => ({
                ...step,
                content: <div className="reactour__content" dangerouslySetInnerHTML={{__html: t(step.content, {interpolation: {escapeValue: false}})}}></div>
            }))
        )
        setCurrentStep(0)
        // 2 Close Modal && add cookie
        setOpenModal(false)
        // 3 Add Cookie
        Cookies.set('SkipTour', true, {expires: 365})
        // 4 Start Tour
        setTimeout(() => setIsOpen(true), 500)
    }

    const skipTour = () => {
        Cookies.set('SkipTour', true, {expires: 365})
        setOpenModal(false)
    }

    useEffect(() => {
        if (!Cookies.get('SkipTour')) setOpenModal(true)
    }, [setOpenModal])

    useEffect(() => {
        const {steps, currentStep} = Tour
        const step = steps[currentStep]
        if (!step) return
        
        if (step.navigationOpen) {
            openMenu(true)
        } else {
            openMenu(false)
        }
    }, [Tour, openMenu])
    
    return (
        <Modal
            open={openModal}
            title={t('tour_title')}
            description={t('tour_description')}>
            <div className="modal__buttons modal__buttons--center">
                <button className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-orange-a3 vnf-btn-box-shadow-orange-a4" onClick={skipTour}>{t('tour_skip')}</button>
                <button className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4"  onClick={startTour}>{t('tour_start')}</button>
            </div>
        </Modal>
    )
}

export default TourManager