import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import { DefaultLayout } from 'components/Layouts';
import { TabContainer, TabItem } from 'components/Tabs'
import PDFViewer from 'components/PDFViewer';

class Procedures extends CustomComponent {
    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    componentDidMount () {
        this._isMounted = true;
    }

    render () {
        const {t} = this.props
        const PATH = `${process.env.PUBLIC_URL}/pdf/${this.props.i18n.language}`

        return (
            <DefaultLayout>
                <h1 className="main-title">{t('procedures')}</h1>
                <TabContainer id="procedures-tabs">
                    <TabItem title={t('cgu')}>
                        <PDFViewer file={`${PATH}/cgu.pdf`} title={t('cgu')} />
                    </TabItem>
                    <TabItem title={t('borne_et_eau_explanatory')}>
                        <PDFViewer file={`${PATH}/how_to.pdf`} title={t('borne_et_eau_explanatory')} />
                    </TabItem>
                    <TabItem title={t('user_guide')}>
                        <PDFViewer file={`${PATH}/user_guide.pdf`} title={t('user_guide')} />
                    </TabItem>
                    <TabItem title={t('remote_startup_note')}>
                        <PDFViewer file={`${PATH}/remote_startup_note.pdf`} title={t('remote_startup_note')} />
                    </TabItem>
                </TabContainer>
            </DefaultLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(Procedures)), Procedures));