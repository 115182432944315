import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import EditPassword from './edit_password'
import { DefaultLayout } from 'components/Layouts';
import { REGEX, validatePdf } from 'helpers/validation';
import { FormInput, PhoneInput, FormCheckBox, FormPermission } from 'components/FormComponents';
import { FileInput } from '../../components/FormComponents';
import { matchRoles } from 'helpers/helpers';


class Account extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            account: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                company: {
                    address: '',
                    city: '',
                    companyIdentifier: '',
                    country: '',
                    email: '',
                    name: '',
                    phone: '',
                },
                created_at: "",
                roles: [],
                notify_armateur_queries: false,
                permissions: []
            },
            files: {
                kbis: "",
                rib: ""
            },
            entities: [],
            roles: [],
            userFormIsValid: true,
            companyFormIsValid: true
        };
        this._isMounted = false;
    }

    checkUserFormValidity () {
        this.setState({userFormIsValid: (
            new RegExp(REGEX.email).test(this.state.account.email) &&
            new RegExp(REGEX.name).test(this.state.account.firstName) &&
            new RegExp(REGEX.name).test(this.state.account.lastName)
        )})
    }

    async getFiles() {
        let url = "/company/files";
        let method = "GET";
        let files = await this.request(url, method);
        return files;
    }

    async getEntities() {
        let url = "/entity/list/all";
        let method = "GET";
        let entities = await this.request(url, method);
        return entities;
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.checkIsConnectedPWA()) {
            //Load localstorage value
            this.loadStorageValues(
                ['account', 'files', 'entities'],
                [this.state.account, this.state.files,
                this.state.entities]
            );
            this._isMounted && this.setState({ roles: this.loadRoles() });
            this._isMounted && this.getAccount();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async getAccount() {
        let url = "/user/";
        let method = "GET";
        let data = await this.request(url, method);
        if (data && data.user) {
            // get files if exist
            let files = {};
            if (data.user.company && (data.user.company.kbis_file || data.user.company.rib_file)) {
                files = await this.getFiles();
            }
            let entities = await this.getEntities();
            this._isMounted && this.setState(prevState => ({
                account: {
                    ...prevState.account,
                    firstName: data.user.firstName || "",
                    lastName: data.user.lastName || "",
                    email: data.user.email,
                    phone: data.user.phone || prevState.account.phone,
                    roles: data.user.roles,
                    company: data.user.company || prevState.account.company,
                    notify_armateur_queries: data.user.notify_armateur_queries || false,
                    permissions: data.user.permissions || prevState.account.permissions,
                    entities: data.user.entities,
                },
                files: {
                    ...prevState.files,
                    kbis: (files.kbis) ? {label: 'see_kbis_file', url: files.kbis} : false,
                    rib: (files.kbis) ? {label: 'see_rib_file', url: files.rib} : false,
                },
                entities: entities.entities
            }), () => {
                localStorage.setItem('account', JSON.stringify(this.state.account));
                localStorage.setItem('files', JSON.stringify(this.state.files));
                localStorage.setItem('entities', JSON.stringify(this.state.entities));
            });
        }
    }

    async afterSubmissionInfo(event) {
        const { t } = this.props;
        event.preventDefault();
        let url = "/user/";
        let method = "POST";
        let csrf = await this.getCsrfToken("/user/csrf");
        let body = JSON.stringify({
            user: {
                firstName: this.state.account.firstName,
                lastName: this.state.account.lastName,
                email: this.state.account.email,
                phone: this.state.account.phone,
                notify_armateur_queries: this.state.account.notify_armateur_queries
            },
            _csrf_token: csrf
        });
        let data = await this.request(url, method, body);
        if (data && data.status === "ok") {
            this.props.addToast(t('Settings successfully changed'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
            localStorage.setItem('account', JSON.stringify(this.state.account));
        }
    }


    async afterSubmissionCompany(event) {
        const { t } = this.props;
        event.preventDefault();
        let url = "/user/";
        let method = "POST";
        let csrf = await this.getCsrfToken("/user/csrf");
        let rib = document.querySelectorAll('input[type="file"]')[0].files[0];
        let kbis = document.querySelectorAll('input[type="file"]')[1].files[0];
        let body = new FormData();
        let user = {
            company: {
                address: this.state.account.company.address,
                city: this.state.account.company.city,
                companyIdentifier: this.state.account.company.companyIdentifier,
                country: this.state.account.company.country,
                email: this.state.account.company.email,
                name: this.state.account.company.name,
                phone: this.state.account.company.phone,
                // ...(rib && { file_rib: rib }),
                // ...(kbis && { file_kbis: kbis })
            }
        };
        body.append("user", JSON.stringify(user));
        if (rib)
            body.append("rib_file", rib);
        if (kbis)
            body.append("kbis_file", kbis);
        body.append("_csrf_token", csrf);
        
        let data = await this.request(url, method, body, true);
        if (data && data.status === "ok") {
            let files = await this.getFiles();
            this.setState(prevState => ({
                files: {
                    ...prevState.files,
                    kbis: (files.kbis) ? files.kbis : "",
                    rib: (files.rib) ? files.rib : ""
                }
            }), () => {
                localStorage.setItem('files', JSON.stringify(this.state.files));
            });
            this.props.addToast(t('Settings successfully changed'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
            localStorage.setItem('account', JSON.stringify(this.state.account));
            document.querySelectorAll('input[type="file"]').forEach(element =>
                element.value = ""
            );
        }
    }

    async onClick(event) {
        const { t } = this.props;
        let target = event.target;
        let id = null;
        this.state.entities.forEach(key => {
            if (key.name === target.name)
                id = key.id;
        });
        let url = "/entity/ask";
        let method = "POST";
        let csrf = await this.getCsrfToken("/entity/csrf");
        let body = JSON.stringify({
            entity_id: id,
            _csrf_token: csrf
        });
        let data = await this.request(url, method, body);
        if (data && data.status === "ok") {
            this.setState(prevState => {
                let account = Object.assign({}, prevState.account);
                account.permissions.push({ entity: { id: id, name: target.name }, grant_status: "waiting" });
                return { account: account };
            });
            this.props.addToast(t('authorization_request_sent'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
            localStorage.setItem('account', JSON.stringify(this.state.account));
        }
    }

    render() {
        const { t } = this.props;

        let arr_entities_permissions = [];
        this.state.entities.forEach(entity => {
            let grant = null;
            let entity_value = t('entity_permission_request');
            if (this.state.account.permissions) {
                this.state.account.permissions.forEach(permission => {
                    if (permission.entity.id === entity.id && permission.grant_status) {
                        grant = permission.grant_status;
                    }
                });
                switch (grant) {
                    case "waiting":
                        entity_value = t('entity_permission_waiting');
                        break;
                    case "allowed":
                        entity_value = t('entity_permission_accepted');
                        break;
                    case "denied":
                        entity_value = t('entity_permission_denied');
                        break;
                    default:
                        entity_value = t('entity_permission_request');
                }
            }
            arr_entities_permissions.push({entity, grant, entity_value})
        });

        return (
            <DefaultLayout>
                <section className="section section--blue">
                    <div className="section__header">
                        <h1 className="section__title section__title--blue">{t('Account')}</h1>
                    </div>
                    <div className="container section__container">
                        <div className="row">
                            <div className="col-lg-6 mb-4">
                                <div className="section-card">
                                    <div className="section-card__container">
                                        <div className="edit__form primary-form primary-form--no-padding">
                                            <h2 className="primary-form__title">{t('Settings')}</h2>
                                            <hr className="form-divider form-divider--big"/>
                                            <form onSubmit={(e) => this.afterSubmissionInfo(e)}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <FormInput
                                                            label={t("Your first name")}
                                                            type="text"
                                                            name="firstName"
                                                            id="firstName"
                                                            required
                                                            handle={(e) => this.handleInputChange(e, null, 'account', () => this.checkUserFormValidity())}
                                                            value={this.state.account.firstName} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <FormInput
                                                            label={t("Your last name")}
                                                            type="text"
                                                            name="lastName"
                                                            id="lastName"
                                                            required
                                                            handle={(e) => this.handleInputChange(e, null, 'account', () => this.checkUserFormValidity())}
                                                            value={this.state.account.lastName} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <FormInput
                                                            label={t("Your email address")}
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            required
                                                            handle={(e) => this.handleInputChange(e, null, 'account', () => this.checkUserFormValidity())}
                                                            value={this.state.account.email} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <PhoneInput
                                                            id="phone"
                                                            name="phone"
                                                            label={t("Phone number")}
                                                            value={this.state.account.phone}
                                                            handle={phone => this.setState(prevState => ({account: { ...prevState.account, phone: phone }}))}/>
                                                    </div>
                                                    {(matchRoles(this.state.account.roles, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_SUPER_COMPTABLE'])) && (
                                                    <div className="col-md-12">
                                                        <FormCheckBox
                                                            label={t("receive_notifications")}
                                                            type="checkbox"
                                                            name="notify_armateur_queries"
                                                            id="notify_armateur_queries"
                                                            handle={(e) => this.handleInputChange(e, null, 'account')}
                                                            value={this.state.account.notify_armateur_queries} />
                                                    </div>
                                                    )}
                                                </div>
                                                <hr className="form-divider form-divider--big"/>
                                                <div className="primary-form__buttons primary-form__buttons--right">
                                                    <button type="submit" disabled={!this.state.userFormIsValid} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('Save')}</button>
                                                </div>
                                                <p className="primary-form__required-fields">* {t('required_fields')}</p>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-4">
                                <div className="section-card">
                                    <div className="section-card__container">
                                        <EditPassword noPadding />
                                    </div>
                                </div>
                            </div>
                            {(matchRoles(this.state.account.roles, ['ROLE_ARMATEUR'])) && (
                            <div className="col-lg-6 mb-4">
                                <div className="section-card">
                                    <div className="section-card__container">
                                        <div className="edit__form primary-form primary-form--no-padding">
                                            <h2 className="primary-form__title">{t('company_information')}</h2>
                                            <hr className="form-divider form-divider--big"/>
                                            <form onSubmit={(e) => this.afterSubmissionCompany(e)}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <FormInput
                                                            label={t("company_name")}
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            handle={(e) => this.handleInputChange(e, null, ['account', 'company'])}
                                                            value={this.state.account.company.name}/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <FormInput
                                                            label={t("company_identifier")}
                                                            type="text"
                                                            name="companyIdentifier"
                                                            id="companyIdentifier"
                                                            handle={(e) => this.handleInputChange(e, null, ['account', 'company'])}
                                                            value={this.state.account.company.companyIdentifier} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <FormInput
                                                            label={t("company_email")}
                                                            type="email"
                                                            name="email"
                                                            id="email2"
                                                            handle={(e) => this.handleInputChange(e, null, ['account', 'company'])}
                                                            value={this.state.account.company.email} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <FormInput
                                                            label={t("address")}
                                                            type="text"
                                                            name="address"
                                                            id="address"
                                                            handle={(e) => this.handleInputChange(e, null, ['account', 'company'])}
                                                            value={this.state.account.company.address} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <FormInput
                                                            label={t("city")}
                                                            type="text"
                                                            name="city"
                                                            id="city"
                                                            handle={(e) => this.handleInputChange(e, null, ['account', 'company'])}
                                                            value={this.state.account.company.city} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <FormInput
                                                            label={t("country")}
                                                            type="text"
                                                            name="country"
                                                            id="country"
                                                            handle={(e) => this.handleInputChange(e, null, ['account', 'company'])}
                                                            value={this.state.account.company.country} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <PhoneInput
                                                            label={t("Phone number")}
                                                            containerClass="phone-input"
                                                            value={this.state.account.company.phone}
                                                            placeholder=""
                                                            onChange={
                                                                phone => this.setState(prevState => ({
                                                                    account: { ...prevState.account, company: { ...prevState.account.company, phone: phone } }
                                                                }))
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <hr className="form-divider" />
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <FileInput
                                                            required
                                                            id="rib"
                                                            name="file"
                                                            validation={validatePdf}
                                                            link={this.state.files.rib}
                                                            label={t('rib_instruction')}
                                                            handle={(e) => console.log(e)}/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <FileInput
                                                            required
                                                            id="kbis"
                                                            name="file"
                                                            validation={validatePdf}
                                                            link={this.state.files.kbis}
                                                            label={t('kbis_instruction')}
                                                            handle={(e) => console.log(e)}/>
                                                    </div>
                                                </div>
                                                <hr className="form-divider form-divider--big"/>
                                                <div className="primary-form__buttons primary-form__buttons--right">
                                                    <button type="submit" disabled={!this.state.companyFormIsValid} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('Save')}</button>
                                                </div>
                                                <p className="primary-form__required-fields">* {t('required_fields')}</p>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )}
                            {matchRoles(this.state.account.roles, ['ROLE_ARMATEUR']) && (
                            <div className="col-lg-6 mb-4">
                                <div className="section-card">
                                    <div className="section-card__container">
                                        <div className="edit__form primary-form primary-form--no-padding">
                                            <h2 className="primary-form__title">{t('my_premissions')}</h2>
                                            <hr className="form-divider form-divider--big"/>
                                            <div id="authorization">
                                                <form onSubmit={(e) => this.afterSubmissionPermissions(e)}>
                                                    <div className="row">
                                                    {arr_entities_permissions.map(({entity, grant, entity_value}) => (
                                                        <div className="col-12">
                                                            <FormPermission
                                                                key={entity.id}
                                                                label={entity.name + " : "}
                                                                type="button"
                                                                name={entity.name}
                                                                id={entity.name}
                                                                {...(!grant && { onClick: (e) => this.onClick(e) })}
                                                                value={entity_value}
                                                                disabled={grant} />
                                                        </div>
                                                    ))}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                </section>
            </DefaultLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(Account)), Account));