const translationFR = {
    GENERIC_ERROR: 'Une erreur est survenue',
    "Email": "Email",
    "First name": "Prénom",
    "Last name": "Nom",
    "Full name": "Nom",
    "Roles": "Rôles",
    "Entity": "Entité",
    "Creation date": "Date de création",
    "Actions": "Actions",
    "Password": "Mot de passe",
    "Identify": "S'identifier",
    "First eletric terminals and water network": "Premier réseau de bornes électrique et eau",
    "A service developped by ...": "Un service développé par ...",
    "Login": "Se connecter",
    "Logout": "Deconnexion",
    "Account": "Mon compte",
    "Current password": "Mot de passe actuel",
    "New password": "Nouveau mot de passe",
    "Confirmation": "Confirmation",
    "Your first name": "Votre prénom",
    "Your last name": "Votre nom",
    "Your email address": "Votre adresse e-mail",
    "Phone number": "Numéro de téléphone",
    "Settings": "Informations personnelles",
    "Security": "Sécurité",
    "Save": "Enregistrer",
    "Passwords do not match": "Les mots de passe ne correspondent pas",
    "Password successfully changed": "Mot de passe changé avec succès",
    "Settings successfully changed": "Paramètres modifiés avec succès",
    "Connection successful": "Connexion réussie",
    "Logout successful": "Déconnexion réussie",
    "You must provide a password.": "Vous devez fournir un mot de passe.",
    "Your password is too short. It should have 12 characters or more.": "Votre mot de passe est trop court. Il doit avoir 12 caractères ou plus.",
    "Already connected": "Déjà connecté",
    'Invalid Credentials': "L'email ou le mot de passe est incorrect",
    "Your password must contain at least one uppercase character.": "Votre mot de passe doit contenir au moins un caractère majuscule.",
    "Your password must contain at least one lowercase character.": "Votre mot de passe doit contenir au moins un caractère minuscule.",
    "Your password must contain at least one special character.": "Votre mot de passe doit contenir au moins un caractère spécial.",
    "Your password must contain at least one number.": "Votre mot de passe doit contenir au moins un chiffre.",
    "This password has been leaked in a data breach, it must not be used. Please use another password.": "Ce mot de passe a été divulgué dans le cadre d'une violation de données, il ne doit pas être utilisé. Veuillez utiliser un autre mot de passe.",
    "Forgot your password ?": "Mot de passe oublié ?",
    "New ? Create an account": "Nouveau ? Créer un compte",
    "reset_password_title": "Un e-mail de réinitialisation de mot de passe valide pendant 30 minutes a été envoyé à l'adresse e-mail du compte.",
    "reset_password_message": "Si vous ne le recevez pas, vérifiez vos dossiers de courrier indésirable/pourriel/spam ou demandez de l'aide à votre administrateur local. Si ce n'est pas le cas, veuillez contacter votre administrateur.",
    "Create a shipowner account": "Créer un compte armateur",
    "remember_me": "Se souvenir de moi",
    "receive_notifications": "Recevoir les notifications des nouvelles demandes d'armateur",
    "company_information": "Informations de votre société",
    "Close notification": "Fermer la notification",
    skip_to_main: "Aller directement au contenu",
    page_not_found_title: "404",
    page_not_found_text: "Cette page n'existe pas",
    company_name: "Nom de votre société",
    company_identifier: "Identifiant société",
    company_email: "Email de facturation",
    address: "Adresse",
    city: "Ville",
    zipcode: "Code postal",
    country: "Pays",
    create_password: "Activation du compte",
    successful_activation: "Le compte a été activé avec succès",
    send: "Envoyer",
    my_premissions: "Mes autorisations",
    entity_permission_request: "Faire une demande avec mes informations actuelles",
    entity_permission_accepted: "Votre demande a été acceptée",
    entity_permission_waiting: "Votre demande est en attente",
    entity_permission_denied: "Votre demande a été refusée",
    authorization_request_sent: "Demande d'autorisation envoyée",
    Users: "Utilisateurs",
    go_back: "Retour",
    cancel: 'Annuler',
    edit_user: "Modifier l'utilisateur",
    add_user: "Ajouter un utilisateur",
    Edit: "Modifier",
    Delete: "Supprimer",
    deleteUserTitle: "Suppression d'utilisateur",
    deleteUserBody: "Voulez-vous vraiment supprimer cet utilisateur ?",
    deleteUserValidate: "Oui",
    deleteUserClose: "Non",
    userDeleted: "Utilisateur supprimé avec succès",
    userModified: "Utilisateur modifié avec succès",
    userCreated: "Utilisateur enregistré avec succès",
    offlineMsg: "Vous êtes hors ligne.",
    Disconnected: "Vous êtes déconnecté.",
    disconnectedMsg: "Vous êtes déconnecté.",
    loading: "Chargement...",
    Next: "Suivant",
    Previous: "Précédent",
    Register: "S'inscrire",
    HTML__accept_cgu: `Je reconnais avoir lu et accepté les présentes <a href="/pdf/fr/cgu.pdf" target="_blank" rel="noreferrer">CGU</a>`,
    acknowledged_warnings: "Je reconnais avoir pris connaissance des avertissements",
    "First step of registration successful": "Première étape d'inscription réussie",
    "Second step of registration successful": "Deuxième étape d'inscription réussie",
    "Third step of registration successful": "Troisième étape de l'inscription réussie",
    "Fourth step of registration successful": "Quatrième étape de l'enregistrement réussie",
    "Last step of registration successful": "Dernière étape d'inscription réussie. Un email de validation vient de vous être envoyé.",
    statistics: "Statistiques",
    history: "Historiques",
    my_ships: "Mes bateaux",
    // MENU
    home: "Accueil",
    menu: 'Menu',
    shortcuts: 'Raccourcis',
    close_menu: 'Fermer le menu',
    close_submenu: 'Fermer le sous-menu',
    home_statistics: "Accueil - Statistiques",
    terminals: "Bornes",
    transaction_history: "Historique des transactions",
    ships: "Bateaux",
    tickets: "Tickets",
    updates_note: 'Notes de Mises à jour',
    procedures: 'Procédures',
    administration: 'Administration',
        users: 'Utilisateurs',
        boat_owners: 'Armateurs',
        remote_operations: 'Opérations à distance',
            start_a_distribution: 'Démarrer une distribution',
            stop_a_distribution: 'Arrêter une distribution',
            reserve_a_connector: 'Réserver un connecteur',
            cancel_a_reservation: 'Annuler une réservation',
            restart_a_terminal: 'Redémarrer une borne',
    terminals_state: 'État des bornes',
    add_ticket: "Déposer un nouveau ticket",
    ticketAdded: "Ticket ajouté avec succès",
    permissions: "Mes autorisations",
    my_history: "Historiques des transactions",
    add_ship: "Ajouter un bateau",
    eni: "ENI (8 chiffres)",
    ship_name: "Nom du bateau",
    ship_email: "Email du bateau",
    ship_phone: "Téléphone du bateau",
    shipAdded: "Bateau ajouté avec succès",
    shipDeleted: "Bateau marqué avec succès comme vendu/démantelé",
    shipModified: "Bateau modifié avec succès",
    deleteShipTitle: "Etes-vous sûr de considérer ce bateau comme vendu/démantelé ?",
    deleteShipBody: "Une fois validée, cette action est irreversible ! Cependant, vous pourrez toujours accéder aux transactions effectuées par ce bateau avant la vente/le démantelement",
    deleteShipValidate: "J'atteste que ce bateau est vendu/démantelé",
    deleteShipClose: "Annuler",
    Show: "Consulter",
    reply: "Répondre",
    code: "Code",
    RegenerateCode: "Regénerer le code d'accès",
    shipCodeRegenerated: "Le code du bateau à été regénéré avec succès et envoyé par email",
    RegenerateShipCodeTitle: "Êtes-vous sûr de vouloir regénérer un code d'accès ?",
    RegenerateShipCodeBody: "Si vous validez, un email sera envoyé à l'email du bateau afin de communiquer le nouveau code d'accès",
    RegenerateShipCodeValidate: "Regénérer le code d'accès",
    RegenerateShipCodeClose: "Annuler",
    empty_input: 'Champ vide',
    invalid_input: 'Champ invalide',
    ROLE_USER: 'Utilisateur',
    ROLE_COMPTABLE: 'Comptable',
    ROLE_SUPER_COMPTABLE: 'Super comptable',
    ROLE_TECHNICIEN: 'Technicien',
    ROLE_ADMIN: 'Admin',
    ROLE_SUPER_ADMIN: 'Super admin',
    ROLE_ARMATEUR: 'Armateur',
    ROLE_NAVIGANT: 'Navigant',
    electric_terminal: "Borne electricité",
    electric_connectors: "Connecteurs d'électricité",
    water_terminal: "Borne eau",
    water_connectors: "Connecteurs d'eau",
    users_list: 'Liste des utilisateurs',
    boat_owners_list: 'Liste des armateurs',
    terminals_list: 'Liste des bornes',
    filter_user_placeholder: "Entrez un nom, une entité...",
    filter_terminal_placeholder: "Entrez un nom, une adresse...",
    available: 'Disponible',
    unavailable: 'Indisponible',
    charging: 'En charge',
    Charging: 'En charge',
    charging_points: 'Points de charges',
    show_on_map: 'Montrer sur la carte',
    go_to_previous_page: 'Aller à la page précédente',
    go_to_next_page: 'Aller à la page suivante',
    go_to_page: 'Aller à la page',
    go_to_first_page: 'Aller à la première page',
    go_to_last_page: 'Aller à la dernière page',
    transaction_id: 'N° transaction',
    place: 'Lieu',
    terminal: 'Borne',
    connector: 'Connecteur',
    connector_count: 'N° connecteur',
    connector_type: 'Type de connecteur',
    consumption: 'Consommation',
    electric_consumption: 'Consommation électrique',
    water_consumption: 'Consommation en eau',
    duration: 'Durée',
    connection: 'Branchement',
    disconnection: 'Débranchement',
    boat_owner: 'Armateur',
    boat: 'Bateau',
    pretax_amount: 'Montant HT',
    in_progress: 'En cours...',
    download: 'Télécharger',
    export_to_xls: 'Exporter au format .xls (excel)',
    connection_start_date: 'Date de début de débranchement',
    connection_end_date: 'Date de fin de débranchement',
    more_filters: 'Plus de filtres',
    less_filters: 'Moins de filtres',
    per_page: 'Élements par page',
    identity: 'Identité',
    company: 'Société',
    informations: 'Informations',
    connections: 'Branchements',
    good_to_know: 'À savoir',
    your_company: 'Votre société',
    general_informations: 'Informations générales',
    required_fields: 'Champs obligatoires',
    invalid_siret: "Le SIRET doit être composé de 14 chiffres",
    siret: "SIRET",
    intra_community_vat: "TVA Intracommunautaire",
    fill_vat_or_siret: 'Veuillez remplir au moins l’un des deux champs suivants : SIRET ou TVA Intracommunautaire.',
    check_all: 'Tout cocher',
    uncheck_all: 'Tout décocher',
    community_certificate: 'Titre de navigation',
    vat_exemption_file: 'Justificatif d\'exonération de TVA',
    numbers_of_transactions: 'Nombre de transactions',
    ships_list: 'Liste des bateaux',
    edit_ship: 'Modifier un bateau',
    status: 'Statut',
    author: 'Auteur',
    last_activity: 'Dernière activité',
    create: 'Création',
    subject: 'Sujet',
    open: 'Ouvert',
    HTML__CGU_disclaimer: `
        <h2>Avertissement et CGU</h2>
        <h3>Avertissement sur le rechargements sur les bornes</h3>
        <p>Afin de finaliser l'inscription, veuillez lire attentivement les avertissements ci-dessous :</p>
        <p><strong>Pour éviter tout problème électrique avec les prises en triphasé,</strong> les bateaux doivent être équipés d'un inverseur automatique de phase.</p>
        <p><strong>Les prises ont une protection différentielle de 30 mA :</strong> Pour les bateaux dont le neutre est relié à la coque, ces derniers peuvent avoir des problèmes de déclenchement de la protection différentielle de la borne.</p>
        <p>Il faut s'assurer de remplir une des conditions suivantes :</p>
        <ul class="unstyled">
            <li>Avoir un bateau équipé d'un transformateur d'isolement</li>
            <li>Vérifier que le bateau équipé d'un contrôleur d'isolement n'indique pas de défaut.</li>
        </ul>
    `,
    brower_compatibily_disclaimer: 'Nous vous informons que certaines fonctionnalités du site ne sont pas optimisées pour Internet Explorer, nous vous invitons à utiliser Chrome, Firefox, Edge, ou autres navigateurs',
    accepted: 'Accepté',
    refused: 'Refusé',
    pending: 'En attente',
    file_input_instruction: 'Glissez le fichier ou choisissez-le en cliquant dans le cadre',
    see_file: 'Voir le fichier',
    community_certificate_instruction: 'Titre de navigation - PDF (max : 5Mo)',
    see_certificate_file: 'Voir le fichier de certificat',
    vat_exemption_file_instruction: "Justificatif d'exonération de TVA - PDF (max : 5Mo)",
    see_vat_exemption_file: "Voir le fichier d'exonération de TVA",
    see_kbis_file: 'Voir le KBIS',
    see_rib_file: 'Voir le RIB',
    close: 'Fermer',
    'ALL': 'Tous',
    'ELECTRICITE': 'Électricité',
    'EAU': 'Eau',
    ignore_null_transactions: 'Ignorer les transactions à 0 kwH/m3',
    rib_instruction: 'RIB - PDF (Poids de fichier max. 5 Mo)',
    kbis_instruction: 'KBIS - PDF (Poids de fichier max. 5 Mo)',
    update_title: "Mise à jour",
    do_update: "Mettre à jour",
    update_description: "Une nouvelle version est disponible.",
    password_12_characters: 'Le mot de passe doit comporter au moins 12 caractères',
    password_uppercase: 'Le mot de passe doit comporter au moins une majuscule',
    password_lowercase: 'Le mot de passe doit comporter au moins une minuscule',
    password_number: 'Le mot de passe doit comporter au moins un chiffre',
    password_special_char: 'Le mot de passe doit comporter au moins un caractère spécial',
    password_dont_match: "Le mot de passe de confirmation ne correspond pas",
    password_global_error: 'Votre mot de passe n’est pas conforme aux normes de sécurité en vigueur (12 caractères minimum dont une majuscule, un chiffre, et un caractère spécial',
    ticket_reason: 'Raison du ticket',
    ticket_message: 'Message',
    terminal_details: 'Details de la borne',
    unavailable_charge: 'Charge indisponible',
    name: 'Nom',
    entity: 'Entité',
    version: 'Version',
    actions: 'Actions',
    type: 'Type',
    user_refusal_reason: "Motif de refus de l'armateur",
    accept: 'Accepter',
    decline: 'Refuser',
    confirm: 'Confirmer',
    'Expired or invalid token': 'Le Jeton est invalide. Veuillez renouveler votre demande.',
    cgu: 'CGU',
    borne_et_eau_explanatory: 'Explicatif Borne et Eau',
    user_guide: 'Guide utilisateur',
    remote_startup_note: 'Note de démarrage à distance',
    back_to_login: 'Revenir au formulaire de connexion',
    active:'Actif',
    deleted:'Supprimé',
    show_deleted_boats: 'Voir les bateaux supprimés',
    new: 'Nouveau',
    update : 'Mise à jour',
    password_different: 'Le nouveau mot de passe doit être différent de l\'ancien',
    "Your password must be different from your previous.": 'Le nouveau mot de passe doit être différent de l\'ancien',
    allowed: 'Autorisé',
    denied: 'Refusé',
    waiting: 'En attente',
    reason: 'Raison',
    tickets_list: 'Liste des tickets',
    ticket_details: 'Detail du ticket',
    min_1_max_500: '1 caractère minimum, 500 maximum',
    display_all: 'Tout afficher',
    ticket_terminal: ' Selectionnez une borne si nécessaire',
    issue_terminal_panel : 'Problème avec le panneau de la borne',
    issue_interface_operation: 'Problème avec le fonctionnement de l\'interface',
    issue_payement: 'Problème avec le paiement',
    issue_damaged_terminal: 'Borne endommagée',
    issue_personnal_data: "Suppression des données à caractère personnel",
    issue_other: 'Autre problème',
    show_closed_tickets: 'Voir les tickets résolus',
    DATE_FORMAT: "DD/MM/yyyy",
    DATE_HOURS_FORMAT: "DD/MM/yyyy HH:mm:ss",
    ticket_open: 'Ouvert',
    ticket_closed: 'Résolu',
    ticket_waiting_answer: 'En cours de résolution',
    blocked_file_popup: "Le fichier n'a pas pu être ouvert. Vérifiez que votre navigateur n'a pas bloqué l'ouverture de la pop-up",
    ticket_comment_sent: 'Commentaire envoyé',
    ticket_status_updated: 'Statut du ticket modifié',
    identification: 'Identification',
    Occupied: "Occupé",
    Available: "Disponible",
    Unavailable: "Indisponible",
    AvailableFull: 'Disponible',
    OccupiedFull: 'Totalement occupé',
    UnavailableFull: 'Totalement indisponible',
    UnavailableOccupied: 'En partie Indisponible',
    UnavailableOccupiedFull: 'Indisponible',
    Preparing: "En préparation",
    Offline: 'Hors Ligne',
    'Unknown': 'Inconnu',
    date: 'Date',
    graphical_version: 'Version graphique',
    accessible_version: 'Version accessible',
    show_fullscreen: 'Afficher en plein écran',
    distribution_finished: 'Nb de distributions',
    distribution_running: 'Nb de distributions en cours',
    distribution_zero: 'Nb de distributions à zero',
    "Unauthorized file type. Expecting 'application/pdf', got image/jpeg": "Seuls les fichiers au format PDF sont autorisés.",
    "You must accept the CGU and acknowledge the warnings": "Vous devez accepter les CGU et prendre connaissance des avertissements",
    min_date_message_transactions: 'La date de début ne peux pas être supérieure à celle de fin',
    electric_connectors_state: "État des bornes disponible et occupées en cours",
    electric_distribution: "Distribution d'électricité",
    electric_distribution_state: "État de la distribution d'électricité",
    electric_consumption_state: "État de la consommation d'électricité",
    water_connectors_state: "État des bornes disponible et occupées en cours",
    water_distribution: "Distribution d'eau",
    water_distribution_state: "État de la distribution d'eau",
    water_consumption_state: "État de la consommation d'eau",
    tour_title: `Voulez-vous faire une petite visite ?`,
    tour_description: `Nouvel utilisateur ? Découvrez les fonctionnalités du site Borne&Eau en suivant cette courte visite.`,
    tour_skip: `Je connais déjà le site`,
    tour_start: `C'est parti`,
    tour_content_statistics: `Ici vous trouverez les statistiques montrant la consommation de vos bateaux ainsi que le nombre de distribution.`,
    tour_content_terminals: `Ici vous trouverez toutes les bornes ainsi que leurs états (En ligne, en charge, hors-ligne).`,
    tour_content_transaction_history: `Retrouvez vos consommations récentes ou ancienne grâce à un système de filtrage. Vous pouvez également les télécharger en format Excel en 1 clic avec le filtrage de votre choix.`,
    tour_content_ships: `Tous vos bateaux sont enregistrés ici. Pas encore de bateaux enregistrés, créé en un pour recevoir le code permettant de recharger votre bateau sur les bornes. Vous pouvez aussi supprimer votre bateau (vente/démantèlement), vous garderez les transactions associées à ce bateau.`,
    tour_content_tickets: `Vous rencontrez un problème sur le site ou sur une borne, n'hésitez pas à nous écrire un ticket, nous vous répondrons dans les plus brefs délais.`,
    tour_content_updates_note: `Nous mettons à jour le site le plus souvent possible et vous pouvez voir les ajouts et correctifs apportés sur cette page.`,
    tour_content_procedures: `Retrouvez ici les Conditions Générales d'Utilisations (CGU) ainsi qu'un PDF explicatif du projet Borne&Eau.`,
    tour_content_account: `Accédez à votre compte en cliquant ici. Vous pouvez changer votre mot de passe, vos données personnelles, les données de votre entreprise et gérer les autorisations des ports.`,
    tour_content_pwa: `<p>Pour retrouver l'application depuis l'écran d'accueil de votre tablette ou téléphone :</p><ol><li>Ouvrez l'application https://www.borneeteau.fr/login sur votre navigateur</li><li>Cliquez sur Partage</li><li>Sélectionnez "Sur l'écran d'accueil"</li></ol>`,
    "Missing required file 'kbis_file'": "Le fichier KBIS doit être fourni",
    "Missing required file 'rib_file'": "Le fichier RIB doit être fourni",
    pdf_wrong_format: 'Le fichier doit être au format pdf',
    'You are not allowed to change the status for this entity': "Vous n'avez pas les droits nécessaires pour changer le statut pour cette entité",
    last_update: 'Dernière mise à jour',
    report_unavailable: 'Signaler indisponible',
    'Unable to start a busy connector': 'Impossible de démarrer un connecteur occupé',
    'Unable to disable a busy connector': 'Impossible de désactiver un connecteur occupé',
    replay_tutorial: 'Rejouer le didactitiel',
    reactivate_user: "Réactiver l'utilisateur",
    reactivate_user_description: "L'utilisateur existe déjà mais a été désactivé, voulez-vous le réactiver ?",
    user_restored: "L'utilisateur a été réactivé avec succés",
    token_expired_please_reapply: 'Le lien a expiré. Veuillez renouveler votre demande.',
    show_boat_transactions: "Voir l'historique de consommation du bateau",
    quantity: 'Quantité',
    "Boat not found": "Le bateau n'a pas été trouvé",
    recap_available: 'Disponible(s)',
    recap_occupied: 'Occupé(s)',
    recap_error: 'En erreur',
    recap_online: 'En ligne',
    recap_offline: 'Hors-ligne',
    recap_unknown: 'Inconnu(e)s',
    "You are not allowed to access to this boat": "Vous n'êtes pas autorisé(e) à accéder à ce bateau",
    transaction_start_success : 'La transaction a démarré avec succès',
    transaction_stop_success : "La transaction s'est arrêtée avec succès",
    transaction_count: 'Nombre de transactions',
    total: 'Total',
    this_week: 'Cette semaine',
    today: "Aujourd'hui",
    price_group: 'Groupe de prix',
    per_year: 'Par année',
    per_month: 'Par mois',
    per_week: 'Par semaine',
    per_day: 'Par jour',
    proceed_with_disconnection: "Vous pouvez maintenant procéder aux opérations de déconnexion sur la borne",
    about_personnal_data: "À propos des données à caractère personnel",
    HTML__personnal_data: `
        <h2>Données à caractère personnel</h2>
        <p>Conformément au <strong>Règlement européen sur la protection des données et à la réglementation nationale (la loi Informatique, fichiers et libertés modifiée du 6 janvier 1978), vous êtes informés que les données personnelles enregistrées sont celles fournies par le formulaire d’inscription en ligne.</strong></p>
        <p>Le site peut également recueillir des données de connexion à <strong>des fins d’information, d’analyse statistique et analyse d’audience</strong> dans les conditions indiquées au § Témoin de connexion.</p>
        <p><strong>Ces données sont uniquement destinées à VNF,</strong> en tant que responsable du traitement, à des fins de gestion administrative.</p>
        <p>Il vous appartient de vérifier que les informations que vous nous fournissez par le biais de ce site sont exactes et complètes.</p>
        <p>Vous pouvez exercer votre droit d’accès, d’opposition et de rectification, en vous adressant :</p>
        <h3>Par courrier à :</h3>
        <address>
            Voie navigables de France <br/>
            175 rue Ludovic Boutleux <br/>
            62408 Béthune
        </address>
        <h3>Par téléphone: </h3>
        <p>Tél.: 33 (0)3 21 63 24 24</p>
        <h3>Par messagerie à :</h3>
        <p><a href="mailto:dpd@vnf.fr">dpd@vnf.fr</a></p>
    `,
    'Unable to reserve a busy connector': "Impossible de réserver un connecteur occupé",
    'Unable to reserve a disabled connector': "Impossible de réserver un connecteur désactivé",
    'You are not allowed to access this terminal': "Vous n'êtes pas autorisé à accéder à ce terminal",
    'Reservation has already ended': "La réservation est déjà terminée",
    'Unable to retrieve Reservation from CPO': "Impossible de récupérer la réservation auprès du CPO",
    'User not found': "Utilisateur non trouvé",
    'Account exists': "Le compte existe",
    'ENI already in use': "ENI déjà utilisé",
    'Invalid file type.': "Type de fichier invalide.",
    'File not found': "Fichier introuvable",
    'Entity not found': "Entité introuvable",
    'Permission already exists': "L'autorisation existe déjà",
    'Step accessed too early': "Étape accédée trop tôt",
    'Parameter \'entities\' must be an array of ids': "Le paramètre 'entities' doit être un tableau d'ids",
    'no reset token provided': "pas de jeton de réinitialisation fourni",
    'Unable to start a disabled connector': "Impossible de démarrer un connecteur désactivé",
    'Unable to stop a non-charging connector': "Impossible d'arrêter un connecteur qui ne charge pas",
    'No transaction found at this connector for this boat': "Aucune transaction trouvée à ce connecteur pour ce bateau",
    'You are not allowed to edit this user': "Vous n'êtes pas autorisé à modifier cet utilisateur",
    'You are not allowed to edit this boat': "Vous n'êtes pas autorisé à modifier ce bateau",
    'Unable to delete yourself': "Impossible de vous supprimer",
    'You are not allowed to delete this user': "Vous n'êtes pas autorisé à supprimer cet utilisateur",
    'Unable to restore yourself': "Impossible de vous restaurer",
    'Unable to recover a non-deleted user': "Impossible de récupérer un utilisateur non supprimé",
    'You are not allowed to restore this user': "Vous n'êtes pas autorisé à restaurer cet utilisateur",
    'Forbidden': "Interdit",
    'Charging started successfully': "La charge a démarré avec succès",
    'Charging stopped successfully': "La charge s'est arrêtée avec succès",
    'Connector reserved successfully': "Connecteur réservé avec succès",
    'Connector reservation canceled successfully': "Réservation de connecteur annulée avec succès",
    'Water': 'Eau',
    'Electricity': 'Électricité',
    'Arrêter ou démarrer la distribution': "Arrêter ou démarrer la distribution",
    'Démarrer': "Démarrer",
    'Arrêter': "Arrêter",
    'report_available_success' : 'Le connecteur a été signalé comme disponible',
    'report_unavailable_success' : 'Le connecteur a été signalé comme indisponible',
    see_boat: 'Voir le bateau',
    terminal_reset_success: 'La borne a été redémarrée avec succès',
    password_helper_intro: 'Votre mot de passe doit contenir au minimum :',
    password_helper_length: '12 caractères',
    password_helper_uppercase: 'Une majuscule',
    password_helper_digit: 'Un chiffre',
    password_helper_specialchar: 'Un caractère spécial',
    contact_vnf: "Contact VNF",
    contact_haropa: "Contact HAROPA PORT",
    fixes: 'Correctif(s)',
    features: 'Evolutif(s)',
    inactivity_title: `Période d'inactivité prolongée`,
    inactivity_desc: `Les données affichées risquent de ne plus être à jour. Veuillez recharger la page pour les actualiser.`,
    refresh_page: 'Recharger la page',
};
export default translationFR;