import React, { Component } from 'react';
import { useToasts, ToastProvider } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import CustomToast from './components/CustomToast';
import Modal from 'components/Modal';
import Routes from './routes';
import 'bootstrap/scss/bootstrap-grid.scss';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import 'react-grid-layout/css/styles.css';
import 'react-phone-input-2/lib/style.css';
import 'react-pro-sidebar/dist/css/styles.css';
import './assets/scss/main.scss';

export function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isServiceWorkerUpdated: false
    }
  }

  componentDidMount() {
    this.isServiceWorkerUpdated();
  }

  isServiceWorkerUpdated() {
    if (localStorage.getItem('isServiceWorkerUpdated') && localStorage.getItem('isServiceWorkerUpdated') === 'true') {
      this.setState({ isServiceWorkerUpdated: true });
      window.setTimeout(() => { this.isServiceWorkerUpdated() }, 300000);
    }
    else
      window.setTimeout(() => { this.isServiceWorkerUpdated() }, 4000);
  }

  render() {
    const { t } = this.props;

    const updateServiceWorker = () => {
      localStorage.clear();
      this.setState({ isServiceWorkerUpdated: false });
      const registrationWaiting = this.props.serviceWorkerRegistration?.waiting;

      if (registrationWaiting) {
        localStorage.removeItem('isServiceWorkerUpdated');
        this.setState({ isServiceWorkerUpdated: false });
        registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

        registrationWaiting.addEventListener('statechange', e => {
          if (e.target.state === 'activated') {
            // update app
            localStorage.clear();
            window.location.reload();
          }
        });
      }
    };

    if (this.props.isServiceWorkerUpdated) {
      localStorage.setItem('isServiceWorkerUpdated', 'true');
    }

    return (
      <ToastProvider components={{ Toast: CustomToast }}>
        <Modal
          title={t('update_title')}
          description={t('update_description')}
          open={this.state.isServiceWorkerUpdated}
          handle={(val) => { this.setState({ isServiceWorkerUpdated: val }); }}
        >
          <div className="modal__buttons modal__buttons--center">
            <button onClick={updateServiceWorker} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-orange-a4">{t('do_update')}</button>
          </div>
        </Modal>
        <Routes />
      </ToastProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    isServiceWorkerInitialized: state.serviceWorkerInitialized,
    isServiceWorkerUpdated: state.serviceWorkerUpdated,
    serviceWorkerRegistration: state.serviceWorkerRegistration
  }
}


export default withTranslation()(connect(mapStateToProps)(App));
