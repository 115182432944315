import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import {FormInput, FormSubmit} from '../../components/FormComponents';
import { validateEmail, validatePassword } from '../../helpers/validation';
import { LoginLayout } from 'components/Layouts';
import PasswordHelper from 'components/PasswordHelper';

class ResetPassword extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            reset_password: {
                email: "",
                token: this.props.match.params.token || "",
                new_password: "",
                conf_password: "",
            },
            tokenExpired: false
        }
    }

    componentDidMount() {
        this.checkIsConnectedPWA()
        this.checkTokenValidity();
    }

    async checkTokenValidity () {
        if (!this.state.reset_password.token) return
        const url = "/resetpassword/validate?token=" + this.state.reset_password.token;
        const method = 'GET'
        const data = await(this.request(url, method))

        if (data.status === "ok") {
            this.setState({tokenExpired: !data.valid})
        }
    }

    async checkNewPassword() {
        const { t } = this.props;
        let url = "/resetpassword/check?token=" + this.state.reset_password.token;
        let method = "POST";
        let body = JSON.stringify({ password: this.state.reset_password.new_password });
        let data = await this.request(url, method, body);
        if (data && data.status === "ok" && data.valid)
            return true;
        else if (data && !data.valid && data.violations) {
            data.violations.forEach(element => {
                this.props.addToast(t(element), { appearance: 'danger' })
            });

        }
        return false;
    }

    async resetPassword(csrf) {
        const { t } = this.props;
        let url = "/resetpassword/?token=" + this.state.reset_password.token;
        let method = "POST";
        let body = JSON.stringify({ _csrf_token: csrf, password: this.state.reset_password.new_password });
        let data = await this.request(url, method, body);
        if (data && data.status === "ok" && data.valid)
            return true;
        else if (data && !data.valid && data.violations) {
            data.violations.forEach(element => {
                this.props.addToast(t(element), { appearance: 'danger' })
            });
        }
        return false;
    }

    async afterSubmissionPass(event) {
        const { t } = this.props;
        event.preventDefault();
        if (this.state.reset_password.new_password === this.state.reset_password.conf_password) {
            if (await this.checkNewPassword()) {
                let csrf = await this.getCsrfToken("/resetpassword/csrf?token=" + this.state.reset_password.token);
                if (await this.resetPassword(csrf)) {
                    if (!this.checker(window.location.pathname, ["/account/activate"]))
                        this.props.addToast(t('successful_activation'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
                    else
                        this.props.addToast(t('Password successfully changed'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
                    this.props.history.push("/login");
                }
            }
        }
        else {
            this.props.addToast(t('Passwords do not match'), { appearance: 'danger' });
        }
    }

    async afterSubmissionEmail(event) {
        const { t } = this.props;
        event.preventDefault();
        let url = "/resetpassword/new";
        let method = "POST";
        let body = JSON.stringify({ email: this.state.reset_password.email });
        let data = await this.request(url, method, body);
        if (data && data.status === "ok") {
            this.props.addToast(t("reset_password_title"), { appearance: 'success', message: t("reset_password_message"), size: 'xl', autoDismiss: true, autoDismissTimeout: 4000 });
            this.props.history.push("/login");
        }
    }

    getTitle () {
        let title = !this.checker(window.location.pathname, ["/account/activate"])
            ? "create_password"
            : "Forgot your password ?"
        if (this.state.tokenExpired) title = 'token_expired_please_reapply'

        return title
    }

    render() {
        const { t } = this.props;
        return (
            <LoginLayout>
                <div className="login__form secondary-form">
                    <h1 className="secondary-form__title h2">{t(this.getTitle())}</h1>
                    {(this.props.match.params.token && !this.state.tokenExpired)
                        ? (
                            <form onSubmit={(e) => this.afterSubmissionPass(e)}>
                                <div className="row">
                                    <div className="col-12">
                                        <FormInput
                                            label={t("New password")}
                                            type="password"
                                            name="new_password"
                                            id="new_password"
                                            appearance="secondary"
                                            validation={validatePassword}
                                            handle={(e) => this.handleInputChange(e, null, 'reset_password')}
                                            value={this.state.reset_password.new_password}/>
                                    </div>
                                    <div className="col-12">
                                        <FormInput
                                            label={t("Confirmation")}
                                            type="password"
                                            name="conf_password"
                                            id="conf_password"
                                            appearance="secondary"
                                            validation={validatePassword}
                                            handle={(e) => this.handleInputChange(e, null, 'reset_password')}
                                            value={this.state.reset_password.conf_password}/>
                                    </div>
                                    <div className="col-12">
                                        <PasswordHelper password={this.state.password} />
                                    </div>
                                </div>
                                <FormSubmit label={t("Save")} />
                            </form>
                        )
                        : (
                            <form onSubmit={(e) => this.afterSubmissionEmail(e)}>
                                <div className="row">
                                    <div className="col-12">
                                        <FormInput
                                            label={t("Your email address")}
                                            type="email"
                                            name="email"
                                            id="email"
                                            appearance="secondary"
                                            handle={(e) => this.handleInputChange(e, null, 'reset_password')}
                                            validation={validateEmail}
                                            value={this.state.reset_password.email}/>
                                    </div>
                                </div>
                                <FormSubmit label={t("send")}/>
                            </form>
                        )
                    }
                </div>
            </LoginLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(ResetPassword)), ResetPassword));