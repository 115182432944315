import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import LoadingOverlay from 'react-loading-overlay';
import { withToast } from '../../App';
import { RegisterLayout } from '../../components/Layouts';
import { FormMulticheckbox } from 'components/FormComponents';

class RegisterStep4 extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            register_step4: {
                entities: [],
            },
            formIsValid: true,
            isActive: true,
            entities: []
        }
        this._isMounted = false;
    }

    checkFormValidity = () => {
        this.setState({formIsValid: true})
    }

    componentDidMount() {
        this._isMounted = true;
        this.checkIsConnectedPWA();
        //Load localstorage value
        this.loadStorageValues(['register_step4'], [this.state.register_step4], this.checkFormValidity);
        //get entities to display checkbox in form
        this._isMounted && this.getEntities();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async getEntities() {
        let url = "/register/step4";
        let method = "GET";
        let data = await this.request(url, method);
        if (data && data.entities) {
            this._isMounted && this.setState({
                register_step4: {
                    entities: data.entities.filter(ent => ent.id === 1).map(ent => ent.id.toString())
                },
                entities: data.entities.map(entity => ({value: entity.id, name: entity.name})),
                isActive: false
            })
        }
        else {
            this.setState({ isActive: false });
        }
    }

    async afterSubmissionStep4(event, go_back = false) {
        const { t } = this.props;
        event.preventDefault();
        let data = await this.registerStep4();
        if (data && data.status === "ok") {
            localStorage.setItem('register_step4', JSON.stringify(this.state.register_step4));
            if (!go_back) {
                this.props.addToast(t('Fourth step of registration successful'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
                this.props.history.push("/register/step5");
            }
        }

        if (go_back) this.props.history.push('/register/step3');
    }

    async registerStep4() {
        let url = "/register/step4";
        let method = "POST";
        let csrf = await this.getCsrfToken("/register/csrf");

        let body = JSON.stringify({
            _csrf_token: csrf,
            entities: this.state.register_step4.entities.map(entity => parseInt(entity))
        });
        let response = await this.request(url, method, body);
        return response;
    }

    render() {
        const { t } = this.props;
        return (
            <RegisterLayout step={4}>
                <div className="register__form primary-form">
                    <LoadingOverlay active={this.state.isActive} classNamePrefix="loader_" spinner text={t('loading')}>
                        <form onSubmit={(e) => this.afterSubmissionStep4(e)}>
                            <hr className="form-divider form-divider--big"/>
                            <FormMulticheckbox
                                label="Choisissez le(s) lieu(x) où vous êtes suceptible de vous brancher :"
                                name="entities"
                                options={this.state.entities}
                                checkedItems={this.state.register_step4.entities}
                                handle={(e) => this.handleInputChange(e, null, 'register_step4', this.checkFormValidity)}/>
                            <hr className="form-divider form-divider--big"/>
                            <div className="primary-form__buttons">
                                <button type="button" onClick={(e) => this.afterSubmissionStep4(e, true)} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-bleu-a3">{t('Previous')}</button>
                                <button type="submit" disabled={!this.state.formIsValid} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('Next')}</button>
                            </div>
                            <p className="primary-form__required-fields">* {t('required_fields')}</p>
                        </form>
                    </LoadingOverlay>
                </div>
            </RegisterLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(RegisterStep4)), RegisterStep4));