import CustomComponent from 'components/customComponent';
//import { withRouter, Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import { withToast } from '../../App';
import { DefaultLayout } from 'components/Layouts';


class ShowTicket extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            showticket: {
                status: "",
                terminal: {},
                reason: "",
                message: "",
                comments: {},
                comment: "",
            },
            isActive: true
        }
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.checkIsConnectedPWA()) {
            //Load localstorage value
            //this.loadStorageValues(['showticket'], [this.state.showticket]);
            let id = 0;
            if (this.props.match.params.id)
                id = this.props.match.params.id;
            this._isMounted && this.getTicket(id);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    async getTicket(id) {
        let url = "/ticket/" + id;
        let method = "GET";
        let data = await this.request(url, method);
        if (data && data.ticket) {
            this._isMounted && this.setState(prevState => ({
                showticket: {
                    ...prevState.showticket,
                    status: data.ticket.status,
                    reason: data.ticket.reason,
                    terminal: data.ticket.terminal,
                    message: data.ticket.message,
                    comments: data.ticket.comments
                },
                isActive: false
            }));
        }
        else {
            this.setState({ isActive: false });
        }
    }

    async afterSubmissionSendComment(event) {
        const { t } = this.props;
        event.preventDefault();
        let id = 0;
        if (this.props.match.params.id)
            id = this.props.match.params.id;
        let url = "/ticket/" + id + "/comment";
        let method = "POST";
        let csrf = await this.getCsrfToken("/ticket/csrf");
        let body = JSON.stringify({
            message: this.state.showticket.comment,
            _csrf_token: csrf
        });

        let data = await this.request(url, method, body);
        if (data && data.status === "ok") {
            this._isMounted && this.getTicket(id);
            this.props.addToast(t('commentSend'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
        }
    }

    render() {
        const { t } = this.props;
        return (
            <DefaultLayout>
                <h1 className="main-title">{t('tickets')}</h1>
                <LoadingOverlay
                    active={this.state.isActive}
                    classNamePrefix="loader_"
                    spinner
                    text={t('loading')}
                >
                    Status: {this.state.showticket.status}<br />
                    Sujet: {this.state.showticket.reason}<br />
                    Message: {this.state.showticket.message}
                    {
                        Object.keys(this.state.showticket.comments).map((key) => (
                            <div key={key}>
                                Response {key}: {this.state.showticket.comments[key].message}, Author: {this.state.showticket.comments[key].author.firstName + " " + this.state.showticket.comments[key].author.lastName}, date: {moment.utc(this.state.showticket.comments[key].date).format(t('DATE_HOURS_FORMAT'))}
                            </div>
                        ))
                    }
                    <form onSubmit={(e) => this.afterSubmissionSendComment(e)}>
                        <div>
                            <textarea value={this.state.comment} name="comment" onChange={(e) => this.handleInputChange(e, null, 'showticket')} />
                        </div>
                        <input type="submit" name="reply" value={t("reply")} />
                    </form>
                </LoadingOverlay>
            </DefaultLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(ShowTicket)), ShowTicket));
