import Navigation from "./Navigation"
import Footer from "./Footer"
import { useTranslation } from "react-i18next"
import logoVNF from '../assets/img/logo-vnf-monochrome.svg'
import logoHaropa from '../assets/img/logo-haropa.svg'
import logoBorneEtEaux from '../assets/img/logo-borne_et_eaux.png'
import { Link } from "react-router-dom"
import LanguageSelector from "./LanguageSelector"
import ReactToolTip from 'react-tooltip';
import Modal from "./Modal"
import { useState } from "react"
import LoginCheck from "./loginCheck";

const toolTipProps = {
    place: 'top',
    className:'tooltip',
    effect: 'solid',
    border: true,
    backgroundColor: '#fff',
    borderColor: '#5e92ff',
    textColor: '#5e92ff',
    html: true,
}

export const DefaultLayout = ({children}) => {
    return (<>
        <Navigation key="0" />
        <main id="main-content">
            <div className="container">
                {children}
            </div>
        </main>
        <Footer />
        <LoginCheck />
        <ReactToolTip {...toolTipProps}/>
    </>)
}

export const LoginLayout = ({children}) => {
    const {t} = useTranslation()

    return (
        <div className="login">
            <LanguageSelector />
            <div className="login__wrapper">
                <div className="login__logo">
                    <Link to="/">
                        <img src={logoBorneEtEaux} alt="Logo Borne et Eaux" />
                    </Link>
                    <p>{t('First eletric terminals and water network')}</p>
                </div>
                <div className="login__partners">
                    <p>{t('A service developped by ...')}</p>
                    <img src={logoVNF} alt="Logo VNF" />
                    <img src={logoHaropa} alt="Logo Haropa" />
                </div>
                {children}
            </div>
            <ReactToolTip {...toolTipProps}/>
        </div>
    )
}

export const RegisterLayout = ({children, step}) => {
    const [open, setOpen] = useState(false)
    const {t} = useTranslation()

    const steps = [
        {number: 1, name: 'identity', to: '/register'},
        {number: 2, name: 'company', to: '/register/step2'},
        {number: 3, name: 'informations', to: '/register/step3'},
        {number: 4, name: 'connections', to: '/register/step4'},
        {number: 5, name: 'good_to_know', to: '/register/step5'},
    ]

    return (
        <div className="register">
            <LanguageSelector />
            <div className="register__wrapper">
                <div className="register__logo">
                    <Link to="/"><img src={logoBorneEtEaux} alt="Logo Borne et Eaux" /></Link>
                </div>
                <div className="register__form-wrapper">
                    <h1 className="register__title">{t("Create a shipowner account")}</h1>
                    <ul className="steps">
                        {steps.map(s => (
                            <li key={s.number}>
                                {(s.number < step)
                                    ? <Link className="steps__step" to={s.to}><span className={`steps__number steps__number--done`}>{s.number}</span>{t(s.name)}</Link>
                                    : <span className="steps__step"><span className={`steps__number${s.number === step ? ' steps__number--active' : ' steps__number--inactive'}`}>{s.number}</span>{t(s.name)}</span>
                                }
                            </li>
                        ))}
                    </ul>
                    {children}
                    <Link className="register__backlink" to="/login">{t('back_to_login')}</Link>
                </div>
                <button onClick={() => setOpen(true)} type="button" className="register__personal-data">
                    <span className="vnf-icons-icon-m-information-solid"></span>
                    {t('about_personnal_data')}
                </button>
            </div>
            <ReactToolTip {...toolTipProps}/>

            <Modal open={open} size="lg" onClose={() => setOpen(false)}>
                <div dangerouslySetInnerHTML={{__html: t('HTML__personnal_data', {interpolation: {escapeValue: false}})}}></div>
                <div className="modal__buttons modal__buttons--center">
                    <button onClick={() => setOpen(false)} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('close')}</button>
                </div>
            </Modal>
        </div>
    )
}