import { Component } from 'react';
import isOnline from 'is-online';
import { API_URL, API_DEBUG } from '../config';

class CustomComponent extends Component {
    checkIsConnectedPWA() {
        const { t } = this.props;
        let account = localStorage.getItem('account');

        if (localStorage.getItem('passwordNeedsChange') === "true") {
            this.props.history.push("/password/edit");
            return true
        }

        if (localStorage.getItem('cguNeedValidation') === "true") {
            this.props.history.push("/account/accept_cgu")
            return true
        }

        if (!account) {
            if (this.checker(window.location.pathname, ["/login", "/password/reset", "/register", "/account/activate/"]))
                this.props.history.push("/login");
            return false;
        }

        if (!this.checker(window.location.pathname, ["/login", "/password/reset", "/register", "/account/activate"]) && account) {
            this.props.addToast(t("Already connected"), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
            this.props.history.push("/");
        }
        return true;
    }

    async checkIsConnected() {
        let url = "/login";
        let method = "GET";
        let data = await this.request(url, method);

        if (data && data.status === "ok" && data.loggedIn)
            return true;
        return false;
    }

    checker(value, arr) {
        return arr.every(function (v) {
            return value.indexOf(v) === -1;
        });
    }

    contains(target, pattern) {
        var value = 0;
        pattern.forEach(function (word) {
            value = value + target.includes(word);
        });
        return (value >= 1)
    }

    handleInputChange(event, val, lsName, callback = null) {
        const target = event.target;
        let name = target.name || ''
        if (target.parentNode) {
            name = event.target.parentNode.classList.contains('MuiSlider-root') ? event.target.parentNode.querySelector('input').name : event.target.name;
        }
        let value = "";
        if (target.type !== undefined) {
            switch (target.type) {
                case 'multicheckbox':
                    value = target.value
                    break;
                case 'checkbox':
                    value = target.checked;
                    break;
                case 'submit':
                    if (target.id === 'step-up')
                        value = Number(this.state[lsName][name]) + (target.step ? Number(target.step) : 1);
                    if (target.id === 'step-down')
                        value = Number(this.state[lsName][name]) - (target.step ? Number(target.step) : 1);
                    break;
                default:
                    value = target.value;
                    break;
            }
        }
        else {
            value = val;
        }

        this.setState(prevState => {
            let cp = null;
            if (Array.isArray(lsName)) {
                cp = Object.assign({}, prevState[lsName[0]]);
                let obj = cp;
                for (let i = 1; lsName.length > i; i++) {
                    obj = obj[lsName[i]];
                }
                obj[name] = value;
                return { [lsName[0]]: cp };
            }
            else {
                cp = Object.assign({}, prevState[lsName]);
                cp[name] = value;
                return { [lsName]: cp };
            }
        }, () => {callback && callback()});
    }

    update_timer_session(first_request = false) {
        let remember_me = localStorage.getItem('_remember_me') && localStorage.getItem('_remember_me') !== 'true';
        // 5min session when _remember_me is false
        let timer_session = (!isNaN(localStorage.getItem('session')) && (new Date()).getTime() - parseInt(localStorage.getItem('session')) < 300000);
        if (remember_me && (first_request || timer_session))
            localStorage.setItem('session', JSON.stringify((new Date()).getTime()));
        else if (remember_me && !first_request && !timer_session) {
            localStorage.clear();
            this.props.history.push("/login");
        }
    }

    async request(url, method, body = "", isUploadForm = false, first_request = false) {
        const { i18n, t } = this.props;

        let headers = {};

        if (!isUploadForm)
            headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            };

        const settings = {
            method: method,
            headers: headers,
            // credentials: 'same-origin',
            credentials: 'include',
            ...(body && { body: body })
        };
        let hasResponse = false;

        try {
            const response = await fetch(API_URL + url, settings);

            const data = await response.json();
            if (data.status === "error") {
                hasResponse = data ? true : false;
                API_DEBUG && console.log(data);

                const message = i18n.exists(data.message) ? t(data.message) : t('GENERIC_ERROR')
                this.props.addToast(message, { appearance: 'danger' });
                console.error(data.message);

                if (data.message === 'Invalid Credentials') {
                    const loggedIn = await this.checkIsConnected();
                    if (!loggedIn) {
                        localStorage.clear();
                        this.props.addToast(t('disconnectedMsg'), {appearance: 'danger'});
                        this.props.history.push('/login');
                    }
                }

                if (data.message === "Disconnected") {
                    localStorage.clear();
                    this.props.history.push("/login");
                }

                return data
            }
            if (!response.ok)
                throw Error(response.statusText);
            this.update_timer_session(first_request);
            API_DEBUG && console.log(data);
            localStorage.setItem('_activity_timestamp', `${Date.now()}`)
            return data;
        } catch (e) {

            console.log("e", e)

            if (hasResponse && ! await this.checkIsConnected() && localStorage.getItem('account')) {
                this.props.addToast(t('disconnectedMsg'), { appearance: 'danger' });
                localStorage.clear();
                this.props.history.push("/login");
            } else if (!hasResponse) {
                if (await isOnline({ timeout: 1000 })) {
                    // TODO: msg API server is offline
                }
                else {
                    this.props.addToast(t("offlineMsg"), { appearance: 'danger' });
                }
            }
            else {
                console.error(e.toString());
                // this.props.addToast(t(e.toString()), { appearance: 'danger'});
                //this.props.addToast(t(e.toString().replace('danger: ', '')), { appearance: 'error'});
            }
            return null;
        }
    }

    loadStorageValues(actualStateNameArray, actualStateArray, callback = null) {

        if (actualStateNameArray.length === actualStateArray.length) {
            actualStateNameArray.forEach((key, index) => {
                let storageValue = {};
                let value;

                if (this.checkIsJSON(localStorage.getItem(key)))
                    storageValue = JSON.parse(localStorage.getItem(key));

                if (Array.isArray(actualStateArray[index])) {
                    value = storageValue ? storageValue : [];
                }
                else {
                    value = {};
                    Object.keys(actualStateArray[index]).forEach((setting) => {
                        value[setting] = (storageValue !== null && storageValue[setting] !== undefined) ? storageValue[setting] : actualStateArray[index][setting];
                    });
                }
                if (JSON.stringify(value) !== JSON.stringify(actualStateArray[index]))
                    this.setState({
                        [key]: value
                    }, () => {callback && callback()});
            });
        }
    }

    async getCsrfToken(url) {
        //let url = "/user/csrf";
        let method = "GET";
        let data = await this.request(url, method);
        if (data)
            return data.token;
        return null;
    }

    checkIsJSON(text) {
        try {
            JSON.parse(text);
            return true;
        } catch (error) {
            return false;
        }
    }

    async getFiles() {
        let url = "/company/files";
        let method = "GET";
        let files = await this.request(url, method);
        return files;
    }

    async getEntities() {
        let url = "/entity/list/all";
        let method = "GET";
        let entities = await this.request(url, method);
        return entities;
    }

    async getAccount(first_request = false) {
        let url = "/user/";
        let method = "GET";
        let data = await this.request(url, method, "", false, first_request);
        if (data && data.user) {
            // get files if exist
            let files = {};
            if (data.user.company && (data.user.company.kbis_file || data.user.company.rib_file)) {
                files = await this.getFiles();
            }
            let entities = await this.getEntities();
            localStorage.setItem('account', JSON.stringify(data.user));
            localStorage.setItem('files', JSON.stringify({ kbis: (files.kbis) ? files.kbis : "", rib: (files.rib) ? files.rib : "" }));
            localStorage.setItem('entities', JSON.stringify(entities.entities));
            return data.user;
        }
        return null;
    }

    getLocalStorageAccount()
    {
        let account = localStorage.getItem('account');
        if (account && this.checkIsJSON(account))
            account = JSON.parse(account) || [];
        return account;
    }

    loadRoles() {
        let roles = [];
        let accountRoles = localStorage.getItem('accountRoles');
        if (accountRoles && this.checkIsJSON(accountRoles))
            roles = JSON.parse(accountRoles) || [];
        return roles;
    }

    loadMainRole() {
        let role = null
        let account = localStorage.getItem('account')
        if (account && this.checkIsJSON(account)) {
            role = JSON.parse(account).roles.filter(role => role !== 'ROLE_USER')[0] || []
        }
        return role
    }

    hasRole(role) {
        let roles = [];
        let accountRoles = localStorage.getItem('accountRoles');
        if (accountRoles && this.checkIsJSON(accountRoles))
            roles = JSON.parse(accountRoles) || [];
        return roles.includes(role);
    }
}

export default CustomComponent;