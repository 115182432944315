import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import React, { Component, useEffect } from 'react';
import { AnimatePresence } from "framer-motion";
import Login from './views/login/login';
import Home from './views/home/home';
import Account from './views/account/account';
import ResetPassword from './views/reset_password/reset_password';
import EditPassword from './views/account/edit_password';
import Register from './views/register/register';
import RegisterStep2 from './views/register/step2';
import RegisterStep3 from './views/register/step3';
import RegisterStep4 from './views/register/step4';
import RegisterStep5 from './views/register/step5';
import User from './views/user/user';
import Usager from './views/user/usager';
import Ship from './views/ship/ship';
import Ticket from './views/ticket/ticket';
import AddTicket from './views/ticket/add';
import EditTicket from './views/ticket/edit';
import DeleteTicket from './views/ticket/delete';
import ShowTicket from './views/ticket/show';
import NotFound from './views/not_found/NotFound';
import Terminal from './views/terminal/terminals';
import Transactions from 'views/transactions/transactions';
import Procedures from './views/procedures/procedures'
import UpdateNote from 'views/update_note/update_note';
import StartDistribution from 'views/remote_operations/start_distribution'
import StopDistribution from 'views/remote_operations/stop_distribution'
import ReserveConnector from 'views/remote_operations/reserve_connector'
import CancelReservattion from 'views/remote_operations/cancel_reservation'
import RestartTerminal from 'views/remote_operations/restart_terminal'
import AcceptCGU from 'views/account/accept_cgu';

class Routes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsedMenu: false,
            roles: []
        }
    }

    handleOnRolesLoad = (roles) => {
        this.setState({ roles: roles });
    }

    render() {
        return (
            <Router>
                <AnimatePresence>
                    <Switch key="1">
                        <Route exact path="/" render={() => (<Home/>)} />
                        <Route path="/login" render={() => (<Login onRolesLoad={this.handleOnRolesLoad} />)} />
                        <Route path="/account/accept_cgu" component={AcceptCGU} />
                        <Route path="/account/activate/:token" component={ResetPassword} />
                        <Route path="/account" component={Account} />
                        <Route path="/password/edit" component={EditPassword} />
                        <Route path="/password/reset/:token" component={ResetPassword} />
                        <Route path="/password/reset" component={ResetPassword} />
                        <Route path="/register/step1" component={Register} />
                        <Route path="/register/step2" component={RegisterStep2} />
                        <Route path="/register/step3" component={RegisterStep3} />
                        <Route path="/register/step4" component={RegisterStep4} />
                        <Route path="/register/step5" component={RegisterStep5} />
                        <Route path="/register" component={Register} />
                        <Route path="/user" component={User} />
                        <Route path="/usager" component={Usager} />
                        <Route path="/ship/:id" component={Ship} />
                        <Route path="/ship" component={Ship} />
                        <Route path="/ticket/new" component={AddTicket} />
                        <Route path="/ticket/edit/:id" component={EditTicket} />
                        <Route path="/ticket/delete/:id" component={DeleteTicket} />
                        <Route path="/ticket/:id" component={ShowTicket} />
                        <Route path="/ticket" component={Ticket} />
                        <Route path="/terminal" component={Terminal} />
                        <Route path="/transaction/terminal/:cpo_id" component={Transactions} />
                        <Route path="/transaction/boat/:ship_id/:ship_name" component={Transactions} />
                        <Route path="/transaction" component={Transactions} />
                        <Route path="/procedures" component={Procedures} />
                        <Route path="/update_note" component={UpdateNote}/>
                        <Route path="/operation/start_distribution" component={StartDistribution}/>
                        <Route path="/operation/stop_distribution" component={StopDistribution}/>
                        <Route path="/operation/reserve_connector" component={ReserveConnector}/>
                        <Route path="/operation/cancel_reservation" component={CancelReservattion}/>
                        <Route path="/operation/restart_terminal" component={RestartTerminal}/>
                        <Route component={NotFound}/>
                    </Switch>
                    <ScrollTopFix />
                </AnimatePresence>
            </Router>
        )
    }
}

export default Routes;


/**
 * REACT ROUTER DOM FIX
 * WHY ? React router Dom keeps scroll position on page change
 * Check if no anchor is passed to URL and scroll to top
 */
const ScrollTopFix = () => {
    const { pathname, hash } = useLocation()

    useEffect(() => {
        !hash && setTimeout(() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'}), 100)
    }, [pathname, hash])

    return <></>
}