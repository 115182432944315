import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import Modal from 'components/Modal';

class DeleteModal extends CustomComponent {
    async handleDelete() {
        const { t } = this.props;

        let url = "/user/" + this.props.user.id;
        let method = "DELETE";
        let csrf = await this.getCsrfToken("/user/csrf");
        let body = JSON.stringify({
            _csrf_token: csrf
        });

        let data = await this.request(url, method, body);
        if (data && data.status === "ok") {
            this.props.addToast(t('userDeleted'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
            this.props.onClose()
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Modal
                title={t('deleteUserTitle')}
                description={`${t('deleteUserBody')} ( ID: ${this.props.user.id} )`}
                open={true}
                onClose={this.props.onClose}>
                <div className="modal__buttons">
                    <button type="button" onClick={this.props.onClose} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-bleu-a3">{t('deleteUserClose')}</button>
                    <button type="button" onClick={() => this.handleDelete()} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-orange-a4">{t('deleteUserValidate')}</button>
                </div>
            </Modal>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(DeleteModal)), DeleteModal));